import React, { Fragment, useState, useEffect, useContext } from "react";
import publicIp from "public-ip";
import { Link } from "react-router-dom";

import { Card, CardBody, Container, Row, Col } from "reactstrap";
import JSCookie from "js-cookie";
import { displayToastr } from "../helper";

import axios from "axios";
import "./myAccount.css";
import India from "./LoginWizard/states.json";
import { AppContext } from "../contexts/AppContext";

function MyAccount(props) {
  // global state
  const [seconds, setSeconds] = React.useState(30);
  const { isPopularDrawerOpen } = useContext(AppContext);
  const [customerDetails, setCustomerDetails] = useState({});
  const [publication, setPublication] = useState({});
  const [edition, setEdition] = useState({});
  const [hasCustomerDetails, setHasCustomerDetails] = useState(true);
  const [IPData, setIPData] = useState({});
  const [showInvoices, setshowInvoices] = useState(false);
  const [showOtp, setshowOtp] = useState(false);
  const [passwd, setpasswd] = useState({
    old_password: "",
    new_password: "",
  });
  const [showPasswd, setshowPasswd] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [otp, setOTP] = useState("");

  useEffect(() => {
    // if (!getCustomer()) {
    //   props.history.push("/login");
    // } else {
    //   let customer = getCustomer();
    //   setCustomerDetails(customer);
    //   await getCustomerDetails();
    // }

    setPublication(props?.publicationsData?.[0]);
    setEdition(props?.publicationsData?.[0]?.editions?.[0]);
  }, []);
  const getCustomerDetails = async () => {
    if (customerDetails?.customer_id)
      await axios
        .get(
          `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/customer-details`,
          {
            headers: {
              token: JSCookie.get("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setHasCustomerDetails(true);
          setFinalData({
            ...finalData,
            ...res.data,
            state:
              res?.data?.country?.toLowerCase() === "india"
                ? India?.States?.filter(
                    (v) => v.label === res?.data?.state
                  )?.[0]
                : {},
          });
        })
        .catch((err) => {
          setHasCustomerDetails(false);
        });
  };
  // useEffect(() => {
  //   getCustomerDetails();
  // }, [customerDetails]);

  const showOtpHandler = async () => {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/send-reset-link`,
      headers: {
        token: JSCookie.get("token"),
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (res) {
        displayToastr(
          "Verification email sent to your registered email address",
          "My Account",
          5000,
          "success"
        );
        setshowOtp(true);
        setshowPasswd(false);
        setSeconds(seconds - 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (seconds >= 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);
  const passwdChangeHandler = () => {
    axios
      .put(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/reset-pwd/`,
        { password: passwd.new_password },
        {
          headers: {
            token: JSCookie.get("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        displayToastr(
          "Password reset successfully",
          "My Account",
          5000,
          "success"
        );
        showOtp(false);
        setshowPasswd(false);
      })
      .catch((err) => {
        displayToastr(err?.response?.data, "My Account", 5000, "error");
      });
  };
  const updateCustomerDetails = () => {
    if (!finalData.state || !finalData.state.label) {
      return displayToastr(
        IPDataObj?.countryCode === "IN"
          ? "Select your State"
          : "Enter your State",
        "My Account",
        5000,
        "error"
      );
    }
    let obj = {
      first_name: finalData?.first_name,
      last_name: finalData?.last_name,
      gender: finalData?.gender,
      dob: finalData?.dob,
      address_line_1: finalData?.address_line_1,
      street: finalData?.street,
      city: finalData?.city,
      country: finalData?.country,
      postal_code: finalData?.postal_code,
      state: finalData?.state?.label,
    };
    axios
      .put(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/update/${customerDetails?.customer_id}`,
        obj
      )
      .then((res) => {
        displayToastr(
          "Details updated successfully",
          "My Account",
          5000,
          "success"
        );
        // localStorage.setItem("customer_details", JSON.stringify(res.data));
      });
  };
  useEffect(() => {
    const getIP = async () => {
      const IP = await publicIp.v4();
      await fetch(
        `https://pro.ip-api.com/json/${IP}?fields=country,countryCode,city,zip,regionName,query&key=2tjq7YXrdexnO8N`
      ).then(async (res) => {
        const data = await res.json();
        setIPData(data);
        let final_data = { ...finalData };

        final_data.country = data.country;
        final_data.city = data.city;
        final_data.country_code = data.countryCode;
        final_data.state =
          data?.countryCode === "IN"
            ? India?.States?.filter((v) => v.label === data.regionName)?.[0]
            : {};
        final_data.postal_code = data.zip;

        setFinalData(final_data);
      });
    };
    if (!hasCustomerDetails) {
      getIP();
    }
  }, [hasCustomerDetails]);

  const verifyOTP = async () => {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/verify-otp`,

      headers: {
        token: JSCookie.get("token"),
        "Content-Type": "application/json",
      },
      data: { otp },
    };

    await axios(config)
      .then(function (res) {
        setshowPasswd(true);
        displayToastr(
          "OTP verified successfully",
          "My Account",
          5000,
          "success"
        );
      })
      .catch((error) => {
        displayToastr(error?.response?.data, "My Account", 5000, "error");
      });
  };
  const [IPDataObj, setIPDataObj] = useState({});
  useEffect(() => {
    async function getIPData() {
      const IP = await publicIp.v4();
      await axios
        .get(
          `https://pro.ip-api.com/json/${IP}?fields=country,countryCode,city,zip,regionName,query&key=2tjq7YXrdexnO8N`
        )
        .then((res) => {
          setIPDataObj(res.data);
        });
    }
    getIPData();
    JSCookie.set("isLanding", false);
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "sticky",
          top: "0px",
          // height: "90px",
          zIndex: "9999",
        }}
      >
        <Card
          className="header-card-landing border-0 bg-white "
          style={{
            height: "90px",
          }}
        >
          <Row className="bg-white pb-1">
            <Col
              xs="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <img
                onClick={() => props.history.push("/")}
                className="cursor-pointer"
                style={{ maxHeight: "85px" }}
                src={`/navbharattimes-assets/logo/nbt-header-logo.png`}
                alt="toi"
              />
            </Col>
            <Col
              className=" top-ad-landing
                "
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <img
                height="90px"
                width="-webkit-fill-available"
                src="https://tpc.googlesyndication.com/daca_images/simgad/16249357003648981781"
                alt="hh"
              />
            </Col>

            <Col md="3" lg="3" xl="3"></Col>
          </Row>
        </Card>
        <div className="border-landing"></div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Container
          className="pt-2"
          style={{
            fontFamily: "sans-serif",
            height: window.innerHeight - 100,
            position: "absolute",
          }}
        >
          <div class="row">
            <div class="col-md-12">
              <iframe
                src="https://epapers.timesgroup.com/myaccount"
                frameborder="0"
                scrolling="yes"
                style={{
                  overflow: "hidden",
                  // height: window.innerHeight - 100,
                  width: "100%",
                }}
                height={window.innerHeight - 200}
                width="100%"
                title="myacnt"
              ></iframe>
            </div>
          </div>
          <footer className="footer-myacnt">
            <div className="toi-links">
              <Link
                to="/navbharattimes/about"
                // className="toi-submit-anchor toi-anchor"
                title="The Times of India — Mumbai"
                target="_self"
              >
                About Us
              </Link>
              <span className="toi-sep px-1"></span>
              <Link
                to="/navbharattimes/advertise"
                // className="toi-submit-anchor toi-anchor"
                title="The Times of India — Mumbai"
                target="_self"
              >
                Advertise with Us
              </Link>
              <span className="toi-sep px-1"></span>
              <Link
                to="/navbharattimes/terms"
                // className="toi-submit-anchor toi-anchor"
                title="The Times of India — Mumbai"
                target="_self"
              >
                Terms of Use
              </Link>
              <span className="toi-sep px-1"></span>
              <Link
                to="/navbharattimes/privacy"
                // className="toi-submit-anchor toi-anchor"
                title="The Times of India — Mumbai"
                target="_self"
              >
                Privacy and Cookie Policy
              </Link>
              <span className="toi-sep show-if--require-consents px-1"></span>
              <a
                id="link-consents"
                className="show-if--require-consents"
                href="/navbharattimes/home"
              >
                Privacy Form
              </a>
            </div>
            <div className="toi-copyright-myacnt">
              Copyright © 2020 Bennett Coleman &amp; Co. Ltd. • All rights
              reserved.
            </div>
          </footer>
          {/* <div
          className=""
          style={{
            background: "#ffffff",
            border: "3px solid #E5E5E5",
            marginTop: "5px",
            marginBottom: "160px",
            float: "left",
            width: "100%",
          }}
        >
          <div className="d-flex justify-content-center align-items-center p-2">
            <div className="copyrighttext">
              <a
                href="/about"
                target="_blank"
                rel="noreferrer"
                className="bottomlinks"
              >
                About Us{" "}
              </a>{" "}
              |{" "}
              <a
                href="https://epapers.timesgroup.com/advertise"
                className="bottomlinks"
                target="_blank"
                rel="noreferrer"
              >
                Advertise with Us
              </a>{" "}
              |{" "}
              <a
                href="https://epapers.timesgroup.com/terms"
                className="bottomlinks"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              |{" "}
              <a
                href="https://epapers.timesgroup.com/policy"
                className="bottomlinks"
                target="_blank"
                rel="noreferrer"
              >
                Privacy and Cookie Policy
              </a>
              <br /> Copyright © 2020 Bennett Coleman &amp; Co. Ltd. • All
              rights reserved.
            </div>
          </div>
        </div> */}
        </Container>
      </div>
    </Fragment>
  );
}

export default MyAccount;
