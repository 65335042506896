import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  TabContent,
  TabPane,
  Col,
  Card,
  CardBody,
  CardImg,
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";
import JSCookie from "js-cookie";
import grid_img from "../assets/images/grid_img.png";
import card_mob from "./card_mob.png";
import axios from "axios";
import classNames from "classnames";
import "./digest.css";
import classnames from "classnames";
import ArticleCard from "./ArticleCard";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

//context
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
export default function Digest(props) {
  // global state
  const {
    isPopularDrawerOpen,
    toggleArticleViewModal,
    setArticleViewData,
    setArticleViewName,
    isOpenArticleModal,
    // isOpenMobArticle,
    toggle,
    toggleShare,
    isOpen2,
    articleViewName,
    s3Source,
    currArticleIndex,
    setCurrArticleIndex,
    updateInsight,
    setArticleViewDataLinked,
    articleViewDataLinked,
    articleViewNameLinked,
    setArticleViewNameLinked,
    setLinkJsonURL,
    hasInfoZone,
    setHasInfoZone,
    ratioMainPage,
    setRatioMainPage,
    fit2pagesArray,
    setFit2pagesArray,
    articleViewData,
    setRelatedArticleData,
    relatedArticleData,
    setArticles,
    publicationsData,
    initialPublication,
    initialEdition,
    articles,
    dateArray,
    dateIndexData,
    dayIndexData,
    displayArticle,
    setDisplayArticle,
    logo,
    isSearch,
    themeColor,
    propsArticles,
    mostShared,
    setIsLandingPage,
    pageClick,
    currentPage,
    filteredDate,
    linkJsonData,
    setArticleLoading,
    isEnableAds,
  } = useContext(AppContext);

  const [isPopularOpen, setPopularOpen] = useState(false);
  const [isLeftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [pageNameDigest, setPageNameDigest] = useState("");

  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (!JSCookie.get("andre")) {
      history.push("/login");
    }
  });
  useEffect(() => {
    JSCookie.set("view", "digest");
  }, []);
  const tomainPage = () => {
    history.push(
      `/${publicationsData[JSCookie.get("nbtpubindex")]?.publicationname
        ?.split(" ")
        .join("-")
        .toLowerCase()}/${publicationsData[
        JSCookie.get("nbtpubindex")
      ]?.editions[JSCookie.get("nbtedindex")]?.editionname
        ?.split(" ")
        .join("-")
        .toLowerCase()}`
    );
  };
  let desktopView = window.innerWidth > 700;

  useEffect(() => {
    const fetchPublications = () => {
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${
        initialPublication?.publicationcode
      }/${initialEdition?.editionid}/${dateArray?.join("/")}/PageJson/`;

      path =
        path_prefix +
        currentPage?.PageName +
        `.json?v=${filteredDate?.Timestamp}`;

      if (!path.includes("undefined")) {
        axios
          .get(path)
          .then((result) => {
            setArticles(result?.data);
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    };
    fetchPublications();
  }, [dayIndexData]);

  const getContinuedArticles = async (articleName, articleJsonPath) => {
    let result = false;
    let isLinkTo = false;
    if (linkJsonData?.linkJson) {
      if (linkJsonData?.linkJson && linkJsonData?.linkJson?.length > 0) {
        let filter = linkJsonData.linkJson.filter(
          (v) => v.FromLinkArticle === articleName
        );
        if (filter.length === 0) {
          filter = linkJsonData.linkJson.filter(
            (v) => v.ToLinkArticle === articleName
          );
          if (filter.length > 0) {
            isLinkTo = true;
          }
        }
        if (filter && filter.length > 0) {
          let findArticleLink = filter.filter(
            (v) => v.LinkType === "articleLink"
          );
          let selectedArticleLink = [];
          if (findArticleLink.length > 0) {
            selectedArticleLink = [findArticleLink[findArticleLink.length - 1]];
          }
          // if (filter[0].LinkType === "articleLink") {
          if (selectedArticleLink?.length > 0) {
            let pageNumber =
              selectedArticleLink[0]?.ArticleLink.split("_")?.[3];

            setArticleViewNameLinked(selectedArticleLink[0]?.ArticleLink);
            await axios
              .get(
                `${articleJsonPath}${pageNumber}/${selectedArticleLink[0]?.ArticleLink}.json?v=${filteredDate?.Timestamp}`
              )
              .then(async (response) => {
                let array = [];
                for (let i = 0; i < response.data.length; i++) {
                  const ele = response.data[i];
                  let obj = { ...ele, SortOrder: i + 1 };
                  array.push(obj);
                }

                response.data = array;
                const finalArticle = await getFinalArticleArray(
                  response?.data,
                  selectedArticleLink[0]?.ArticleLink
                );
                result = {
                  articleName: selectedArticleLink[0]?.ArticleLink,
                  pageNumber,
                  articleData: finalArticle,
                  isLinkTo,
                };
                setArticleViewDataLinked({
                  ...finalArticle.articleData[0],
                  ArticlePhotographs: finalArticle.photographs ?? [],
                });
                setTimeout(() => {
                  updateInsight("view", finalArticle.articleData[0]);
                }, 1200);
              })
              .catch((err) => {
                setArticleViewDataLinked(null);
              });
          }
        }
      }
    }
    return result;
  };
  const openArticleViewDigest = async (
    articleName,
    isSecondPage = false,
    linkJsonPath,
    zone
  ) => {
    let pageNumber = articleName.split("_")?.[3];
    if (zone?.TagName === "Information") setHasInfoZone(true);
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray?.[2]}_${
          dateArray?.[1]
        }_${dateArray?.[0]}_${initialEdition?.editionid}.json?v=${
          filteredDate?.Timestamp
        }`
    );
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition?.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;
    let searched = await getContinuedArticles(articleName, path_prefix);
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      interchange = searched?.isLinkTo;
    }
    let arrayToUse = articles.PageContent;

    if (!articles.PageContent) {
      arrayToUse = propsArticles;
    }
    const articleIndex = arrayToUse?.findIndex(
      (article) => article.ArticleName === articleName
    );
    if (articleIndex !== -1) setCurrArticleIndex(articleIndex);

    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${filteredDate?.Timestamp}`;
    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }
        response.data = array;

        const finalArticle = await getFinalArticleArray(
          response.data,
          articleName,
          searched,
          interchange,
          true
        );
      })

      .catch((err) => {
        setArticleViewData(null);
      });
  };

  const getFinalArticleArray = async (
    data,
    articleName,
    searched,
    interchange,
    isNotFromContinued
  ) => {
    try {
      const articles = [];
      let photographs = data.filter((v) => v.TagName === "Photographs");

      if (photographs.length > 0) {
        photographs = photographs.map((ele, ind) => {
          return {
            ...ele,
            Photograph: ele?.ZoneID,
            ImageCaption: data?.filter(
              (v) =>
                v.TagName === "ImageCaption" &&
                v.SortOrder == ele?.SortOrder + 1
            )?.[0]?.ZoneText,
          };
        });
      }
      let hasInformationTag = false;
      const titles = await data?.filter((v) => v.TagName === "ArticleTitle");
      for (let j = 0; j < titles.length; j++) {
        const ele = titles[j];
        const sliceNum = titles.filter((v, i, a) => {
          if (i !== 0 && i === j + 1) {
            return a[i];
          }
        });
        let sliced_list = [];
        if (sliceNum.length > 0) {
          sliced_list = data?.slice(
            ele.SortOrder - 1,
            sliceNum[0].SortOrder - 1
          );
        } else {
          sliced_list = data?.slice(ele.SortOrder - 1);
        }
        let checkForBlurb = data?.filter(
          (v) => v.SortOrder === ele.SortOrder - 1
        );
        if (
          checkForBlurb &&
          checkForBlurb.length > 0 &&
          checkForBlurb?.[0].TagName === "Blurb"
        ) {
          sliced_list.unshift(checkForBlurb?.[0]);
        }
        articles.push(sliced_list);
      }

      if (articles.length > 0) {
        let finalArticleArray = [];
        let allowedTags = ["ArticleTitle", "ArticleBody"];
        for (let i = 0; i < articles.length; i++) {
          const sub_article = articles[i];
          let tts = "";
          let obj = {};

          for (let j = 0; j < sub_article.length; j++) {
            const ele = sub_article[j];
            if (allowedTags.includes(ele.TagName)) {
              tts += ele?.ZoneText?.split("")
                .join(" ")
                .split("<br>")
                .join(" ");
            }
            if (ele.TagName === "Information") {
              setHasInfoZone(true);
              hasInformationTag = true;
            }
            if (ele.TagName === "LinkTo") {
              let zoneid = ele.ZoneID.split("_");
              zoneid.pop();
              let linkedZone = await searchLink(
                `${s3Source}/PublicationData/${
                  initialPublication?.publicationcode
                }/${initialEdition?.editionid}/${dateArray?.join(
                  "/"
                )}/LinkJson/${dateArray?.[2]}_${dateArray?.[1]}_${
                  dateArray?.[0]
                }_${initialEdition?.editionid}.json?v=${
                  filteredDate?.Timestamp
                }`,
                { ZoneId: zoneid.join("_") }
              );
              const span_link = `<p id='${zoneid.join("_")}' class='${
                linkedZone?.[0]?.PageLink
              }' style='cursor:pointer;color:#0000ee;text-decoration:underline;font-weight:bold;font-family:LibreBaskerville'>${
                (!ele.ZoneText || ele.ZoneText === " ") &&
                linkedZone[0].LinkType === "pageLink"
                  ? `P${Number(linkedZone[0].PageLink.split("_")[3])}`
                  : ele.ZoneText?.split("").join("›")
              }</p>`;
              if (obj.ArticleBody) {
                obj["ArticleBody"] = obj?.["ArticleBody"] + `${span_link}`;
              } else {
                obj["ArticleBody"] = span_link;
              }
            } else if (ele.TagName === "ArticleBody") {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›")
                : ele?.ZoneText?.split("").join("›");
            } else {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›").trim()
                : ele?.ZoneText?.split("").join("›").trim();
            }
          }
          finalArticleArray.push({ ...obj, ArticleName: articleName, tts });
        }
        let finalArticle = {
          photographs,
          articleData: finalArticleArray,
        };

        if (isNotFromContinued) {
          if (interchange) {
            if (
              searched &&
              Object.keys(searched).length > 0 &&
              searched?.articleData?.articleData?.length > 1
            ) {
              let related = [
                ...searched?.articleData?.articleData.slice(1),
                ...finalArticle.articleData.slice(1),
              ];
              setRelatedArticleData(related);
            }
            setArticleViewName(searched?.articleName);
            if (
              !hasInformationTag &&
              !searched?.articleData?.articleData[0]?.ArticleBody.slice(
                0,
                7
              ) === "<p id='"
            ) {
              let location = await findLocation(
                searched?.articleData?.articleData[0]?.ArticleBody
              );
              if (location && location.length > 0) {
                searched.articleData.articleData[0].ArticleLocation =
                  location.join(" ");
                searched.articleData.articleData[0].ArticleBody =
                  searched?.articleData?.articleData[0]?.ArticleBody.slice(
                    location.join(" ")?.length
                  );
              }
            }

            setArticleViewData(searched?.articleData?.articleData[0]);
            setArticleViewDataLinked({
              ...finalArticle?.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
            setArticleViewNameLinked(articleName);
          } else {
            setRelatedArticleData(finalArticle.articleData.slice(1));
            setArticleViewName(articleName);
            setArticleViewNameLinked(searched?.articleName);
            if (
              !hasInformationTag &&
              !finalArticle.articleData[0]?.ArticleBody.slice(0, 7) ===
                "<p id='"
            ) {
              let location = await findLocation(
                finalArticle.articleData[0]?.ArticleBody
              );

              if (location && location.length > 0) {
                finalArticle.articleData[0].ArticleLocation =
                  location.join(" ");

                finalArticle.articleData[0].ArticleBody =
                  finalArticle.articleData[0]?.ArticleBody.slice(
                    location.join(" ")?.length
                  );
              }
            }
            setArticleViewData({
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }
          setTimeout(() => {
            updateInsight("view", {
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }, 1200);
        }
        return finalArticle;
      }
      return { photographs, articleData: [] };
    } catch (error) {
      console.log("getFinalArticleArray error: ", error);
    }
  };
  const findLocation = (data) => {
    const iterated = [];
    if (data && data.length > 0) {
      let split = data.split(" ").slice(0, 4);

      for (let i = 0; i < split.length; i++) {
        const ele = split[i];

        if (ele.search(":") !== -1) {
          split[i] = ele.split(":")[0] + ":";
          for (let s = 0; s < i + 1; s++) {
            const element = split[s];
            iterated.push(element);
          }
        } else {
        }
      }
    }
    return iterated;
  };
  //updated
  const handleNextArticle = async (articleName) => {
    setRelatedArticleData([]);
    setArticleViewDataLinked({});
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition?.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    let length = propsArticles?.length - 1;

    if (currArticleIndex < length) {
      let array = propsArticles;

      let searched = await getContinuedArticles(
        array?.[currArticleIndex + 1]?.ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }

      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex + 1].ArticleName
      }.json?v=${filteredDate?.Timestamp}`;
      let newArticleName = array[currArticleIndex + 1].ArticleName;
      setArticleViewName(array[currArticleIndex + 1].ArticleName);

      setCurrArticleIndex(currArticleIndex + 1);
      if (!path.includes("undefined"))
        axios
          .get(path)
          .then(async (response) => {
            let array = [];
            for (let i = 0; i < response.data.length; i++) {
              const ele = response.data[i];
              let obj = { ...ele, SortOrder: i + 1 };
              array.push(obj);
            }

            response.data = array;

            const finalArticle = await getFinalArticleArray(
              response.data,
              newArticleName,
              searched,
              interchange,
              true
            );
          })
          .catch((err) => {
            console.log("handleNextArticle err: ", err);
          });
    }
  };

  const handlePrevArticle = async (articleName) => {
    setArticleViewDataLinked({});
    setRelatedArticleData([]);
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition?.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    // let pageNumber = currentPage.PageName.slice(11, 14);
    let array = propsArticles;

    if (currArticleIndex > 0) {
      let searched = await getContinuedArticles(
        array?.[currArticleIndex - 1]?.ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }

      path = `${path_prefix}${pageNumber}/${
        array?.[currArticleIndex - 1]?.ArticleName
      }.json?v=${filteredDate?.Timestamp}`;
      let newArticleName = array?.[currArticleIndex - 1]?.ArticleName;
      setCurrArticleIndex(currArticleIndex - 1);
      setArticleViewName(array?.[currArticleIndex - 1]?.ArticleName);

      if (!path.includes("undefined"))
        axios
          .get(path)
          .then(async (response) => {
            let array = [];
            for (let i = 0; i < response.data.length; i++) {
              const ele = response.data[i];
              let obj = { ...ele, SortOrder: i + 1 };
              array.push(obj);
            }

            response.data = array;
            const finalArticle = await getFinalArticleArray(
              response.data,
              newArticleName,
              searched,
              interchange,
              true
            );
          })
          .catch((err) => {
            console.log("handlePrevArticle err: ", err);
          });
    }
  };

  const searchLink = async (path, zone) => {
    let response = [];
    await axios.get(path).then((res) => {
      if (res?.data?.linkJson?.length > 0) {
        response = res?.data?.linkJson.filter(
          (v) => v.FromZoneID === zone.ZoneId
        );
      }
    });
    return response;
  };
  const togglePopular = async (value) => {
    await setPopularOpen(value);
  };
  const toggleLeftDrawer = async (key) => {
    setLeftDrawerOpen(key);
  };
  var digestViewWidth = classNames({
    "digest-view-width": isPopularDrawerOpen,
  });

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setIsLandingPage(false);
    JSCookie.set("isLanding", false);
  }, []);
  return (
    <React.Fragment>
      <div className="desktop-only">
        <Row
          className="mt-3 digest-row-ie"
          style={{
            paddingRight:
              isPopularDrawerOpen && window.innerWidth > 1365
                ? " 356px"
                : "3.5rem",
            marginTop: "50px",
          }}
        >
          {/* <Col md="1"></Col> */}
          <Col sm="12" md="12" lg="12" xl="12" className="padding-ipad">
            {dayIndexData &&
              dayIndexData.length > 0 &&
              dayIndexData.map((item, index) => {
                let path = `${s3Source}/PublicationData/${
                  initialPublication?.publicationcode
                }/${initialEdition?.editionid}/${dateArray?.join(
                  "/"
                )}/PageThumb/${item.PageName}.jpg?v=${filteredDate?.Timestamp}`;

                return (
                  <React.Fragment key={item.PageTitle + index}>
                    <Card className="card-digest">
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html:
                            item && item.PageTitle
                              ? item.PageTitle
                              : `Page ${index + 1}`,
                        }}
                        style={{ backgroundColor: themeColor }}
                        className="heading-sticky-lg  col col-md-12  text-white"
                      ></div>
                      <CardBody>
                        <Row>
                          <Col
                            onClick={() => {
                              pageClick(path, index);
                            }}
                            sm="3"
                            md="3"
                            lg="3"
                            xl="3"
                            className="frozen"
                          >
                            <Link
                              to={`/${publicationsData[
                                JSCookie.get("nbtpubindex")
                              ]?.publicationname
                                ?.split(" ")
                                .join("-")
                                .toLowerCase()}/${publicationsData[
                                JSCookie.get("nbtpubindex")
                              ]?.editions[
                                JSCookie.get("nbtedindex")
                              ]?.editionname
                                ?.split(" ")
                                .join("-")
                                .toLowerCase()}`}
                            >
                              <CardImg
                                className="digest-image"
                                src={path}
                                alt={item?.PageName}
                              />
                            </Link>
                          </Col>

                          <Col
                            sm="9"
                            md="9"
                            lg="9"
                            className=" pt-5 cursor-pointer digest-card-body digest-col-ie"
                          >
                            {item.Articles &&
                              item.Articles.length > 0 &&
                              item.Articles.map((article, i) => {
                                return (
                                  <>
                                    <div
                                      onClick={() => {
                                        pageClick(path, index);

                                        openArticleViewDigest(
                                          article?.ArticleName
                                          // item?.PageName
                                        );
                                        toggleArticleViewModal();
                                      }}
                                    >
                                      <h6
                                        key={i}
                                        className="font-weight-bold "
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            article &&
                                            article.ArticleTitle.split(
                                              "`"
                                            ).join("₹"),
                                        }}
                                      ></h6>
                                      <p
                                        className="mb-0"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            article &&
                                            article.ArticleBody?.split(
                                              "`"
                                            ).join("₹"),
                                        }}
                                      ></p>
                                    </div>
                                    <hr className="horizontal-line" />
                                  </>
                                );
                              })}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                );
              })}
          </Col>
        </Row>
      </div>
      <div
        className="pt-5 mx-0   mob-only"
        style={{
          marginTop:
            localStorage.getItem("contentRec") === "true" ? "5rem" : "1rem",
        }}
      >
        <div className="p-0">
          <TabContent activeTab={activeTab} className="mx-4 pt-3">
            <Row className="mb-2">
              <Col sm="12" className="px-0">
                {dayIndexData &&
                  dayIndexData.length > 0 &&
                  dayIndexData.map((item, index) => {
                    let path = `${s3Source}/PublicationData/${
                      initialPublication?.publicationcode
                    }/${initialEdition?.editionid}/${dateArray?.join(
                      "/"
                    )}/PageThumb/${item.PageName}.jpg?v=${
                      filteredDate?.Timestamp
                    }`;

                    return (
                      <>
                        <div
                          onClick={() => {
                            pageClick(path, index);
                            tomainPage();
                          }}
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html:
                              item && item.PageTitle
                                ? item.PageTitle
                                : `Page ${index + 1}`,
                          }}
                          // description
                          className="heading-sticky-mob  col col-md-12  text-white"
                          style={{
                            top:
                              localStorage.getItem("contentRec") === "true"
                                ? "114px"
                                : "50px",
                          }}
                        />

                        {item.Articles &&
                          item.Articles.length > 0 &&
                          item.Articles.map((art, ind) => {
                            return (
                              <>
                                <Row key={ind}>
                                  <Col
                                    style={{
                                      display: "contents",
                                      cursor: "pointer",
                                    }}
                                  ></Col>
                                  <Col
                                    onClick={() => {
                                      pageClick(path, index);
                                      openArticleViewDigest(
                                        art?.ArticleName
                                        // item?.PageName
                                      );
                                      toggleArticleViewModal();
                                    }}
                                    style={{ textAlign: "justify" }}
                                  >
                                    <h6
                                      className="font-weight-bold mb-0 "
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          art &&
                                          art.ArticleTitle.split("`").join("₹"),
                                      }}
                                    ></h6>

                                    <p
                                      style={{
                                        fontSize: "small",
                                        lineHeight: "normal",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          art &&
                                          art.ArticleBody?.split("`").join("₹"),
                                      }}
                                    ></p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
              </Col>
            </Row>
          </TabContent>
        </div>
      </div>

      {desktopView ? (
        <Modal
          isOpen={isOpenArticleModal}
          className="mymodal"
          overlayClassName="myoverlay"
          onRequestClose={toggleArticleViewModal}
        >
          <ArticleCard
            pageClick={(link, jumpTo) => {
              tomainPage();
              pageClick(link, jumpTo);
            }}
            dayIndexData={dayIndexData}
            toggleArticleViewModal={toggleArticleViewModal}
            themeColor={themeColor}
            defaultInitialPubCode={initialPublication}
            defaultInitialEditionName={initialEdition?.editionid}
            dateArray={dateArray}
            currentPage={currentPage}
            toggle={toggle}
            isOpenMobArticle={isOpenMobArticle}
            toggleShare={toggleShare}
            handleNextArticle={handleNextArticle}
            handlePrevArticle={handlePrevArticle}
            isOpen2={isOpen2}
            openArticleViewDigest={openArticleViewDigest}
            propsArticles={propsArticles}
            setIsOpenMobArticle={setIsOpenMobArticle}
            pageNameDigest={pageNameDigest}
            setAd={() => {}}
          />
        </Modal>
      ) : (
        <Modal
          isOpen={isOpenArticleModal}
          className="mymodal-mob "
          overlayClassName={
            localStorage.getItem("contentRec") === "true"
              ? "myoverlay-mob"
              : "myoverlay-mob-noad"
          }
        >
          <ArticleCard
            pageClick={(link, jumpTo) => {
              tomainPage();
              pageClick(link, jumpTo);
            }}
            dayIndexData={dayIndexData}
            toggleArticleViewModal={toggleArticleViewModal}
            themeColor={themeColor}
            pageNameDigest={pageNameDigest}
            defaultInitialPubCode={initialPublication}
            defaultInitialEditionName={initialEdition?.editionid}
            dateArray={dateArray}
            currentPage={currentPage}
            toggle={toggle}
            isOpenMobArticle={isOpenMobArticle}
            toggleShare={toggleShare}
            handleNextArticle={handleNextArticle}
            handlePrevArticle={handlePrevArticle}
            isOpen2={isOpen2}
            openArticleViewDigest={openArticleViewDigest}
            propsArticles={propsArticles}
            setIsOpenMobArticle={setIsOpenMobArticle}
            setAd={() => {}}
          />
        </Modal>
      )}
    </React.Fragment>
  );
}
