import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import "../components/sidebar.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col, CardBody, Card, CardHeader, Spinner } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { DFPSlotsProvider, AdSlot, DFPManager } from "react-dfp";

// context
import { AppContext } from "../contexts/AppContext";
import { Facebook, Twitter, XCircle, Mail } from "react-feather";
import { FacebookIcon, TwitterIcon } from "react-share";
import { updatesEvent } from "../functions/cleverTap";
const useStyles = makeStyles({
  list: {
    width: "320px",
    // width: "20vw",
    // marginTop: "10vh",
    marginBottom: "50px",
    backgroundColor: "#f8f9fa",
  },
  fullList: {
    // width: "auto",
    overflowY: "scroll",
    overflowX: "hidden",
    backgroundColor: "#f8f9fa",
    height: "80%",
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ccc",
      borderRight: "3px solid white",
      borderLeft: "3px solid white",
      borderTopRightRadius: "22px",
      borderTopLeftRadius: "22px",
      borderBottomRightRadius: "22px",
      borderBottomLeftRadius: "22px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(134, 133, 133)",
      borderRight: "3px solid white",
      borderLeft: "3px solid white",
      borderTopRightRadius: "22px",
      borderTopLeftRadius: "22px",
      borderBottomRightRadius: "22px",
      borderBottomLeftRadius: "22px",
    },
  },
  drawerPaper: {
    // overflowX: "hidden",
    // height: "100rem",
    // overflowY: "scroll",
    top: "160px",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // width: "0.5vw",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "rgb(174, 174, 175)",
    // },
  },
});

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});

export default function PopularRightDrawer(props) {
  const classes = useStyles();

  // global state
  const {
    isPopularDrawerOpen,
    setIsPopularDrawerOpen,
    setArticleViewData,
    setArticleViewName,
    fit2page,
    s3Source,
    isRegional,
    setIsRegional,
    currentPage,
    dateArray,
    pageClick,
    themeColor,
    initialPublication,
    initialEdition,
    todaysDate,
    items,
    mostViewed,
    mostShared,
    filteredDate,
    setLoading,
    isPaidUser,
  } = useContext(AppContext);
  const popularButtonRef = useRef();
  const [popularDrawer, setPopularDrawer] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: true,
  });
  const [expanded, setExpanded] = React.useState(false);
  const [innrPageBanners, setInnerPageBanner] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openArticleView = (articleName) => {
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/ArticleZoneJson/`;

    let pageNumber = currentPage.PageName.slice(11, 14);
    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${filteredDate?.Timestamp}`;

    axios.get(path).then((response) => {
      setArticleViewData(response.data);
    });
  };
  const toggleDrawer2 = () => {
    if (!fit2page) {
      setIsPopularDrawerOpen((currState) => !currState);
    }
  };
  useEffect(() => {
    if (initialPublication?.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [initialPublication]);

  useEffect(() => {
    const getBannerData = async () => {
      const url = `${process.env.REACT_APP_CMS_URL}/banner/epaper/${process.env.REACT_APP_CHANNEL_ID}`;
      await axios.get(url).then(async (res) => {
        res?.data?.data.map((data) => {
          data.img = `${process.env.REACT_APP_S3_IMAGE_PATH}/${process.env.REACT_APP_CHANNEL_NAME}${data.img}`;
        });
        await setInnerPageBanner(
          res?.data?.data.filter((v) => v.label === "Desktop Inner page")[0]
        );
      });
    };
    getBannerData();
  }, []);

  const List = () => (
    <div>
      {!isRegional && (
        <Accordion
          // className="rightdrawer-accordian"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="pl-3" sx={{ width: "100%", flexShrink: 0 }}>
              MOST READ
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {mostViewed &&
                mostViewed.length > 0 &&
                mostViewed.map((item, index) => {
                  let article_name = item?.articlename.split("_");
                  let pgname = article_name[3].slice(1);
                  article_name.pop();
                  article_name.pop();
                  const image_url = `${s3Source}/PublicationData/${
                    initialPublication?.publicationcode
                  }/${initialEdition?.editionid}/${article_name[2]}/${
                    article_name[1]
                  }/${article_name[0]}/PageThumb/${
                    article_name.join("_") + "_" + initialEdition?.editionid
                  }.jpg?v=${filteredDate?.Timestamp}`;
                  return (
                    <li
                      className="most-read"
                      key={"viewed_" + item.articlename + index}
                      onClick={async () => {
                        await pageClick(
                          image_url,
                          parseInt(pgname) - 1,
                          item.articlename
                        );
                        setLoading(false);
                      }}
                      style={{
                        fontSize: "small",
                        listStyleType: "none",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center px-0 mb-1">
                        <span>
                          {index + 1}.{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.articletitle?.length > 30
                                  ? item?.articletitle.slice(0, 30) + " ..."
                                  : item?.articletitle,
                            }}
                          />
                        </span>
                        {/* <div
                    className="badge pill text-white px-1"
                    style={{
                      backgroundColor: themeColor,
                    }}
                  >
                    {item?.view_count}
                  </div> */}
                      </div>
                    </li>
                  );
                })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      <div className="my-2" />
      {mostShared && mostShared.length > 0 && (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="pl-3" sx={{ width: "100%", flexShrink: 0 }}>
              MOST SHARED
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {mostShared.map((item, index) => {
                let article_name = item?.articlename.split("_");
                let pgname = article_name[3].slice(1);
                article_name.pop();
                article_name.pop();
                const image_url = `${s3Source}/PublicationData/${
                  initialPublication?.publicationcode
                }/${initialEdition?.editionid}/${article_name[2]}/${
                  article_name[1]
                }/${article_name[0]}/PageThumb/${
                  article_name.join("_") + "_" + initialEdition?.editionid
                }.jpg?v=${filteredDate?.Timestamp}`;
                return (
                  <li
                    className="most-read"
                    key={"viewed_" + item.articlename + index}
                    onClick={async () => {
                      await pageClick(
                        image_url,
                        parseInt(pgname) - 1,
                        item.articlename
                      );
                      setLoading(false);
                    }}
                    style={{
                      fontSize: "small",
                      listStyleType: "none",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center px-0 mb-1">
                      <span>
                        {index + 1}.{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.articletitle?.length > 20
                                ? item?.articletitle.slice(0, 20) + " ..."
                                : item?.articletitle,
                          }}
                        />
                      </span>
                      {item?.share_count?.facebook && (
                        <div
                          className="badge pill text-white px-1 d-flex align-items-center"
                          style={{
                            backgroundColor: "#3c5997",
                          }}
                        >
                          <Facebook size={14} />
                          <span className="ml-1">
                            {item?.share_count?.facebook}
                          </span>
                        </div>
                      )}

                      {item?.share_count?.twitter && (
                        <div
                          className="badge pill text-white px-1 d-flex align-items-center"
                          style={{
                            backgroundColor: "#04abed",
                          }}
                        >
                          <TwitterIcon size={14} />
                          <span className="ml-1">
                            {item?.share_count?.twitter}
                          </span>
                        </div>
                      )}
                      {item?.share_count?.email && (
                        <div
                          className="badge pill text-white px-1 d-flex align-items-center"
                          style={{
                            backgroundColor: "#04abed",
                          }}
                        >
                          <Mail size={14} />
                          <span className="ml-1">
                            {item?.share_count?.email}
                          </span>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
  const [propsarticles, setpropsArticles] = useState([]);
  useEffect(() => {
    setpropsArticles(items?.Articles);
  }, [props]);

  // Usage example:

  // somewhere in the code...
  // GPTAdsManager.initializeAds(false, true);

  return (
    <div className="desktop-screen-only">
      {!fit2page && window.innerWidth > 1365 && (
        <div
          className="button-fixed3"
          style={{ backgroundColor: themeColor }}
          onClick={() => {
            toggleDrawer2();
            updatesEvent(
              "updates_float",
              "updates_float",
              "updates_float",
              "updates_float"
            );
          }}
        >
          <b className="button-fixed-text-3">UPDATES</b>
        </div>
      )}
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.root}
        style={{ backgroundColor: "none" }}
        anchor={"right"}
        // variant="permanent"
        variant="persistent"
        open={isPopularDrawerOpen}
        onClose={() => setIsPopularDrawerOpen(false)}
      >
        <div className={clsx(classes.list, classes.fullList)}>
          <div style={{ height: "auto" }}>
            <Row className="pt-2 mx-0">
              <Col className="px-0">
                <Card className="border-0  cursor-pointer bg-light popular-card-width">
                  <CardHeader className=" popular-card-header">
                    <Row>
                      <Col
                        md="9"
                        sm="9"
                        lg="9"
                        xl="9"
                        className="text-center popular-name-pub"
                      >
                        <b>
                          {/* {initialPublication?.publicationnameHindi} {"- "} */}
                          {initialEdition?.editionnameHin}
                        </b>
                        <br />
                        <b className=" popular-date-pub">
                          {todaysDate !== "undefined" ? todaysDate : ""}
                        </b>
                      </Col>
                      <Col style={{ display: "flex", justifyContent: "end" }}>
                        <XCircle
                          className="close-popular"
                          onClick={() => toggleDrawer2()}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody
                    className="px-0 py-2"
                    // style={{ borderBottom: "1px solid black" }}
                  >
                    <List />

                    <div className="my-2" />
                    {initialPublication?.adFirstRightId && (
                      <div>
                        {localStorage.getItem("contentRec") === "true" &&
                          !isPaidUser && (
                            <div className="my-1">
                              <Ad
                                path={initialPublication?.adFirstRightPath}
                                width={300}
                                height={250}
                                divId={initialPublication?.adFirstRightId}
                                number={1}
                              />
                            </div>
                          )}
                        {/* //banner */}
                        <a href={innrPageBanners?.url}>
                          <img
                            width="300px"
                            height="250px"
                            src={`${process.env.REACT_APP_IMAGE_URL}${innrPageBanners?.img}`}
                            alt="banner 3"
                          />
                        </a>

                        {localStorage.getItem("contentRec") === "true" &&
                          !isPaidUser && (
                            <div className="my-1">
                              <Ad
                                path={initialPublication?.adThirdRightPath}
                                width={300}
                                height={250}
                                divId={initialPublication?.adThirdRightId}
                                number={3}
                              />
                            </div>
                          )}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

const Ad = ({ path, width, height, divId, number }) => {
  const id = React.useMemo(() => `${divId}`, []);
  React.useEffect(() => {
    const slot = GPTAdsManager.createSlot(path, width, height, id);
    slot.display();

    return () => {
      slot.destroy();
    };
  }, [path, width, height]);
  return <div id={id} />;
};
