import { Height } from "@material-ui/icons";
import React, { useState, useCallback, useRef, useEffect } from "react";
import publicationJson from "../config/Publications.json";
import axios from "axios";
import JSCookie from "js-cookie";
import publicIp from "public-ip";
import jwtDecode from "jwt-decode";
import { getTicketId } from "../helper";
import jsonp from "jsonp";

// import ReactGA from "react-ga";
import * as moment from "moment";
import { isIOS, isAndroid } from "react-device-detect";
import {
  activeDaysEvents,
  editionEvents,
  printEvents,
  publicationEvents,
  subscriptionEvents,
} from "../functions/cleverTap";

export const AppContext = React.createContext();

const AppContextProvider = (props) => {
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [TTS, setTTS] = useState("");
  // const [s3Source, setS3Source] = useState("");
  const todayCurrentDate = moment(new Date()).format("DD-MM-YYYY");
  const [IPData, setIPData] = useState({});
  const [isPopularDrawerOpen, setIsPopularDrawerOpen] = useState(true);
  const [isFitToHeight, setIsFitToHeight] = useState(0);
  const [isFitToWidth, setisFitToWidth] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [articles, setArticles] = useState([]);
  const [articles2] = useState();
  const [articlesPage2, setArticlesPage2] = useState([]);
  const [widthPage2, setWidthPage2] = useState(0);
  const [isEnableAnalytics, setIsEnableAnalytics] = useState("Enable");
  const [isEnableAds, setIsEnableAds] = useState("Enable");
  const [multiplier, setMultiplier] = useState(0);
  //loader for mainPage
  const [mainPageLOader, setMainPageLoader] = useState(false);
  const [ratioMainPage, setRatioMainPage] = useState(1);
  const [fit2page, setFit2page] = useState(false);
  const [fit2pagesArray, setFit2pagesArray] = useState([]);
  const [platform, setPlatform] = useState("web");

  ////for article view//
  const [isOpenArticleModal, setIsOpenArticleModal] = useState(false);
  const [articleViewName, setArticleViewName] = useState(null);
  const [articleViewNameLinked, setArticleViewNameLinked] = useState(null);
  const [articleViewData, setArticleViewData] = useState(null);
  const [relatedArticleData, setRelatedArticleData] = useState(null);
  const [articleViewDataLinked, setArticleViewDataLinked] = useState(null);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [isPanoramaPage, setIsPanoramaPage] = useState(false);
  const [isPanoramaPage2, setIsPanoramaPage2] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [currArticleIndex, setCurrArticleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState(0);
  const [totalShareCount, setTotalShareCount] = useState(0);
  const [shares, setShares] = useState({
    facebook: 0,
    twitter: 0,
    whatsapp: 0,
    email: 0,
  });
  const [isRegional, setIsRegional] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [activeTabName, setActiveTabName] = useState("article");
  const [linkJsonURL, setLinkJsonURL] = useState("");
  const [hasInfoZone, setHasInfoZone] = useState(false);
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [checkHeight, setCheckHeight] = useState(false);
  //contact
  const [isOpenContactModal, setIsOpenContactModal] = React.useState(false);
  const [isOpenFeedBackModal, setIsOpenFeedBackModal] = React.useState(false);

  // check paid user
  const [isPaidUser, setIsPaidUser] = useState(false);

  //left publication drawer mob state
  const [state, setState] = React.useState({
    // top: false,
    left: false,
    // bottom: false,
    // right: false,
  });
  const toggle = () => {
    setIsOpen2(!isOpen2);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const toggleShare = () => setIsOpenMobArticle(!isOpenMobArticle);

  function toggleArticleViewModal() {
    if (isOpenArticleModal) {
      setArticleViewData(null);
      setArticleViewDataLinked(null);
      setHasInfoZone(false);
    }
    setIsOpenArticleModal(!isOpenArticleModal);
  }

  function ClickAnywhereClose() {
    document.addEventListener("mouseup", function (e) {
      setIsOpenArticleModal(!isOpenArticleModal);
    });
  }

  const calculateNewWidth = useCallback((coord, newWidth, currWidth) => {
    return (coord * newWidth) / currWidth;
  }, []);

  const calculateNewHeight = useCallback(() => {
    return window.innerHeight;
  }, [height]);

  const updateInsight = async (type, data, share_type) => {
    return;
    let name_split = await data?.ArticleName?.split("_");
    let post_url = `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/insight`;
    if (name_split && name_split.length > 0) {
      let data_object = {
        type,
        articlename: data?.ArticleName,
        articletitle: data?.ArticleTitle,
        edition_code: name_split?.[5], //edition_code,
        edition_date: `${name_split?.[2]}-${name_split?.[1]}-${name_split?.[0]}`, //edition_date,
      };
      if (type === "share") {
        data_object.share_type = share_type;
      }
      axios
        .post(post_url, data_object, {
          headers: {
            token: JSCookie.get("andre"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (type === "view") {
            setViews(res.data.view_count);
          } else {
            let shareCount = {
              ...shares,
              [share_type]: res.data.share_count[share_type],
            };
            setShares(shareCount);
            let totalShareCount =
              shareCount["email"] ??
              0 + shareCount["facebook"] ??
              0 + shareCount["whatsapp"] ??
              0 + shareCount["twitter"] ??
              0;
            setTotalShareCount(totalShareCount ?? 0);
          }
        })
        .catch((err) => {
          console.log("updateInsight err:", err);
        });
    }
  };
  const refactorHighlight = useCallback(
    (newWidth, currWidth) => {
      setArticles((currentState) => {
        const newArticles = currentState?.PageContent?.map((article) => {
          return {
            ...article,
            Zones: article.Zones.map((zone) => {
              return {
                ...zone,
                Y: calculateNewWidth(zone.Y, newWidth, currWidth),
                X: calculateNewWidth(zone.X, newWidth, currWidth),
                H: calculateNewWidth(zone.H, newWidth, currWidth),
                W: calculateNewWidth(zone.W, newWidth, currWidth),
              };
            }),
          };
        });
        return { ...currentState, PageContent: newArticles };
      });
    },
    [articles, setArticles, calculateNewWidth]
  );

  const refactorHighlightPage2 = useCallback(
    (newWidth, currWidth) => {
      setArticlesPage2((currentState) => {
        const newArticles = currentState?.PageContent?.map((article) => {
          return {
            ...article,
            Zones: article.Zones.map((zone) => {
              return {
                ...zone,
                Y: calculateNewWidth(zone.Y, newWidth, currWidth),
                X: calculateNewWidth(zone.X, newWidth, currWidth),
                H: calculateNewWidth(zone.H, newWidth, currWidth),
                W: calculateNewWidth(zone.W, newWidth, currWidth),
              };
            }),
          };
        });
        return { ...currentState, PageContent: newArticles };
      });
    },
    [articlesPage2, setArticlesPage2, calculateNewWidth]
  );

  const vw = useCallback((w) => {
    return (w * window.innerWidth) / 100;
  }, []);

  const vh = useCallback((h) => {
    return (h * window.innerHeight) / 100;
  }, []);

  const print = (elementId) => {
    const printContent = document.getElementById(elementId);

    // Create a hidden iframe for printing
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    // Create a document within the iframe
    const iframeDocument = iframe.contentDocument;
    const iframeWindow = iframe.contentWindow;

    // Add the custom text before the content of the element
    const contentToPrint = printContent?.innerHTML;

    // Write the content to the iframe document
    iframeDocument.open();
    iframeDocument.write(contentToPrint);
    iframeDocument.close();

    // Add CSS rules to the iframe document
    const style = iframeDocument.createElement("style");
    style.innerHTML = `
      img {
        max-height: 900px;
        width: auto !important;
        max-width: 1000px;
      }
    `;
    iframeDocument.head.appendChild(style);

    // Focus and print the iframe content
    iframeWindow.focus();
    iframeWindow.print();

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [messageOpacity, setMessageOpacity] = useState("");
  const [messageTop, setMessageTop] = useState("");
  const [ft1Duration, setFt1Duration] = useState(null);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  // import from app.js
  const [loading, setLoading] = useState(false);
  const [publicationsData, setPublicationsData] = useState([]);
  const [hpImgBanner, setHpImgBanner] = useState("");
  const [editionData, setEditionsData] = useState([]);
  const [initialPublication, setInitialPublication] = useState({});
  const [initialEdition, setInitialEdition] = useState({});
  const [todaysDate, setTodaysDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [dateIndexData, setDateIndexData] = useState([]);
  const [dayIndexData, setDayIndexData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [nextPage, setNextPage] = useState({});
  const [displayArticle, setDisplayArticle] = useState("");
  // const [logo, setLogo] = useState(null);
  // const [isLandingPage, setIsLandingPage] = useState(true);
  const [items, setItems] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [showPageThumb, setShowPageThumb] = useState(false);

  const [currentIndexHome, setCurrentIndexHome] = useState(0);

  const [currentPublication, setCurrentPublication] = useState({});
  // const [fit2page, setFit2page] = useState(false);
  const [isSecondPage, setIsSecondPage] = useState(false);

  const [contentModalURL, setContentModalURL] = useState("");
  //
  const [logo, setLogo] = useState();
  const [themeColor, setThemeColor] = useState();
  const [propsArticles, setPropsArticles] = useState([]);
  const [favIcon, setFavicon] = useState();
  const [mostViewed, setMostViewed] = useState([]);
  const [mostShared, setMostShared] = useState([]);
  const [filteredDate, setFilteredDate] = useState({});
  const [linkJsonData, setLinkJsonData] = useState({});
  const [articleLoading, setArticleLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const getPublicationsData = async (take_first, pageName) => {
    try {
      setDateArray([]);
      setInitialPublication({});
      setInitialEdition({});
      setEditionsData([]);
      setCurrentPage({});
      setNextPage({});

      var pubIndex = 0,
        edIndex = 0;

      // key-value
      if (JSCookie.get("nbtpubindex")) {
        pubIndex = JSCookie.get("nbtpubindex");
      } else {
        JSCookie.set("nbtpubindex", pubIndex, { expires: 3 });
      }
      if (JSCookie.get("nbtedindex")) {
        edIndex = JSCookie.get("nbtedindex");
      } else {
        JSCookie.set("nbtedindex", edIndex, { expires: 3 });
      }
      let tempInitialPub = "";
      let tempInitialEdition = "";

      // const favicon = document.getElementById("favicon");

      tempInitialPub = publicationJson.Publications[Number(pubIndex)];
      await setInitialPublication({
        ...tempInitialPub,
        index: Number(pubIndex),
      });
      await setCurrentPublication({
        ...tempInitialPub,
        index: Number(pubIndex),
      });

      tempInitialEdition =
        publicationJson.Publications[Number(pubIndex)].editions[
          Number(edIndex)
        ];
      await setInitialEdition({
        ...tempInitialEdition,
        index: Number(edIndex),
      });

      await setPublicationsData(publicationJson.Publications); // array of publications.

      await setEditionsData(
        publicationJson.Publications[Number(pubIndex)].editions
      ); // array of editions of the await set publication
      document.title =
        publicationJson.Publications[Number(pubIndex)]?.publicationname +
        " | e-Edition";
      await setThemeColor(
        publicationJson.Publications[Number(pubIndex)]?.theme_color
      );
      await setLogo(tempInitialPub.logo);
      // favicon.setAttribute("href", tempInitialPub.favicon);

      setRootStyles(tempInitialPub);
      await getDates(
        tempInitialPub,
        tempInitialEdition,
        Number(pubIndex),
        Number(edIndex),
        take_first,
        pageName
      );

      // });
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };
  // useEffect(() => {
  //   setPropsArticles(items?.Articles);
  //   console.log("items?.Articles: ", items?.Articles);
  // }, [items]);

  const setRootStyles = (tempInitialPub) => {
    let r = document.querySelector(":root");
    r.style.setProperty("--primary", tempInitialPub.theme_color);
    //setting font
    r.style.setProperty("--font", tempInitialPub.font.family);
    r.style.setProperty(
      "--articletitlefontsize",
      tempInitialPub.font.article.title.fontsize
    );
    r.style.setProperty(
      "--articletitlelineheight",
      tempInitialPub.font.article.title.lineheight
    );
    r.style.setProperty(
      "--articlebodyfontsize",
      tempInitialPub.font.article.content.fontsize
    );
    r.style.setProperty(
      "--articlebodylineheight",
      tempInitialPub.font.article.content.lineheight
    );
    r.style.setProperty(
      "--relatedtitlefontsize",
      tempInitialPub.font.related.title.fontsize
    );
    r.style.setProperty(
      "--relatedtitlelineheight",
      tempInitialPub.font.related.title.lineheight
    );
    r.style.setProperty(
      "--relatedbodyfontsize",
      tempInitialPub.font.related.content.fontsize
    );
    r.style.setProperty(
      "--relatedbodylineheight",
      tempInitialPub.font.related.content.lineheight
    );
  };

  useEffect(() => {
    if (localStorage.getItem("contentRec"))
      setIsEnableAds(
        localStorage.getItem("contentRec") === "true" ? "Enable" : "Disable"
      );

    if (localStorage.getItem("webAnalytics"))
      setIsEnableAnalytics(
        localStorage.getItem("webAnalytics") === "true" ? "Enable" : "Disable"
      );
  }, []);

  const getDates = async (
    tempInitialPub,
    tempInitialEdition,
    pubIndex,
    edIndex,
    take_first,
    pageName
  ) => {
    let json = "DateIndex";
    if (process.env.REACT_APP_ENV === "PROD") {
      json = "datekey";
    }
    const url = `${
      publicationJson[process.env.REACT_APP_ENV]
    }/PublicationData/${tempInitialPub.publicationcode}/${
      tempInitialEdition.editionid
    }/${json}.json`;
    await axios
      .get(url)
      .then(async (res) => {
        //to get latest date first
        let newDateIndex = [];

        if (process.env.REACT_APP_ENV !== "PROD") {
          for (let i = 0; i < res.data.DateIndex.length; i++) {
            const ele = res.data.DateIndex[i];
            newDateIndex.push({
              Date: ele,
              Timestamp: new Date().getTime(),
            });
          }
        } else {
          if (res.data && res.data.length > 0) {
            newDateIndex = [...res.data];
          }
        }
        //sort to get latest date first
        let dateIndexSorted = await newDateIndex.sort((a, b) =>
          new Date(a.Date.split("-").reverse().join("-")) <
          new Date(b.Date.split("-").reverse().join("-"))
            ? 1
            : -1
        );
        if (
          JSCookie.get("fromArchive") &&
          JSCookie.get("nbteddate") &&
          dateIndexSorted?.filter((v) => v.Date === JSCookie.get("nbteddate"))
            ?.length == 0
        ) {
          dateIndexSorted.push({
            Date: JSCookie.get("nbteddate"),
            Timestamp: new Date().getTime(),
          });
          dateIndexSorted = await dateIndexSorted.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );
        }

        if (take_first) {
          await JSCookie.set("nbteddate", dateIndexSorted[0]?.Date);
        }

        await setDateIndexData(dateIndexSorted);

        if (
          JSCookie.get("nbteddate") &&
          JSCookie.get("nbtpubindex") == pubIndex &&
          JSCookie.get("nbtedindex") == edIndex
        ) {
          let today = JSCookie.get("nbteddate");
          await setTodaysDate(today);
          let date = JSCookie.get("nbteddate").split("-").reverse();
          await setDateArray(date);
        } else {
          let today = dateIndexSorted[0]?.Date;

          await setTodaysDate(today);
          // JSCookie.set("nbteddate", today);
          let date = dateIndexSorted[0].Date?.split("-").reverse();
          await setDateArray(date);
        }
        await getDatesApiData(
          tempInitialPub,
          tempInitialEdition,
          dateIndexSorted.filter(
            (v) => v.Date === JSCookie.get("nbteddate")
          )?.[0],
          pageName
        );
      })
      .catch((err) => {
        console.log("getDates err: ", err);
        setLoading(false);
      });
  };

  const getDatesApiData = async (
    tempInitialPub,
    tempInitialEdition,
    date,
    pageName
  ) => {
    if (date) {
      await getLinkJson(tempInitialPub, tempInitialEdition, date);
      await getInsights(tempInitialEdition);
      let url = `${
        publicationJson[process.env.REACT_APP_ENV]
      }/PublicationData/${tempInitialPub.publicationcode}/${
        tempInitialEdition.editionid
      }/${date?.Date.split("-")
        .reverse()
        .join("/")}/DayIndex/${date?.Date.split("-").join("_")}_${
        tempInitialEdition.editionid
      }.json?v=${date?.Timestamp}`;
      await axios
        .get(url)
        .then(async (dayIndexRes) => {
          await setDayIndexData(dayIndexRes.data.DayIndex);
          let pageTitleKey = {};

          if (pageName) {
            let filteredPage = dayIndexRes.data.DayIndex.findIndex(
              (v) => v.PageName === pageName
            );
            if (filteredPage !== -1) {
              await setCurrentPage({
                ...dayIndexRes.data.DayIndex[filteredPage],
                pageNumber: filteredPage + 1,
              });
              await setNextPage({
                ...dayIndexRes.data.DayIndex[filteredPage + 1],
                pageNumber: filteredPage + 2,
              });
            } else {
              await setCurrentPage({
                ...dayIndexRes.data.DayIndex[0],
                pageNumber: 1,
              });
              await setNextPage({
                ...dayIndexRes.data.DayIndex[1],
                pageNumber: 2,
              });
            }
          } else {
            await setNextPage({
              ...dayIndexRes.data.DayIndex[1],
              pageNumber: 2,
            });
            await setCurrentPage({
              ...dayIndexRes.data.DayIndex[0],
              pageNumber: 1,
            });
          }
          dayIndexRes.data.DayIndex.forEach((singlePage) => {
            if (!pageTitleKey[singlePage.PageTitle]) {
              pageTitleKey[singlePage.PageTitle] = [];
            }
            if (singlePage.Articles) {
              singlePage.Articles.forEach((singleArticle) => {
                pageTitleKey[singlePage.PageTitle].push({
                  title: singleArticle.ArticleTitle.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ),
                  name: singleArticle.ArticleName,
                  pagename: singlePage.PageName,
                });
              });
            }
          });
          await setItems(dayIndexRes?.data?.DayIndex?.[0]);

          await setPropsArticles(dayIndexRes?.data?.DayIndex?.[0]?.Articles);
          setSectionData(pageTitleKey);
        })
        .catch((err) => {
          console.log("getDatesApiData err: ", err);
          setLoading(false);
        });
    }
  };

  const getRelatedArticles = async (name, index) => {
    //get related articles;
    let n = name.split("/");
    let filename = n.pop();
    let fchunks = filename.split("_");
    fchunks.pop();
    if (fchunks.length > 3) fchunks.pop();
    let url = n.join("/") + "/" + fchunks.join("_") + "_" + n[5] + ".json";
    url = url.replace("PageThumb", "DayIndex");
    await axios
      .get(url)
      .then(async (response) => {
        await setItems(response?.data?.DayIndex?.[index]);

        await setPropsArticles(response?.data?.DayIndex?.[index]?.Articles);
      })
      .catch((err) => {
        console.log("getRelatedArticles err", err);
        setItems({});
        setLoading(false);
      });
  };
  useEffect(() => {
    // let exclude = ["/login"];
    // if (!JSCookie.get("isLanding")) {
    //   JSCookie.set("isLanding", false);
    // }
    // if (
    //   !exclude.includes(window.location.pathname) &&
    //   JSCookie.get("isLanding") === "false"
    // ) {
    const callFunctions = async () => {
      await updateCookies();
      // await getPublicationsData(true);
    };
    callFunctions();
    // }
  }, [window?.location?.pathname]);

  const handlePublication = async (publication, index) => {
    publicationEvents(
      "publication_button",
      "header",
      "publication_button",
      publication.publicationname
    );
    setLoading(true);
    setCurrentPublication(publication);
    const favicon = document.getElementById("favicon");

    await setFavicon(initialPublication.favicon);
    await JSCookie.set("nbtpubindex", index, { expires: 3 });
    await JSCookie.set("nbtedindex", 0, { expires: 3 });
    await getPublicationsData(true);
  };
  const setURL = async () => {
    await JSCookie.set("nbtpubindex", 0, { expires: 3 });
    await JSCookie.set("nbtedindex", 0, { expires: 3 });
    window.location.assign(
      `${
        window.location.origin
      }/navbharattimes/${publicationJson.Publications[0].publicationname
        .toLowerCase()
        .split(" ")
        .join("-")}/${publicationJson.Publications[0].editions[0].editionname
        .toLowerCase()
        .split(" ")
        .join("-")}`
    );
  };
  const updateCookies = async () => {
    let pub = window.location.pathname.split("/")[2]?.split("-").join(" ");
    let edn = window.location.pathname
      .split("/")[3]
      ?.split("-")
      .join(" ")
      .split("&")?.[0];
    if (pub === "undefined" || edn === "undefined") {
      setURL();
    }
    const exclude = ["/digest-view", "/home", "/gallery-view", "my-account"];
    if (pub) {
      let pub_filter = publicationJson.Publications.findIndex(
        (v) => v.publicationname.toLowerCase() === pub
      );
      if (pub_filter === -1) {
        if (window.location.pathname.split("/").length > 3) {
          window.location.assign(
            `${window.location.origin}/navbharattimes/home`
          );
        }
        // if (exclude.includes(pub)) {
        //   console.log("!exclude.includes(pub): ", !exclude.includes(pub));
        // } else {
        //   setURL();
        // }
      }
      if (edn && pub_filter !== -1) {
        let edn_filter = publicationJson.Publications[
          pub_filter
        ].editions.findIndex((v) => v.editionname.toLowerCase() === edn);
        await JSCookie.set("nbtpubindex", pub_filter, { expires: 3 });
        if (edn_filter !== -1) {
          await JSCookie.set("nbtedindex", edn_filter, { expires: 3 });
        } else {
          await JSCookie.set("nbtedindex", 0, { expires: 3 });
          window.location.assign(
            `${
              window.location.origin
            }/navbharattimes/${publicationJson.Publications[
              pub_filter
            ].publicationname
              .toLowerCase()
              .split(" ")
              .join("-")}/${publicationJson.Publications[
              pub_filter
            ].editions[0].editionname
              .toLowerCase()
              .split(" ")
              .join("-")}`
          );
        }
      }
    }
  };
  const getIP = async () => {
    const IP = await publicIp.v4();
    await fetch(
      `https://pro.ip-api.com/json/${IP}?fields=country,continentCode,city,zip,regionName,query&key=2tjq7YXrdexnO8N`
    ).then(async (res) => {
      const data = await res.json();
      setIPData(data);
      localStorage.setItem("continentCode", data.continentCode);
      if (data.continentCode !== "EU") {
        localStorage.setItem("webAnalytics", true);
        localStorage.setItem("contentRec", true);
      }
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("continentCode")) {
      getIP();
    } else {
      if (localStorage.getItem("continentCode") !== "EU") {
        localStorage.setItem("webAnalytics", true);
        localStorage.setItem("contentRec", true);
      }
    }
  }, []);
  const handleEdition = async (edition, index) => {
    editionEvents(
      "edition_button",
      "header",
      "edition_button",
      edition.editionname
    );
    setLoading(true);
    await JSCookie.set("nbtedindex", index, { expires: 3 });
    await getPublicationsData(true);
  };

  // useEffect(() => {
  const getInsights = async (tempInitialEdition) => {
    let date = JSCookie.get("nbteddate")?.split("-");
    if (date && date.length > 0 && tempInitialEdition?.editionid) {
      axios
        .get(
          `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/insight/${tempInitialEdition?.editionid}/${date[2]}-${date[1]}-${date[0]}`
        )
        .then((res) => {
          setMostViewed(res.data.most_viewed);
          setMostShared(res.data.most_shared);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };
  // let exclude = ["/home", "/login"];
  // if (!exclude.includes(window.location.pathname)) {
  //   getInsights();
  // } else {
  // }
  // }, [
  //   initialEdition,
  //   initialPublication,
  //   dateIndexData,
  //   dateArray,
  //   dayIndexData,
  // ]);

  const handleDate = async (date) => {
    setLoading(true);
    await JSCookie.set("nbteddate", date);
    getPublicationsData();
  };

  const setLogoAndTheme = (theme_color, logo) => {
    setLogo(logo);
    setThemeColor(theme_color);
  };

  useEffect(() => {
    const platform = window.innerWidth > 760 ? "web" : "mobile";
    setPlatform(platform);
  }, []);

  // const pageClick = async (name, index, displayArticle, isSearch) => {
  //   setLoading(true);
  //   if (index !== 0) {
  //     if (!localStorage.getItem("customer_details")) {
  //       if (!localStorage.getItem("ssoTicketId")) {
  //         window.open(
  //           `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window?.location?.origin}/sso.html`,
  //           "Ratting",
  //           "width=600,height=600,toolbar=0,status=0,"
  //         );
  //       } else {
  //         let valid = await validateCustomer();
  //         if (valid) {
  //           await setCurrentPage({
  //             ...dayIndexData[index],
  //             pageNumber: index + 1,
  //           });
  //           await setNextPage({
  //             ...dayIndexData[index + 1],
  //             pageNumber: index + 2,
  //           });
  //           await setItems(dayIndexData?.[index]);

  //           await setPropsArticles(dayIndexData?.[index]?.Articles);
  //           // await getRelatedArticles(name, index);

  //           await setDisplayArticle(displayArticle ?? "");
  //           await setIsSearch(
  //             { ...isSearch } ?? { search: "", from: "", articleName: "" }
  //           );
  //           await setShowPageThumb(false);
  //         }
  //       }
  //     } else {
  //       let customer_details = await validateCustomer();
  //       let customerData = JSON.parse(localStorage.getItem("customer_details"));
  //       if (customer_details && customerData.allow_access) {
  //         await setCurrentPage({
  //           ...dayIndexData[index],
  //           pageNumber: index + 1,
  //         });
  //         await setNextPage({
  //           ...dayIndexData[index + 1],
  //           pageNumber: index + 2,
  //         });
  //         await setItems(dayIndexData?.[index]);

  //         await setPropsArticles(dayIndexData?.[index]?.Articles);
  //         // await getRelatedArticles(name, index);

  //         await setDisplayArticle(displayArticle ?? "");
  //         await setIsSearch(
  //           { ...isSearch } ?? { search: "", from: "", articleName: "" }
  //         );
  //         await setShowPageThumb(false);
  //       } else {
  //         // redirect to buy subscription
  //         window.open(
  //          {`${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/plans`}
  //         );
  //       }
  //     }
  //   } else {
  //     await setCurrentPage({
  //       ...dayIndexData[index],
  //       pageNumber: index + 1,
  //     });
  //     await setNextPage({
  //       ...dayIndexData[index + 1],
  //       pageNumber: index + 2,
  //     });
  //     await setItems(dayIndexData?.[index]);

  //     await setPropsArticles(dayIndexData?.[index]?.Articles);
  //     // await getRelatedArticles(name, index);

  //     await setDisplayArticle(displayArticle ?? "");
  //     await setIsSearch(
  //       { ...isSearch } ?? { search: "", from: "", articleName: "" }
  //     );
  //     await setShowPageThumb(false);
  //   }
  // };

  const pageClick = async (name, index, displayArticle, isSearch) => {
    setLoading(true);
    await setCurrentPage({ ...dayIndexData[index], pageNumber: index + 1 });
    await setNextPage({ ...dayIndexData[index + 1], pageNumber: index + 2 });
    await setItems(dayIndexData?.[index]);

    await setPropsArticles(dayIndexData?.[index]?.Articles);
    // await getRelatedArticles(name, index);

    await setDisplayArticle(displayArticle ?? "");
    await setIsSearch(
      { ...isSearch } ?? { search: "", from: "", articleName: "" }
    );
    await setShowPageThumb(false);
  };

  const changeCurrentIndex = (index) => {
    setCurrentIndexHome(index);
  };

  useEffect(() => {
    if (
      currentPublication &&
      Object.keys(currentPublication)?.length > 0 &&
      JSCookie.get("nbtedindex")
    ) {
      let url = `/${currentPublication?.publicationname
        .split(" ")
        .join("-")
        .toLowerCase()}/${currentPublication?.editions[
        JSCookie.get("nbtedindex")
      ]?.editionname
        .split(" ")
        .join("-")
        .toLowerCase()}`;
      if (url) setContentModalURL(url);
    }
  }, [currentPublication]);
  // const initializeGAnalytics = () => {
  //   if (
  //     publicationsData.length > 0 &&
  //     JSCookie.get("nbtedindex") &&
  //     JSCookie.get("nbtpubindex")
  //   ) {
  //     let trackers = [];
  //     publicationsData.forEach((item) => {
  //       const editionTrackers = item.editions.map((edition) => {
  //         return {
  //           trackingId: edition.trackingId,
  //           gaOptions: { name: `${edition.editionid}-${edition.editionname}` },
  //         };
  //       });
  //       trackers = [...trackers, ...editionTrackers];
  //     });
  //     ReactGA.initialize(trackers);
  //   }
  // };
  // useEffect(() => {
  //   initializeGAnalytics();
  // }, []);

  useEffect(() => {
    setFilteredDate(
      dateIndexData &&
        dateIndexData.length > 0 &&
        dateIndexData.filter((v) => v.Date === JSCookie.get("nbteddate"))?.[0]
    );
  }, [dateIndexData]);

  const getLinkJson = async (pub, edn, date) => {
    let path = `${publicationJson[process.env.REACT_APP_ENV]}/PublicationData/${
      pub?.publicationcode
    }/${edn?.editionid}/${date.Date.split("-")
      .reverse()
      .join("/")}/LinkJson/${date.Date.split("-").join("_")}_${
      edn?.editionid
    }.json?v=${date?.Timestamp}`;
    await axios
      .get(path)
      .then(async (res) => {
        setLinkJsonData(res.data);
      })
      .catch((err) => {
        console.log("getLinkJson err: ", err);
      });
  };

  useEffect(() => {
    if (window.innerWidth > 700) {
      window.addEventListener("contextmenu", (event) => event.preventDefault());
    }
  }, []);
  const getNotification = () => {
    showPromise().then(
      setTimeout(() => {
        hidePromise().then(() => {});
      }, 3000)
    );
  };

  const showPromise = () => {
    return new Promise((resolve, reject) => {
      setIsMessageVisible(true);
      setTimeout(() => {
        setMessageOpacity(1);
        setMessageTop("90vh");
        resolve();
      }, 10);
    });
  };
  const hidePromise = () => {
    return new Promise((resolve, reject) => {
      setMessageOpacity(0);
      setMessageTop("110vh");
      setTimeout(() => {
        setIsMessageVisible(false);
        resolve();
      }, 500);
    });
  };
  const validateCustomer = async (ssoTicketId) => {
    let ticketId = ssoTicketId ?? getTicketId();
    const IP = await publicIp.v4();
    const IPDetails = await fetch(
      `https://pro.ip-api.com/json/${IP}?fields=country,countryCode,city,zip,regionName,query&key=2tjq7YXrdexnO8N`
    ).then(async (res) => {
      let response = await res.json();
      return response;
    });
    let customerDetailsFromLS = localStorage.getItem("customer_details");
    let obj = {
      ipAddress: IPDetails?.query,
      country: IPDetails?.country,
      city: IPDetails?.city,
      postalCode: IPDetails?.zip,
      state: IPDetails?.regionName,
      countryCode: IPDetails?.countryCode, //"IN",
      ssoTicketId: ticketId,
      tenant_id: process.env.REACT_APP_TENANT_ID,
      channel: process.env.REACT_APP_CHANNEL_SSO_NAME,
      customerDetailsFromLS,
    };
    let result = await axios
      .post(
        process.env.REACT_APP_SUBSCRIPTION_URL + "/cms/validate-customer",
        obj
      )
      .then((response) => {
        // response.data.allow_access = false; //todo
        localStorage.setItem("customer_details", JSON.stringify(response.data));
        setCustomerDetails(response.data);
        const { FT1Dudation, isNewCustomer } = response.data;
        if (FT1Dudation && isNewCustomer) {
          setFt1Duration(FT1Dudation);
        }
        const msgShown = JSON.parse(localStorage.getItem("msg_shown"));
        if (isNewCustomer && !msgShown) {
          getNotification();
          localStorage.setItem("msg_shown", true);
        }
        if (
          localStorage.getItem("customer_details") &&
          JSON.parse(localStorage.getItem("customer_details")).access_type ===
            "paid"
        ) {
          setIsPaidUser(true);
        }
        const token = response.data?.token;
        const in8hrs = 1 / 3;
        JSCookie.set("token", token, {
          secure: true,
          expires: in8hrs,
        });
        if (!response.data.allow_access && !isLandingPage) {
          // clevertap and gtm initiation
          subscriptionEvents(
            "subscription_initiated",
            "subscription_initiated",
            "subscription_initiated",
            "page_2"
          );
          window.location.assign(
            `https://${process.env.REACT_APP_CHANNEL_SSO_NAME}.harnscloud.com/plans`
          );
          return false;
        } else {
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return result;
  };

  return (
    <AppContext.Provider
      value={{
        isPopularDrawerOpen,
        setIsPopularDrawerOpen,
        isFitToHeight,
        setIsFitToHeight,
        isFitToWidth,
        setisFitToWidth,
        articles,
        setArticles,
        width,
        setWidth,
        setHeight,
        vw,
        calculateNewHeight,
        vh,
        refactorHighlight,
        isOpenArticleModal,
        toggleArticleViewModal,
        articleViewData,
        articleViewName,
        setArticleViewName,
        setArticleViewData,
        setArticleViewDataLinked,
        relatedArticleData,
        setRelatedArticleData,
        articleViewDataLinked,
        articleViewNameLinked,
        setArticleViewNameLinked,
        toggle,
        isPanoramaPage,
        setIsPanoramaPage,
        isOpen2,
        isPanoramaPage2,
        setIsPanoramaPage2,
        setIsOpen2,
        toggleShare,
        isOpenMobArticle,
        ClickAnywhereClose,
        fit2page,
        setFit2page,
        articlesPage2,
        setArticlesPage2,
        widthPage2,
        setWidthPage2,
        refactorHighlightPage2,
        print,
        isLoading,
        showLoader,
        setShowLoader,
        setIsLoading,
        multiplier,
        setMultiplier,
        isLandingPage,
        setIsLandingPage,
        mainPageLOader,
        setMainPageLoader,
        s3Source: publicationJson[process.env.REACT_APP_ENV],
        currArticleIndex,
        setCurrArticleIndex,
        publicationJson: publicationJson.Publications,
        updateInsight,
        views,
        shares,
        totalShareCount,
        setTotalShareCount,
        isRegional,
        setIsRegional,
        imageUrl,
        setImageUrl,
        activeTabName,
        setActiveTabName,
        linkJsonURL,
        setLinkJsonURL,
        hasInfoZone,
        setHasInfoZone,
        ratioMainPage,
        setRatioMainPage,
        fit2pagesArray,
        setFit2pagesArray,
        isOpenArchive,
        setIsOpenArchive,
        TTS,
        setTTS,
        state,
        setState,

        //from app.js
        loading,
        setLoading,
        publicationsData,
        setPublicationsData,
        editionData,
        setEditionsData,
        initialPublication,
        setInitialPublication,
        initialEdition,
        setInitialEdition,
        todaysDate,
        setTodaysDate,
        dateArray,
        setDateArray,
        dateIndexData,
        setDateIndexData,
        dayIndexData,
        setDayIndexData,
        sectionData,
        setSectionData,
        currentPage,
        setCurrentPage,
        nextPage,
        setNextPage,
        displayArticle,
        setDisplayArticle,
        logo,
        setLogo,
        items,
        setItems,
        isSearch,
        setIsSearch,
        showPageThumb,
        setShowPageThumb,
        currentIndexHome,
        setCurrentIndexHome,
        currentPublication,
        setCurrentPublication,
        isSecondPage,
        setIsSecondPage,
        contentModalURL,
        setContentModalURL,
        themeColor,
        setThemeColor,
        propsArticles,
        setPropsArticles,
        favIcon,
        setFavicon,
        mostViewed,
        setMostViewed,
        mostShared,
        setMostShared,
        getPublicationsData,
        handlePublication,
        handleEdition,
        handleDate,
        pageClick,
        setLogoAndTheme,
        filteredDate,
        setFilteredDate,
        linkJsonData,
        setLinkJsonData,
        articleLoading,
        setArticleLoading,
        setZoom,
        zoom,
        updateCookies,
        checkHeight,
        setCheckHeight,
        todayCurrentDate,
        isOpenContactModal,
        setIsOpenContactModal,
        isEnableAds,
        setIsEnableAds,
        isEnableAnalytics,
        setIsEnableAnalytics,
        isOpenFeedBackModal,
        setIsOpenFeedBackModal,
        validateCustomer,
        isOpenUpdate,
        setIsOpenUpdate,
        customerDetails,
        setCustomerDetails,
        hpImgBanner,
        isMessageVisible,
        setIsMessageVisible,
        messageTop,
        setMessageTop,
        messageOpacity,
        setMessageOpacity,
        ft1Duration,
        isPaidUser,
        setIsPaidUser,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
