import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { Card, CardBody, Row, Col, Dropdown } from "reactstrap";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  CardImg,
} from "reactstrap";
import JSCookie from "js-cookie";
import { AppContext } from "../contexts/AppContext";
import defaultImg from "../assets/images/default_image.png";
import moment from "moment";
import "./archive.css";
import TopHeader from "./TopHeader";
import { useHistory, Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Search, X } from "react-feather";
import publicationJson from "../config/Publications.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Select from "react-select";
import { ItemImage } from "semantic-ui-react";
import DesktopSearchBar from "./DestopSearchBar";
import { isIOS } from "react-device-detect";

function Archive(props) {
  const history = useHistory();
  const startingYear = 2021;
  const startingMonth = "December";
  // global state
  const {
    s3Source,
    getPublicationsData,
    themeColor,
    publicationsData,
    mostShared,
    pageClick,
  } = useContext(AppContext);
  const [searchModal, toggleSearch] = useState(false);
  const [dateKeyData, setDateKeyData] = useState([]);
  const [filteredDateData, setFilteredDateData] = useState([]);
  const [isOpenPub, setIsOpenPub] = React.useState(false);
  const [isOpenEdition, setIsOpenEdition] = React.useState(false);
  const [isOpenDayOfWeek, setIsOpenDayOfWeek] = React.useState(false);
  const [isRegional, setIsRegional] = React.useState(false);
  const [dayIndexData, setDayIndexData] = useState([]);
  const [initialPublication, setInitialPublication] = React.useState({
    ...publicationJson.Publications[
      JSCookie.get("nbtpubindex") ? Number(JSCookie.get("nbtpubindex")) : 0
    ],
    index: JSCookie.get("nbtpubindex")
      ? Number(JSCookie.get("nbtpubindex"))
      : 0,
  });

  const [editionData, setEditionsData] = useState(
    publicationJson.Publications[
      JSCookie.get("nbtpubindex") ? Number(JSCookie.get("nbtpubindex")) : 0
    ].editions
  );
  const [initialEdition, setInitialEdition] = React.useState({
    ...publicationJson.Publications[
      JSCookie.get("nbtpubindex") ? Number(JSCookie.get("nbtpubindex")) : 0
    ].editions[
      JSCookie.get("nbtpubindex") ? Number(JSCookie.get("nbtedindex")) : 0
    ],
    index: JSCookie.get("nbtpubindex") ? Number(JSCookie.get("nbtedindex")) : 0,
  });
  const [images, setImages] = React.useState({});
  const [editions, seteditions] = useState([]);
  const [defaultPublication, setdefaultPublication] = useState({});

  const togglePublication = () => setIsOpenPub((prevState) => !prevState);
  const toggleEdition = () => setIsOpenEdition((prevState) => !prevState);
  const toggleDaysOfWeek = () => setIsOpenDayOfWeek((prevState) => !prevState);
  const [selectedWeekDay, setSelectedWeekDay] = useState("All");
  // const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentYear, setCurrentYear] = useState(startingYear);
  const selectedYear = useRef(startingYear);
  const [thisYear, setThisYear] = useState(moment().format("YYYY"));
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [days, setDays] = useState([
    "All",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [Past2years, setPast2years] = useState([]);
  useEffect(() => {
    let Past2years = [];
    for (let i = Number(thisYear - 1); i < Number(thisYear); i++) {
      Past2years.push(i);
    }
    Past2years.push(Number(thisYear));
    setPast2years(Past2years);
  }, [thisYear]);
  const [dateIndexData, setDateIndexData] = useState([]);
  useEffect(() => {
    getDateIndex();
    // setDateIndexData()
  }, []);
  useEffect(() => {
    setImages({});
  }, [initialPublication, initialEdition, currentYear, selectedMonth]);
  const getDateIndex = (month) => {
    const endDate = new Date();

    var a =
      currentYear > startingYear
        ? moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()])
        : moment([startingYear, 11, 31]);

    var b =
      currentYear == startingYear
        ? moment([startingYear, 11, 1])
        : moment([
            currentYear,
            months.findIndex((v) => v == month ?? selectedMonth),
            1,
          ]);
    let diff = a.diff(b, "days"); // =1
    let array = [];
    array.push({
      Date: moment(
        new Date(
          currentYear == startingYear
            ? "2021-12-01"
            : `${currentYear}-${
                months.findIndex((v) => v == month ?? selectedMonth) + 1
              }-01`
        )
      ).format("DD-MM-YYYY"),
      Timestamp: new Date(
        moment(
          new Date(
            currentYear == startingYear
              ? "2021-12-01"
              : `${currentYear}-${
                  months.findIndex((v) => v == month ?? selectedMonth) + 1
                }-01`
          )
        ).format("YYYY-MM-DD")
      ).getTime(),
    });

    if (diff && diff !== "NaN") {
      for (let i = 0; i < diff; i++) {
        array.push({
          Date: moment(
            new Date(
              currentYear == startingYear
                ? "2021-12-01"
                : `${currentYear}-${
                    months.findIndex((v) => v == month ?? selectedMonth) + 1
                  }-01`
            )
          )
            .add(i + 1, "days")
            .format("DD-MM-YYYY"),
          Timestamp: new Date(
            moment(
              new Date(
                currentYear == startingYear
                  ? "2021-12-01"
                  : `${currentYear}-${
                      months.findIndex((v) => v == month ?? selectedMonth) + 1
                    }-01`
              )
            )
              .add(i + 1, "days")
              .format("YYYY-MM-DD")
          ).getTime(),
        });
      }
      setDateIndexData(array.reverse());
    }
  };
  useEffect(() => {
    setDateKeyData([]);
    setFilteredDateData([]);
    let array = [];
    if (dateIndexData && dateIndexData.length > 0) {
      for (let i = 0; i < dateIndexData.length; i++) {
        const ele = dateIndexData[i]?.Date?.split("-").reverse().join("-");
        array.push({
          date: ele,
          day: moment(new Date(ele)).format("dddd"),
          month: moment(new Date(ele)).format("MMMM"),
        });
      }
      setDateKeyData(array);
      setFilteredDateData(array);
    }
  }, [dateIndexData]);

  const dayOfTheWeekHandler = (weekDay) => {
    setSelectedWeekDay(weekDay);
    if (weekDay === "All") {
      setFilteredDateData([...dateKeyData]);
    } else {
      let filterByDay = dateKeyData.filter((v) => v.day === weekDay);
      setFilteredDateData(filterByDay);
    }
  };
  const monthHandler = (month) => {
    setSelectedMonth(month);
    let filterByMonth = dateKeyData.filter((v) => v.month === month);
    setFilteredDateData(filterByMonth);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    nextArrow: (
      <div style={{ color: "black" }}>
        <ChevronRight />
      </div>
    ),
    prevArrow: <ChevronLeft style={{ stroke: "black" }} />,
  };

  const leftHandler = () => {
    if (Past2years.filter((v) => v == currentYear - 1)?.length > 0) {
      setCurrentYear(currentYear - 1);
    }
  };
  const rightHandler = () => {
    if (currentYear + 1 <= Number(moment().format("YYYY"))) {
      setCurrentYear(currentYear + 1);
    }
  };

  useEffect(() => {
    if (currentYear == startingYear) {
      monthHandler("December");
      getDateIndex();
    }
    if (currentYear < startingYear) {
      setSelectedMonth("");
      setFilteredDateData([]);
    }
    if (currentYear > startingYear) {
      async function updateData() {
        await setSelectedMonth("January");
        await getDateIndex("January");
      }
      updateData();
    }
  }, [currentYear]);

  const [currMonthIndex, setCurrMonthIndex] = React.useState(0);
  useEffect(() => {
    let currentMonth = moment().format("MMMM");
    setCurrMonthIndex(
      months.findIndex((v) => v.toLowerCase() === currentMonth.toLowerCase())
    );
  }, []);
  useEffect(() => {
    if (initialPublication.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [initialPublication]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handlePublication = async (publication, index) => {
    setInitialPublication({ ...publication, index });
    setInitialEdition({ ...publication.editions[0], index: 0 });
  };
  const handleEdition = async (edition, index) => {
    setInitialEdition({ ...edition, index });
  };

  const todayCurrentDate = moment(new Date()).format("YYYY-MM-DD");
  const redirectURL = `/${publicationsData?.[
    JSCookie.get("nbtpubindex")
  ]?.publicationname
    ?.split(" ")
    .join("-")
    .toLowerCase()}/${publicationsData?.[JSCookie.get("nbtpubindex")]?.editions[
    JSCookie.get("nbtedindex")
  ]?.editionname
    ?.split(" ")
    .join("-")
    .toLowerCase()}`;
  return (
    <Fragment>
      <TopHeader
        close={
          <div className="pl-3 pt-2 close-archive-modal">
            <Link
              to={redirectURL}
              style={{ textDecoration: "none", color: "red" }}
            >
              <X className=" icon-bar-size-archive" />
            </Link>
          </div>
        }
      />
      <Row className="d-flex justify-content-center mt-1">
        <Col sm="12" md="12">
          {window.innerWidth < 700 && (
            <Row className="px-3">
              <Col xs="6" md="6">
                <Link to={"/navbharattimes/home"}>
                  <img
                    style={{ maxHeight: "85px" }}
                    src={`/navbharattimes-assets/logo/nbt-header-logo.png`}
                    alt="timesgroup"
                  />
                </Link>
              </Col>
              <Col xs="6" sm="6" className=" pt-2 close-archive-modal">
                <Link
                  to={redirectURL}
                  style={{ textDecoration: "none", color: "red" }}
                >
                  <X className=" icon-bar-size-archive" />
                </Link>
              </Col>
            </Row>
          )}
          {/* <Col
            className="archive-top-ad"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img
              style={{ maxWidth: "728px" }}
              width="100%"
              src="https://tpc.googlesyndication.com/simgad/329970739938340633"
              alt="hh"
            />
          </Col> */}
        </Col>
      </Row>
      <div className="archive_wrapper">
        <Row className="archive-wrapper">
          <Col xs="5" sm="5" md="3" lg="3">
            <Card>
              <CardBody>
                <Row className="justify-content-center">
                  <Col
                    xs="11"
                    md="11"
                    className="border py-1 px-0"
                    style={{ borderRadius: "2.5px", cursor: "pointer" }}
                    // onClick={() => toggleSearch(!searchModal)}
                  >
                    {/* <label className="my-0">
                      <span className=""> Search</span>
                    </label>
                    <Search size={13} /> */}

                    <DesktopSearchBar
                      {...props}
                      currentPublication={initialPublication}
                      mostShared={mostShared}
                      themeColor={themeColor}
                      dateArray={JSCookie.get("nbteddate")
                        ?.split("-")
                        ?.reverse()}
                      pageClick={pageClick}
                      defaultInitialEditionName={initialEdition?.editionid}
                      initialPublication={initialPublication}
                      dayIndexData={[]}
                      handleDate={() => {}}
                      fromArchive={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" className="py-2">
                    {/* <FormGroup className="mb-2"> */}
                    {/* <Label>Select Publication:</Label> */}
                    <label
                      className=" toi-label-span-archive"
                      // for="exampleFormControlSelect1"
                    >
                      Publication
                    </label>
                    <Dropdown isOpen={isOpenPub} toggle={togglePublication}>
                      <DropdownToggle
                        caret
                        className="chaoice-inner-archive d-flex justify-content-between align-items-center"
                      >
                        {initialPublication
                          ? initialPublication.publicationname
                          : " The Times Of India"}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "100px",
                                  fontSize: "smaller",
                                },
                              };
                            },
                          },
                        }}
                      >
                        {publicationJson?.Publications?.map(
                          (publication, index) => {
                            return (
                              <DropdownItem
                                key={publication.id}
                                className="dropdown-archive-menu"
                                value={publication?.publicationname}
                                // className="dropdown-item"
                                onClick={() => {
                                  handlePublication(publication, index);
                                  setEditionsData(publication.editions);
                                }}
                              >
                                {publication.publicationname}
                              </DropdownItem>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                {/* <FormGroup className="mb-2"> */}
                <Col xs="12" md="12" className="py-2 px-0">
                  <label className=" toi-label-span-archive">
                    Select Edition:
                  </label>
                  <Dropdown isOpen={isOpenEdition} toggle={toggleEdition}>
                    <DropdownToggle
                      caret
                      className="chaoice-inner-archive d-flex justify-content-between align-items-center"
                    >
                      {initialEdition?.editionname}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "100px",
                                fontSize: "smaller",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {editionData?.map((item, index) => {
                        return (
                          <DropdownItem
                            key={item.editionname + index}
                            // value={item.editionname}
                            className="dropdown-archive-menu"
                            onClick={() => handleEdition(item, index)}
                          >
                            {item.editionname}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                {/* </FormGroup> */}

                <Col xs="12" md="12" className="py-2 px-0">
                  <label className=" toi-label-span-archive">
                    Day of the week:
                  </label>
                  <Dropdown isOpen={isOpenDayOfWeek} toggle={toggleDaysOfWeek}>
                    <DropdownToggle
                      caret
                      className="chaoice-inner-archive d-flex justify-content-between align-items-center"
                    >
                      {selectedWeekDay}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "100px",
                                fontSize: "smaller",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {days?.map((item, index) => {
                        return (
                          <DropdownItem
                            key={item + index}
                            value={item}
                            className="dropdown-archive-menu"
                            onClick={() => dayOfTheWeekHandler(item)}
                          >
                            {item}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>

                  <ListGroup className="border-none mt-2">
                    {months &&
                      months?.map((month, index) => {
                        return (
                          <ListGroupItem
                            disabled={
                              startingYear < currentYear
                                ? index > currMonthIndex
                                : startingYear == currentYear &&
                                  month !== "December"
                            }
                            onClick={() => {
                              monthHandler(month);
                            }}
                            className={"border-0 month_item"}
                            style={{
                              color: selectedMonth === month && themeColor,
                              textDecoration:
                                selectedMonth === month && "underline",
                              cursor:
                                startingYear < currentYear
                                  ? index > currMonthIndex
                                    ? "not-allowed"
                                    : "pointer"
                                  : startingYear == currentYear
                                  ? month !== "December"
                                    ? "not-allowed"
                                    : "pointer"
                                  : currentYear < startingYear && "not-allowed",
                            }}
                          >
                            {month}
                          </ListGroupItem>
                        );
                      })}
                  </ListGroup>
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col
            xs="7"
            sm="7"
            md="9"
            lg="9"
            style={{ height: window.innerHeight - 10, overflowY: "scroll" }}
          >
            <Row className="mb-3">
              <Col md="12">
                <div className="year_list">
                  <div
                    //  onClick={leftHandler}

                    className="arrow arrow-left"
                  >
                    {/* {Past2years?.filter((v) => v == currentYear - 1)?.length >
                      0 && <ChevronLeft className="mt-2" />} */}
                  </div>
                  <div className="data-content">
                    <ul className="w-100 text-center">
                      {Past2years.map((year, index) => {
                        return (
                          <li
                            onClick={() => {
                              selectedYear.current = year;
                              setCurrentYear(year);
                            }}
                            style={{
                              color:
                                selectedYear.current === year ? "red" : "grey",
                            }}
                            key={"years" + year}
                          >
                            {/* {filteredDateData.length
                          ? filteredDateData[0].date?.slice(0, 4)
                          : currentYear} */}
                            {year}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div
                    //  onClick={rightHandler}
                    className="arrow arrow-right"
                  >
                    {/* {currentYear < Number(moment().format("YYYY")) && (
                      <ChevronRight className="mt-2" />
                    )} */}
                  </div>
                  <div className="top-shadow"></div>
                </div>
              </Col>
            </Row>
            <Card className="border-0">
              <CardBody className="p-2">
                <Row>
                  {filteredDateData &&
                    filteredDateData.length > 0 &&
                    filteredDateData.map((item, index) => {
                      let date1 = item.date.split("-").join("/");
                      console.log("date1: ", date1);
                      let date2 = item.date.split("-").reverse().join("-");
                      if (!images?.[date1])
                        return (
                          <>
                            {todayCurrentDate !== item.date && (
                              <Col
                                md="4"
                                lg="3"
                                xl="2"
                                sm="6"
                                xs="10"
                                className="mb-3 "
                                key={index + item.date}
                                onClick={async () => {
                                  if (!images?.[date1]) {
                                    await JSCookie.set(
                                      "nbtpubindex",
                                      initialPublication.index,
                                      { expires: 3 }
                                    );
                                    await JSCookie.set(
                                      "nbtedindex",
                                      initialEdition.index,
                                      { expires: 3 }
                                    );
                                    await JSCookie.set("nbteddate", date2);
                                    await JSCookie.set("fromArchive", true);
                                    await getPublicationsData();
                                    history.push(
                                      `/${initialPublication.publicationname
                                        .toLowerCase()
                                        .split(" ")
                                        .join(
                                          "-"
                                        )}/${initialEdition?.editionname
                                        .toLowerCase()
                                        .split(" ")
                                        .join("-")}`
                                    );
                                  }
                                }}
                                id={item.date}
                              >
                                <Card
                                  className=" img_card_archive"
                                  style={{
                                    cursor: images?.[date1]
                                      ? "not-allowed"
                                      : "pointer",
                                    width: "85%",
                                    maxHeight:
                                      initialPublication.publicationname ===
                                        "Mirror" && window.innerWidth < 1200
                                        ? "169px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 2000
                                        ? "330px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1800
                                        ? "240px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1600
                                        ? "220px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1200
                                        ? "160px"
                                        : "auto",
                                  }}
                                >
                                  {filteredDateData?.length > 0 && (
                                    <CardImg
                                      style={
                                        {
                                          //   // maxWidth: "199px",
                                          // maxHeight: "275px",
                                        }
                                      }
                                      onError={(e) => {
                                        e.target.src = defaultImg;
                                        setImages({ ...images, [date1]: true });
                                      }}
                                      src={`${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${date1}/TodaysPage/todaysthumbimage_${initialEdition?.editionid}.jpg`}
                                    />
                                  )}
                                  <div className="border-card-ar"></div>
                                  <div className="card_footer">
                                    <span>{item.date}</span>
                                  </div>
                                </Card>
                              </Col>
                            )}
                          </>
                        );
                    })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
export default Archive;
