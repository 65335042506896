import React, { useState, forwardRef, useContext, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import JSCookie from "js-cookie";
import RightSidebarMenu from "./RightSidebarMenu";
import { Row, Col } from "reactstrap";
import AppBar from "@material-ui/core/AppBar";
import ToggleMenu1 from "./ToggleMenu1";
import ToggleMenu3 from "./ToggleMenu3";
import ToggleMenu5 from "./ToggleMenu5";
import DesktopSearchBar from "./DestopSearchBar";
import SubmenuNav from "./SubmenuNav";
import Tooltip from "@material-ui/core/Tooltip";
import HomeIcon from "@material-ui/icons/Home";
import "../components/sidebar.css";
import RightDrawer from "./RightDrawer";
import IconButton from "@material-ui/core/IconButton";
import ToggleMenu4 from "../components/ToggleMenu4";
import FileSaver from "file-saver";
import jsonp from "jsonp";
// context
import { AppContext } from "../contexts/AppContext";
import {
  ChevronDown,
  ChevronUp,
  Home,
  List,
  Menu,
  X,
  Download,
  LogIn,
  Bell,
} from "react-feather";
import GoldFavIcon from "../assets/icons/goldfavicon.ico";
import DatesModal from "./DatesModal";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import ToggleMenuEdition from "./ToggleMenuEdition";
import ToggleMenuPubLication from "./ToggleMenuPublication";
import { Apps, HomeOutlined, Receipt } from "@material-ui/icons";
import {
  bpnSubscribe,
  displayEvents,
  downloadEvent,
  homeEvent,
  loginEvents,
  signOutEvent,
  TOCEvent,
} from "../functions/cleverTap";
const Nav = styled.div`
  background: #ffff;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
//comment
const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 1rem;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2b78a2;
`;

const SidebarNav = styled.nav`
  background-color: white;
  width: 22vw;
  overflow-y: auto;
  min-width: 230px;
  height: 88.5%;
  display: flex;
  justify-content: center;
  position: fixed;
  margin-bottom: 5vh;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 100;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 20%), 0 18px 14px 3px rgb(0 0 0 / 12%),
    0 8px 3px 1px rgb(0 0 0 / 14%);
`;

const SidebarWrap = styled.div`
  width: 100%;
  margin-top: 30px;
`;

// const grow = styled.nav`
//   flexgrow: 1;
// `;

const Sidebar = (props) => {
  const history = useHistory();
  // global state
  const {
    isPopularDrawerOpen,
    isRegional,
    setIsRegional,
    pageClick,
    handleDate,
    initialPublication,
    initialEdition,
    handlePublication,
    setLogoAndTheme,
    todaysDate,
    dateArray,
    s3Source,
    dateIndexData,
    dayIndexData,
    defaultInitialPubCode,
    publicationsData,
    editionData,
    currentPage,
    filteredDate,
    currentPublication,
    // selectedEdition,
    handleEdition,
    logo,
    themeColor,
    mostShared,
    todayCurrentDate,
    fit2page,
    isEnableAds,
    setIsOpenUpdate,
    isOpenUpdate,
  } = useContext(AppContext);
  // const [logo, setLogo] = useState();
  // const [themeColor, setThemeColor] = useState();
  const [dayIndexesData, setDayIndexesData] = useState([]);
  // useEffect(() => {
  //   setLogo(logo);
  // }, [logo]);
  // useEffect(() => {
  //   setThemeColor(themeColor);
  // }, [themeColor]);
  useEffect(() => {
    setDayIndexesData(dayIndexData);
  }, [dayIndexData]);
  let minLengthDesktop = window.innerWidth > 700 && window.innerWidth < 1025;
  const [startDate, setStartDate] = useState(new Date());
  const [toggleMenu1Open, setToggleMenu1Open] = useState(false);
  const [toggleMenuPubOpen, setToggleMenuPubOpen] = useState(false);
  const [toggleMenuEditionOpen, setToggleMenuEditionOpen] = useState(false);

  // const edition = editionData.filter(
  //   (item) => item.editionid === initialEdition
  // );
  const [editionName, setEditionName] = useState(
    editionData.filter((item) => item.editionid === initialEdition)
  );

  const [toggleMenu2Open, setToggleMenu2Open] = useState(false);
  const [toggleMenu3Open, setToggleMenu3Open] = useState(false);
  const [toggleMenu4Open, setToggleMenu4Open] = useState(false);
  const [toggleMenu5Open, setToggleMenu5Open] = useState(false);

  const toggleMenu2 = () => setToggleMenu2Open((currState2) => !currState2);
  const toggleMenu3 = () => setToggleMenu3Open((currState3) => !currState3);
  const toggleMenu4 = () => setToggleMenu4Open((currState4) => !currState4);
  const toggleMenu5 = () => setToggleMenu5Open((currState5) => !currState5);

  const handleToggleMenu1Close = () => setToggleMenu1Open(false);
  const handleToggleMenu3Close = () => setToggleMenu3Open(false);
  const handleToggleMenu4Close = () => setToggleMenu4Open(false);
  const handleToggleMenu5Close = () => setToggleMenu5Open(false);
  const handleToggleMenuPubClose = () => setToggleMenuPubOpen(false);
  const handleToggleMenuEditionClose = () => setToggleMenuEditionOpen(false);

  const [sidebarTitles, setSidebarTitles] = useState([]);

  const toggleMenu1 = () => setToggleMenu1Open((currState) => !currState);
  const toggleMenuPub = () => setToggleMenuPubOpen((currState) => !currState);
  const toggleMenuEdition = () => {
    setToggleMenuEditionOpen((currState) => !currState);
  };
  const [customerFirstName, setCostomerFirstName] = useState(" ");
  // const handleToggleMenu1Close = () => setToggleMenu1Open(false);

  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const hideSidebar = () => setSidebar(false);
  useEffect(() => {
    setSidebarTitles(
      dayIndexData &&
        dayIndexData.length > 0 &&
        dayIndexData.map((item, index) => {
          return {
            title: item.PageTitle
              ? item.PageTitle + " .."
              : `Page ${index + 1}`,
            // path: path,
            iconClosed: <ChevronDown style={{ color: "grey" }} />,
            iconOpened: <ChevronUp style={{ color: "grey" }} />,
            pageName: item?.PageName,
            subNav: item?.Articles?.map((article) => {
              return {
                title: article?.ArticleTitle,
                // path: redirectURL,
                name: article?.ArticleName,
              };
            }),
          };
        })
    );
  }, [dayIndexData]);

  useEffect(() => {
    setEditionName(
      editionData.filter((item) => item.editionid === initialEdition)
    );
  }, [initialEdition]);

  const redirectURL = `/${publicationsData?.[
    JSCookie.get("nbtpubindex")
  ]?.publicationname
    ?.split(" ")
    .join("-")
    .toLowerCase()}/${publicationsData?.[JSCookie.get("nbtpubindex")]?.editions[
    JSCookie.get("nbtedindex")
  ]?.editionname
    ?.split(" ")
    .join("-")
    .toLowerCase()}`;

  function saveMainImg() {
    FileSaver.saveAs(
      `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
        initialEdition?.editionid
      }/${dateArray?.join("/")}/Page/${currentPage?.PageName}.jpg?v=${
        filteredDate?.Timestamp
      }`,
      `${currentPage?.PageTitle}.jpg`
    );
  }

  useEffect(() => {
    if (initialPublication.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [initialPublication]);

  const extractFirstName = () => {
    if (localStorage.getItem("customer_details")) {
      let customerDetails = localStorage.getItem("customer_details");
      if (customerDetails)
        customerDetails = JSON.parse(customerDetails)?.full_name?.split(" ")[0];
      setCostomerFirstName(customerDetails);
    }
  };
  useEffect(() => {
    extractFirstName();
  }, [props]);

  const handleLogout = () => {
    window.location.reload();
    let customer_details = localStorage.getItem("customer_details");
    if (customer_details) customer_details = JSON.parse(customer_details);
    axios
      .delete(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/logout/${customer_details?.id}`
      )
      .then((res) => {
        console.log("logged out");
      });
    localStorage.clear();
    JSCookie.remove("token");
    jsonp(
      `https://jsso.indiatimes.com/sso/identity/profile/logout/external?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}`,
      null,
      function async(err, data) {
        if (err) {
          console.error(err.message);
        } else {
        }
      }
    );
  };
  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window?.location?.origin}/navbharattimes/sso.html`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };

  const pushNotifications = () => {
    try {
      //cleaverTap BPN
      window?.clevertap.notifications.push({
        titleText: "Would you like to receive Push Notifications?",
        bodyText:
          "We promise to only send you relevant content and give you updates on your transactions",
        okButtonText: "Sign me up!",
        rejectButtonText: "No thanks",
        okButtonColor: "#f23046",
        skipDialog: true,
        serviceWorkerPath: "/serviceworker.js",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AppBar
        className="mob-only "
        id="nav-mob"
        style={{
          top: localStorage.getItem("contentRec") === "true" ? "64px" : "0px",
        }}
      >
        <Nav className="" style={{ color: "#2b78a2" }}>
          <NavIcon to="#">
            <Menu
              color={themeColor}
              onClick={() => {
                TOCEvent(
                  "toc_hamburger",
                  "toc_hamburger",
                  "toc_hamburger",
                  "header"
                );
                showSidebar();
              }}
              fontSize="x-large"
            />
          </NavIcon>
          <div style={{ textDecoration: "none", color: themeColor }}>
            {localStorage?.getItem("customer_details") ? (
              <span
                id="account"
                className=" cursor-pointer hp-btn-loggedin mt-0"
                onClick={toggleMenu5}
              >
                <ToggleMenu5
                  customerFirstName={customerFirstName}
                  themeColor={themeColor}
                  open={toggleMenu5Open}
                  handleClose={handleToggleMenu5Close}
                  homepage={true}
                />
              </span>
            ) : (
              <span className="cursor-pointer hp-btn-loggedin">
                <button
                  onClick={() => {
                    loginEvents(
                      "login_initiated",
                      "login_initiated",
                      "login_initiated",
                      "topbar"
                    );
                    handleLogin();
                  }}
                  className="btn-login-tbpar mr-1"
                  style={{ color: "#d82521", backgroundColor: "white" }}
                >
                  <p style={{ fontSize: "12px" }} className="m-0">
                    {" "}
                    {"लॉग इन"}{" "}
                  </p>
                </button>
              </span>
            )}
          </div>
          <div className="pl-0 cursor-pointer">
            <DatesModal
              handleDate={handleDate}
              initialPublication={initialPublication}
              initialEdition={initialEdition}
              todaysDate={todaysDate}
              dateArray={dateArray}
              dateIndexData={dateIndexData}
              editionData={editionData}
              dayIndexData={dayIndexData}
              setLogoAndTheme={setLogoAndTheme}
              currentPublication={currentPublication}
              publicationsData={publicationsData}
              handlePublication={handlePublication}
              handleEdition={handleEdition}
              themeColor={themeColor}
            />
          </div>
          <div className="logo-center">
            <b
              className="pb-0 text-center mb-0"
              style={{
                width: "100%",
                fontSize: "12px",
                color: `${themeColor}`,
                paddingRight: "9px",
                alignSelf: "center",
              }}
            >
              {`${publicationsData?.[JSCookie.get("nbtpubindex")]?.editions[
                JSCookie.get("nbtedindex")
              ]?.editionnameHin
                ?.split(" ")
                .join("-")}`}
            </b>
          </div>

          {!isRegional && (
            <div className=" pt-1 pr-0  avatar" data-tooltip="Thinking Cat">
              <DesktopSearchBar
                {...props}
                currentPublication={currentPublication}
                mostShared={mostShared}
                themeColor={themeColor}
                dateArray={dateArray}
                pageClick={pageClick}
                defaultInitialEditionName={initialEdition?.editionid}
                initialPublication={initialPublication}
                dayIndexData={dayIndexData}
                handleDate={handleDate}
              />
            </div>
          )}
          <span className="pr-2">
            <a
              // href={`${process.env.REACT_APP_GOLD_URL}/${process.env.REACT_APP_CHANNEL_NAME}`}
              href="https://navbharatgold.timesgroup.com/"
            >
              <img width="23px" src={GoldFavIcon} alt="gold icon" />
            </a>
          </span>
        </Nav>

        <div style={{ backgroundColor: "red" }}>
          <div
            className="pl-2 pt-0 sticky-cross-toc"
            style={{
              left: sidebar ? "0" : "-100%",
              top:
                localStorage.getItem("contentRec") === "true"
                  ? "116px"
                  : "50px",
            }}
          >
            <p
              className="m-0"
              style={{
                color: themeColor,
                fontFamily: "serif",
                fontSize: "inherit",
              }}
            >
              {" "}
              {initialPublication?.publicationname?.toUpperCase()}
            </p>
            <X color={"black"} onClick={hideSidebar} />
          </div>
          <SidebarNav
            style={{
              backgroundColor: "white",
              top:
                localStorage.getItem("contentRec") === "true"
                  ? "112px"
                  : "50px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            sidebar={sidebar}
          >
            <SidebarWrap>
              {sidebarTitles?.length > 0 &&
                sidebarTitles.map((item, index) => {
                  return (
                    <SubmenuNav
                      showSidebar={showSidebar}
                      item={item}
                      key={index}
                      defaultInitialPubCode={defaultInitialPubCode}
                      defaultInitialEditionName={initialEdition?.editionid}
                      dateArray={dateArray}
                      pageClick={pageClick}
                      pageNumber={index}
                      hideSidebar={hideSidebar}
                      isRegional={isRegional}
                      themeColor={themeColor}
                    />
                  );
                })}
            </SidebarWrap>
          </SidebarNav>
        </div>
      </AppBar>
      {/* Desktop navbar */}

      <AppBar
        id="nav-desktop"
        className="desktop-only"
        style={{
          top:
            localStorage.getItem("contentRec") === "true"
              ? "100px"
              : localStorage.getItem("contentRec") === "false" &&
                window.innerWidth < 1133
              ? "0px"
              : "100px",
        }}
      >
        <Nav
          id="nav-bar"
          className=""
          style={{ color: themeColor, backgroundColor: "white" }}
        >
          <Col
            onClick={() => {
              showSidebar();
              TOCEvent(
                "toc_hamburger",
                "toc_hamburger",
                "toc_hamburger",
                "header"
              );
            }}
            className=" px-2 ie-leftbar text-center"
            style={{ maxWidth: "max-content", cursor: "pointer" }}
          >
            <Tooltip title="Table Of Contents">
              <Menu color={themeColor} fontSize="large" />
            </Tooltip>

            <br />
          </Col>
          <div id="date" className="pl-2">
            <DatesModal
              handleDate={handleDate}
              initialPublication={initialPublication}
              initialEdition={initialEdition}
              todaysDate={todaysDate}
              dateArray={dateArray}
              dateIndexData={dateIndexData}
              dayIndexData={dayIndexData}
              setLogoAndTheme={setLogoAndTheme}
              currentPublication={currentPublication}
              publicationsData={publicationsData}
              handlePublication={handlePublication}
              handleEdition={handleEdition}
              editionData={editionData}
              themeColor={themeColor}
            />
          </div>
          {!isRegional && (
            <div
              id="search-bar"
              className=" pt-1 pb-1
            pl-4"
            >
              <DesktopSearchBar
                {...props}
                currentPublication={currentPublication}
                mostShared={mostShared}
                themeColor={themeColor}
                dateArray={dateArray}
                pageClick={pageClick}
                defaultInitialEditionName={initialEdition?.editionid}
                initialPublication={initialPublication}
                dayIndexData={dayIndexData}
                handleDate={handleDate}
              />
            </div>
          )}

          {todayCurrentDate !== todaysDate && !fit2page && (
            <a to="#" style={{ textDecoration: "none", color: themeColor }}>
              <Col
                onClick={() => {
                  saveMainImg();
                }}
                className=" pl-3 pr-0 cursor-pointer text-center"
              >
                <Tooltip title="Download">
                  <Download
                    onClick={() => {
                      downloadEvent(
                        "download",
                        "download",
                        "download",
                        "header"
                      );
                    }}
                  />
                </Tooltip>
                <div className="desktop-icon-name  ">डाउनलोड</div>
              </Col>
            </a>
          )}
          <div
            // to={`/home`}
            style={{ textDecoration: "none", color: themeColor }}
          >
            <Col
              className=" pl-3 cursor-pointer"
              onClick={() => {
                homeEvent(
                  "home_button",
                  "home_button",
                  "home_button",
                  "header"
                );

                window.location.assign(
                  `${window.location.origin}/navbharattimes/home`
                );
              }}
            >
              <Tooltip title="Homepage">
                <Home />
              </Tooltip>
              <div className="desktop-icon-name  ">होम</div>
            </Col>
          </div>
          <div className="logo-center">
            <b
              className="pb-0 text-center mb-0"
              style={{
                width: "100%",
                fontSize: window.innerWidth > 700 ? "large" : "small",
                color: `${themeColor}`,
                paddingRight: "3px",
                paddingLeft: "10px",
                alignSelf: "center",
              }}
            >
              {`${publicationsData?.[JSCookie.get("nbtpubindex")]?.editions[
                JSCookie.get("nbtedindex")
              ]?.editionnameHin
                ?.split(" ")
                .join("-")}`}
            </b>
          </div>
          {minLengthDesktop ? (
            <Row className="pr-2">
              <span style={{ textDecoration: "none", color: themeColor }}>
                <Col
                  className=" pr-3 pl-1 cursor-pointer text-center"
                  onClick={toggleMenuEdition}
                >
                  <ToggleMenuEdition
                    defaultInitialEditionName={initialEdition?.editionid}
                    setLogoAndTheme={setLogoAndTheme}
                    publicationsData={publicationsData}
                    editionData={editionData}
                    handleEdition={handleEdition}
                    open={toggleMenuEditionOpen}
                    handleClose={handleToggleMenuEditionClose}
                  />

                  <div className="desktop-icon-name pt-2  ">एडिशन</div>
                </Col>
              </span>
              <span style={{ textDecoration: "none", color: themeColor }}>
                <Col
                  id="display"
                  className=" pr-3 pl-1 cursor-pointer text-center"
                  onClick={() => {
                    toggleMenu1();
                  }}
                >
                  <ToggleMenu1
                    themeColor={themeColor}
                    currentPage={currentPage}
                    dayIndexData={dayIndexData}
                    open={toggleMenu1Open}
                    setToggleMenu1Open={setToggleMenu1Open}
                    handleClose={handleToggleMenu1Close}
                    pageClick={pageClick}
                    defaultInitialEditionName={initialEdition?.editionid}
                    defaultInitialPubCode={defaultInitialPubCode}
                    dateArray={dateArray}
                  />
                  <div className="desktop-icon-name pt-2">डिस्प्ले</div>
                </Col>
              </span>
              <span style={{ textDecoration: "none", color: themeColor }}>
                <Col
                  id="options"
                  className=" pl-1 cursor-pointer text-center"
                  onClick={toggleMenu4}
                >
                  <ToggleMenu4
                    themeColor={themeColor}
                    open={toggleMenu4Open}
                    handleClose={handleToggleMenu4Close}
                    className="text-icon-color"
                  />{" "}
                  <div className="desktop-icon-name  pt-2 ">विकल्प</div>
                </Col>
              </span>

              <span
                className="pr-0"
                style={{ display: "grid", alignItems: "center" }}
              >
                <a
                  // href={`${process.env.REACT_APP_GOLD_URL}/${process.env.REACT_APP_CHANNEL_NAME}`}
                  href="https://navbharatgold.timesgroup.com/"
                >
                  <img width="30px" src={GoldFavIcon} alt="gold icon" />
                </a>
              </span>
              <span style={{ textDecoration: "none", color: themeColor }}>
                {localStorage?.getItem("customer_details") ? (
                  <Col
                    id="account"
                    className=" pr-3 pl-1 cursor-pointer text-center"
                    onClick={toggleMenu5}
                  >
                    <ToggleMenu5
                      customerFirstName={customerFirstName}
                      themeColor={themeColor}
                      open={toggleMenu5Open}
                      handleClose={handleToggleMenu5Close}
                    />
                  </Col>
                ) : (
                  <Col>
                    {" "}
                    <button
                      onClick={() => {
                        loginEvents(
                          "login_initiated",
                          "login_initiated",
                          "login_initiated",
                          "topbar"
                        );
                        handleLogin();
                      }}
                      className="btn-login-tbpar mt-2"
                      style={{
                        color: "#d82521",
                        backgroundColor: "white",
                        width: "5rem",
                      }}
                    >
                      <p className="m-0"> {"लॉग इन"} </p>
                    </button>
                  </Col>
                )}
              </span>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center mr-2">
                <span style={{ textDecoration: "none", color: themeColor }}>
                  <Col
                    id="editions"
                    className=" pr-3 pl-1 cursor-pointer text-center"
                    onClick={toggleMenuEdition}
                  >
                    <ToggleMenuEdition
                      defaultInitialEditionName={initialEdition?.editionid}
                      setLogoAndTheme={setLogoAndTheme}
                      publicationsData={publicationsData}
                      editionData={editionData}
                      handleEdition={handleEdition}
                      open={toggleMenuEditionOpen}
                      handleClose={handleToggleMenuEditionClose}
                    />
                    <div className="desktop-icon-name pt-2  ">एडिशन</div>
                  </Col>
                </span>
                <span style={{ textDecoration: "none", color: themeColor }}>
                  <Col
                    id="display"
                    className=" pr-3 pl-1 cursor-pointer text-center"
                    onClick={() => {
                      toggleMenu1();
                    }}
                  >
                    <ToggleMenu1
                      themeColor={themeColor}
                      currentPage={currentPage}
                      dayIndexData={dayIndexData}
                      open={toggleMenu1Open}
                      setToggleMenu1Open={setToggleMenu1Open}
                      handleClose={handleToggleMenu1Close}
                      pageClick={pageClick}
                      defaultInitialEditionName={initialEdition?.editionid}
                      defaultInitialPubCode={defaultInitialPubCode}
                      dateArray={dateArray}
                    />
                    <div className="desktop-icon-name pt-2">डिस्प्ले</div>
                  </Col>
                </span>

                <span style={{ textDecoration: "none", color: themeColor }}>
                  <Col
                    className=" pr-3 pl-1 cursor-pointer text-center"
                    style={{ display: "none" }}
                    onClick={toggleMenu3}
                  >
                    <ToggleMenu3
                      open={toggleMenu3Open}
                      handleClose={handleToggleMenu3Close}
                      publicationsData={publicationsData}
                    />{" "}
                    <div className="desktop-icon-name text-center pt-2 ">
                      दृश्य
                    </div>
                  </Col>
                </span>
                <span style={{ textDecoration: "none", color: themeColor }}>
                  <Col
                    id="options"
                    className=" pr-3 pl-1 cursor-pointer text-center"
                    onClick={toggleMenu4}
                  >
                    <ToggleMenu4
                      themeColor={themeColor}
                      open={toggleMenu4Open}
                      handleClose={handleToggleMenu4Close}
                      className="text-icon-color"
                    />{" "}
                    <div className="desktop-icon-name  pt-2 ">विकल्प</div>
                  </Col>
                </span>
                {window.innerWidth > 700 && (
                  <span
                    className="pr-2"
                    style={{ display: "grid", alignItems: "center" }}
                  >
                    <a
                      // href={`${process.env.REACT_APP_GOLD_URL}/${process.env.REACT_APP_CHANNEL_NAME}`}
                      href="https://navbharatgold.timesgroup.com/"
                      target="_blank"
                    >
                      <img
                        width="30px"
                        src="https://navbharattimes.indiatimes.com/navbharatgold/icons/goldfavicon.ico"
                        alt="gold icon"
                      />
                    </a>
                  </span>
                )}
                <span style={{ textDecoration: "none", color: themeColor }}>
                  {localStorage?.getItem("customer_details") ? (
                    <Col
                      id="account"
                      className=" pr-0 pl-1 cursor-pointer text-center"
                      onClick={toggleMenu5}
                    >
                      <ToggleMenu5
                        customerFirstName={customerFirstName}
                        themeColor={themeColor}
                        open={toggleMenu5Open}
                        handleClose={handleToggleMenu5Close}
                      />
                    </Col>
                  ) : (
                    <button
                      onClick={() => {
                        loginEvents(
                          "login_initiated",
                          "login_initiated",
                          "login_initiated",
                          "topbar"
                        );
                        handleLogin();
                      }}
                      className="btn-login-tbpar mt-2"
                      style={{
                        color: "#d82521",
                        backgroundColor: "white",
                        width: "5rem",
                      }}
                    >
                      <p className="m-0"> {"लॉग इन"} </p>
                    </button>
                  )}
                </span>
              </Row>
            </>
          )}
        </Nav>

        <div
          className="p-3 sticky-cross-toc"
          style={{
            left: sidebar ? "0" : "-100%",
            top:
              localStorage.getItem("contentRec") === "true"
                ? "150px"
                : window.innerWidth > 1132
                ? "150px"
                : "50px",
          }}
        >
          <p
            className="m-0"
            style={{
              color: themeColor,
              fontFamily: "serif",
              fontSize: "inherit",
            }}
          >
            {initialPublication?.publicationname?.toUpperCase()}
          </p>
          <X className="cursor-pointer" onClick={hideSidebar} />
        </div>
        <SidebarNav
          sidebar={sidebar}
          style={{
            height: "78%",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            top:
              localStorage.getItem("contentRec") === "true"
                ? "171px"
                : window.innerWidth > 1132
                ? "171px"
                : "50px",
          }}
        >
          <SidebarWrap>
            {/* <NavIcon to="#"> */}
            {/* <Tooltip title="Menu"> */}
            {/* <Menu color={themeColor} onClick={showSidebar} /> */}
            {/* </Tooltip> */}
            {/* <Row style={{ width: "-webkit-fill-available" }}> */}

            {/* </Row> */}
            {/* </NavIcon> */}
            {sidebarTitles &&
              sidebarTitles.length > 0 &&
              sidebarTitles.map((item, index) => {
                return (
                  <SubmenuNav
                    isRegional={isRegional}
                    defaultInitialPubCode={defaultInitialPubCode}
                    defaultInitialEditionName={initialEdition?.editionid}
                    dateArray={dateArray}
                    pageClick={pageClick}
                    showSidebar={showSidebar}
                    item={item}
                    key={index}
                    pageNumber={index}
                    setSidebar={setSidebar}
                    hideSidebar={hideSidebar}
                    themeColor={themeColor}
                  />
                );
              })}
          </SidebarWrap>
        </SidebarNav>
      </AppBar>
    </>
  );
};

export default Sidebar;
