exports.bpnSubscribe = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.dateArchiveEvent = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.updatesEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.TOCEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.downloadEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.homeEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.signOutEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.myAccountEvent = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.articleEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.displayEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.editionEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,
    event_origin: "epaper",

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.publicationEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.pageEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.subscriptionEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.loginEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.printEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.shareEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.zoomEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
exports.activeDaysEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
    event_origin: "epaper",
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    event_origin: "epaper",
  });
};
