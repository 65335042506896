import React, { Component } from "react";
import publicationJson from "../config/Publications.json";

export default class NotFound extends Component {
  state = {
    list: [],
  };
  componentDidMount() {
    let array = [];
    array.push("/navbharattimes/home");
    let checkForPublication = publicationJson.Publications.filter(
      (v) =>
        v.publicationname?.toLowerCase().split(" ").join("-") ===
        window.location.pathname.split("/")[1]
    );

    if (
      window.location.pathname.split("/").length == 2 &&
      checkForPublication?.length !== 0
    ) {
      this.props.history.push("/navbharattimes/home");
    }
    if (
      window.location.pathname !== "/navbharattimes/home" &&
      checkForPublication?.length === 0
    ) {
      this.props.history.push("/navbharattimes/home");
    } else {
    }
  }
  render() {
    return <div></div>;
  }
}
