import React, { useContext, useState, useEffect } from "react";
import JSCookie from "js-cookie";

import "../components/sidebar.css";
import axios from "axios";
//context
import { AppContext } from "../contexts/AppContext";
import { CardImg, Row, Col, Card } from "reactstrap";
import headerAd from "../assets/images/top_header_ad_img.png";
import { Link } from "react-router-dom";
import { Window } from "@mui/icons-material";
import { isIOS } from "react-device-detect";

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
      // console.log("pubads.getSlots(): ", pubads.getSlots());

      setInterval(() => {
        console.log("refreshing");
        googletag.pubads().refresh();
      }, 30000);
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});
export default function TopHeader(props) {
  // global state
  const { isOpenArchive, initialPublication, publicationsData, isPaidUser } =
    useContext(AppContext);
  const [innrPageBannersMob, setInnerPageBannerMob] = useState("");

  let topAdSize = window.innerWidth > 1132;

  let desktopView = window.innerWidth > 700;
  const [slotsData] = useState([
    {
      path: initialPublication?.adTopHorPath,
      width: 728,
      height: 90,
      divId: initialPublication?.adFirstTopHorId,
    },
  ]);
  // useEffect(() => {
  //   const interval = window.setInterval(() => {
  //     DFPManager.refresh();
  //   }, 30000);
  //   return () => {
  //     window.clearInterval(interval);
  //   };
  // }, [initialPublication]);

  // useEffect(() => {
  //   async function initializeGAds() {
  //     for (let i = 0; i < slotsData.length; i++) {
  //       const ele = slotsData[i];
  //       const slot = GPTAdsManager.createSlot(
  //         ele.path,
  //         ele.width,
  //         ele.height,
  //         ele.divId
  //       );
  //       await slot.display();
  //       // await slot.refresh();
  //     }
  //     await GPTAdsManager.initializeAds(false, true);

  //   }
  //   initializeGAds();
  // }, [initialPublication, initialEdition, slotsData]);

  document.onreadystatechange = function () {
    if (document.readyState == "complete") {
      // document is ready. Do your stuff here
      window?.googletag?.pubads()?.refresh();
    }
  };
  //   setTimeout(() => {
  //     window?.googletag?.pubads()?.refresh();
  //   }, 1500);
  const redirectURL = `/${publicationsData?.[
    JSCookie.get("nbtpubindex")
  ]?.publicationname
    ?.split(" ")
    .join("-")
    .toLowerCase()}/${publicationsData?.[JSCookie.get("nbtpubindex")]?.editions[
    JSCookie.get("nbtedindex")
  ]?.editionname
    ?.split(" ")
    .join("-")
    .toLowerCase()}`;

  useEffect(() => {
    const getBannerData = async () => {
      const url = `${process.env.REACT_APP_CMS_URL}/banner/epaper/${process.env.REACT_APP_CHANNEL_ID}`;
      await axios.get(url).then(async (res) => {
        res?.data?.data.map((data) => {
          data.img = `${process.env.REACT_APP_S3_IMAGE_PATH}/${process.env.REACT_APP_CHANNEL_NAME}${data.img}`;
        });
        await setInnerPageBannerMob(
          res?.data?.data.filter((v) => v.label === "Mobile Inner page")[0]
        );
      });
    };
    getBannerData();
  }, []);
  return (
    <React.Fragment>
      <div
        className="head-ax"
        style={{
          display:
            window.innerWidth < 700
              ? "none"
              : localStorage.getItem("contentRec") === "true" &&
                window.innerWidth < 1132
              ? "block"
              : window.innerWidth > 1132
              ? "block"
              : "none",
        }}
      >
        <Card
          className="border-0 bg-white "
          style={{
            height: "100px",
          }}
        >
          <Row
            className="bg-white "
            style={{
              justifyContent: topAdSize ? "" : "center",
              // position: isIOS && "fixed",
              // left: isIOS && "50",
              // top: isIOS && "0px",
            }}
          >
            <Col
              className="epaper-logo mt-1"
              md="3"
              lg="3"
              xl="3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link to={"/navbharattimes/home"}>
                <img
                  // className="desktop-screen-only"
                  style={{ maxHeight: "85px" }}
                  // height="85px"
                  src={`/navbharattimes-assets/logo/nbt-header-logo.png`}
                  alt="homepage"
                />
              </Link>
            </Col>
            {/* <Col sm="0" md="3" lg="3" xl="3"></Col> */}

            {/* <div
              id="leaderboard2"
              style={{
                width: "728px",
                height: "90px",
                border: "1px solid red",
              }}
            /> */}
            {desktopView &&
              initialPublication?.adTopHorPath &&
              localStorage.getItem("contentRec") === "true" &&
              !isPaidUser && (
                <Col
                  className="p-0 m-0"
                  sm={topAdSize ? "6" : "11"}
                  md={topAdSize ? "6" : "11"}
                  lg={topAdSize ? "6" : "11"}
                  xl={topAdSize ? "6" : "11"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <div id={initialPublication?.adFirstTopHorId} /> */}
                  <Ad
                    path={initialPublication?.adTopHorPath}
                    width={728}
                    height={90}
                    divId={initialPublication?.adFirstTopHorId}
                  />
                  {/* <img
                  height="90px"
                  width="100%"
                  style={{ maxWidth: "767px" }}
                  src={headerAd}
                  alt="hh"
                /> */}

                  {/* <DFPSlotsProvider
                  dfpNetworkId={initialPublication?.adTopHorId}
                >
                  <AdSlot sizes={[[728, 90]]} adUnit="test/refreshable" />
                </DFPSlotsProvider> */}
                </Col>
              )}
            {isOpenArchive && <div>{props?.close}</div>}
          </Row>
        </Card>
        <div
          style={{
            borderBottom: "3px solid white",
          }}
        ></div>
      </div>

      {localStorage.getItem("contentRec") === "true" && (
        <Row className="mob-ad-only-header  justify-content-center bg-white">
          <Card className="border-1 mob-header" style={{ display: "block" }}>
            {localStorage.getItem("customer_details") &&
            JSON.parse(localStorage.getItem("customer_details"))
              ?.access_type === "paid" ? (
              <a href={innrPageBannersMob?.url} target="_blank">
                <img
                  width="300px"
                  height="50px"
                  src={`${process.env.REACT_APP_IMAGE_URL}${innrPageBannersMob?.img}`}
                  alt="banner mobile"
                />
              </a>
            ) : (
              <Ad
                path={initialPublication?.adTopHorPathMob}
                width={320}
                height={50}
                divId={initialPublication?.adFirstTopHorMobId}
              />
            )}
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
}
// if (typeof window !== "undefined") {
//   DFPManager.load();
// }

const Ad = ({ path, width, height, divId }) => {
  const id = React.useMemo(() => `${divId}`, []);
  React.useEffect(() => {
    const slot = GPTAdsManager.createSlot(path, width, height, id);

    slot.display();
    // slot.refresh();
    return () => {
      slot.destroy();
    };
  }, [path, width, height]);
  return <div style={{ display: "flex", justifyContent: "center" }} id={id} />;
};
