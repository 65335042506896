import React, { useState, useContext, useEffect, useRef } from "react";
import JSCookie from "js-cookie";
import axios from "axios";
import moment from "moment";
import "./landing-page.css";
import defaultImg from "../assets/images/default_image.png";
import { Link, Redirect, useHistory } from "react-router-dom";
import publicationJson from "../config/Publications.json";
import Tooltip from "@material-ui/core/Tooltip";
import CookiesChoice from "./CookiesChoice";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import NbtMenu from "./NbtMenu";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import topBannerLp from "../assets/images/1000x200Banner1.jpeg";
import topBannerLpMob from "../assets/images/300x50Banner1.jpeg";
import { Menu, Bell, X } from "react-feather";
import ToggleMenu5 from "./ToggleMenu5";
import { pushNotifications } from "../functions/BPN";
import {
  bpnSubscribe,
  displayEvents,
  downloadEvent,
  homeEvent,
  loginEvents,
  signOutEvent,
  TOCEvent,
} from "../functions/cleverTap";
import GoldFavIcon from "../assets/icons/goldfavicon.ico";

//context
import { AppContext } from "../contexts/AppContext";
import { CardImg, Row, Col, Card, Button, ModalBody } from "reactstrap";
import styled from "styled-components";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    left: "48%",
    bottom: "31%",
    width: "fit-content",
    zIndex: 99999,
    color: "black",
    backgroundColor: "rgb(255 255 255 / 0%) !important",
  },
}));

const Nav = styled.div`
  background: #ffff;
  // height: 57px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  position: fixed;
  z-index: 999;
  width: -webkit-fill-available;
  left: 0px;
`;

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
      // googletag.pubads().addEventListener("slotOnload", function (event) {
      //   var slot = event.slot;
      //   console.log(
      //     "Creative iframe for slot",
      //     slot.getSlotElementId(),
      //     "has loaded."
      //   );
      // });
      setInterval(() => {
        googletag.pubads().refresh();
      }, 30000);
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});

export default function HomePageNbt(props) {
  const history = useHistory();
  // global state
  const {
    mainPageLOader,
    setMainPageLoader,
    getPublicationsData,
    s3Source,
    isEnableAds,
    setIsOpenUpdate,
    isOpenUpdate,
    isPaidUser,
    setIsPaidUser,
    customerDetails,
  } = useContext(AppContext);

  const classes = useStyles();
  const [toggleMenu5Open, setToggleMenu5Open] = useState(false);
  const [hpBanner, setHpBanner] = useState();
  const toggleMenu5 = () => setToggleMenu5Open((currState5) => !currState5);
  const handleToggleMenu5Close = () => setToggleMenu5Open(false);
  const [customerFirstName, setCostomerFirstName] = useState(" ");

  const extractFirstName = () => {
    if (localStorage.getItem("customer_details")) {
      let customerDetails = localStorage.getItem("customer_details");
      if (customerDetails) {
        customerDetails =
          JSON.parse(customerDetails)?.full_name?.split(" ")?.[0];
        setCostomerFirstName(customerDetails);
      }
    }
  };
  useEffect(() => {
    extractFirstName();
  }, [customerDetails]);

  const [isOpenPub, setIsOpenPub] = React.useState(false);
  const [isOpenEdition, setIsOpenEdition] = React.useState(false);
  const togglePublication = () => setIsOpenPub((prevState) => !prevState);
  const toggleEdition = () => setIsOpenEdition((prevState) => !prevState);

  const [publicationsData, setPublicationsData] = useState([]);

  const [initialPublication, setInitialPublication] = useState({});
  const [currentPublication, setCurrentPublication] = useState({});
  const [dateIndexData, setDateIndexData] = useState([]);
  const [todaysDate, setTodaysDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [displayTodaysImage, setDisplayTodaysImage] = useState(true);
  const [editionData, setEditionsData] = useState([]);
  const [logo, setLogo] = useState();
  const [imgLpLoaded, setImgLpLoaded] = useState(false);
  const [themeColor, setThemeColor] = useState();
  const [initialEdition, setInitialEdition] = useState({});
  const [dayIndexData, setDayIndexData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [favIcon, setfavIcon] = useState();
  const [todays_date, set_todays_date] = useState();
  const loading = useRef(true);

  const getData = async (pubindex, edindex) => {
    try {
      if (
        JSCookie.get("nbtpubindex") !== pubindex ||
        JSCookie.get("nbtedindex") !== edindex ||
        !currentPublication ||
        !initialEdition ||
        dateIndexData.length === 0
      ) {
        await setDisplayTodaysImage(false);
        var publication = 0,
          edition = 0;

        // key-value
        if (JSCookie.get("nbtpubindex")) {
          publication = await JSCookie.get("nbtpubindex");
        } else {
          await JSCookie.set("nbtpubindex", publication, { expires: 3 });
        }
        if (JSCookie.get("nbtedindex")) {
          edition = await JSCookie.get("nbtedindex");
        } else {
          await JSCookie.set("nbtedindex", edition, { expires: 3 });
        }
        let tempInitialPub = "";
        let tempInitialEdition = "";

        const favicon = await document.getElementById("favicon");

        tempInitialPub = publicationJson.Publications[Number(publication)];

        await setInitialPublication({ ...tempInitialPub, index: publication });
        await setCurrentPublication({ ...tempInitialPub, index: publication });

        tempInitialEdition = await publicationJson.Publications[
          Number(publication)
        ].editions[Number(edition)];
        await setInitialEdition({ ...tempInitialEdition, index: edition });

        await setPublicationsData(publicationJson.Publications); // array of publications

        await setEditionsData(
          publicationJson.Publications[Number(publication)].editions
        ); // array of editions of the await set publication
        document.title =
          publicationJson.Publications[Number(publication)]?.publicationname +
          " | e-Edition";
        await setThemeColor(
          publicationJson.Publications[Number(publication)]?.theme_color
        );
        await setLogo(tempInitialPub.logo);

        // await favicon.setAttribute("href", tempInitialPub.favicon);

        if (
          Object.keys(tempInitialPub).length > 0 &&
          Object.keys(tempInitialEdition).length > 0
        ) {
          let json = "DateIndex";
          if (process.env.REACT_APP_ENV === "PROD") {
            json = "datekey";
          }
          const result = await getDates(
            tempInitialPub,
            tempInitialEdition,
            Number(publication),
            Number(edition),
            json
          );

          let newDateIndex = [];
          if (process.env.REACT_APP_ENV !== "PROD") {
            for (let i = 0; i < result.DateIndex.length; i++) {
              const ele = result.DateIndex[i];
              newDateIndex.push({
                Date: ele,
                Timestamp: new Date().getTime(),
              });
            }
          } else {
            if (result && result.length > 0) {
              newDateIndex = result;
            }
          }

          //sort to get latest date first
          const dateIndexSorted = await newDateIndex.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );

          await set_todays_date(
            dateIndexSorted[0].Date.split("-").reverse().join("/")
          );
          await JSCookie.set("nbteddate", dateIndexSorted[0].Date);
          await setDateIndexData(dateIndexSorted);
          await setDisplayTodaysImage(true);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  //get dates with timestamp
  const getDates = async (
    tempInitialPub,
    tempInitialEdition,
    publication,
    edition,
    json
  ) => {
    let result = [];
    await setDisplayTodaysImage(false);

    const url = `${
      publicationJson[process.env.REACT_APP_ENV]
    }/PublicationData/${
      tempInitialPub.publicationcode ??
      publicationJson?.Publications?.[publication]?.publicationcode
    }/${
      tempInitialEdition.editionid ??
      publicationJson?.Publications?.[publication]?.editions[edition]?.editionid
    }/${json}.json`;

    await axios
      .get(url)
      .then(async (res) => {
        result = await res.data;
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    return result;
  };

  useEffect(async () => {
    await getData(JSCookie.get("nbtpubindex"), JSCookie.get("nbtedindex"));
  }, []);

  const handlePublication = async (publication, index) => {
    // await setCurrentPublication(publication);
    await JSCookie.set("nbtpubindex", index, { expires: 3 });
    await JSCookie.set("nbtedindex", 0, { expires: 3 });
    await getData(index, 0);
  };

  const [slotsData] = useState([
    {
      path: `/1064661/HP_Top_Horizontal`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1647945910557-0`,
    },
    {
      path: `/1064661/Epaper_NBT_Desktop/ENBT_DTP_HP_MTF_300`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1647945968086-0`,
    },
    {
      path: `/1064661/Epaper_NBT_Desktop/ENBT_DTP_HP_BTF_300`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1647945860655-0`,
    },
    {
      path: `/1064661/Epaper_NBT_Desktop/ENBT_DTP_HP_ATF_728`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1647945808198-0`,
    },
    {
      path: `/1064661/Epaper_NBT_WAP/ENBT_WAP_HP_TOP_320`,
      width: 320,
      height: 50,
      divId: `div-gpt-ad-1647946420710-0`,
    },
  ]);
  const handleEdition = async (edition, index) => {
    await JSCookie.set("nbtedindex", index, { expires: 3 });
    await getData(currentPublication?.index, index);
  };
  const handleDate = async (date) => {
    await JSCookie.set("nbteddate", date);
    await getData();
  };
  useEffect(() => {
    async function initializeGAds() {
      for (let i = 0; i < slotsData.length; i++) {
        const ele = slotsData[i];
        const slot = GPTAdsManager.createSlot(
          ele.path,
          ele.width,
          ele.height,
          ele.divId
        );
        await slot.display();
        // await slot.refresh();
      }
      GPTAdsManager.initializeAds(false, true);
      setTimeout(() => {
        if (window && window?.googletag?.pubads())
          window.googletag.pubads().refresh();
      }, 1000);
    }
    initializeGAds();
  }, []);

  useEffect(() => {
    if (dateIndexData && dateIndexData.length > 0) {
      set_todays_date(dateIndexData[0].Date.split("-").reverse().join("/"));
    }
  }, [dateIndexData]);

  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window?.location?.origin}/navbharattimes/sso.html`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };

  useEffect(() => {
    const getBannerData = async () => {
      const url = `${process.env.REACT_APP_CMS_URL}/banner/epaper/${process.env.REACT_APP_CHANNEL_ID}`;
      await axios.get(url).then(async (res) => {
        res?.data?.data.map((data) => {
          data.img = `${process.env.REACT_APP_S3_IMAGE_PATH}/${process.env.REACT_APP_CHANNEL_NAME}${data.img}`;
        });
        await setHpBanner(
          res?.data?.data.filter((v) => v.label === "Desktop Homepage")[0]
        );
      });
    };
    getBannerData();
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Navbharat Times Newspaper online | Read and Download today's hindi
          epaper | पढ़ें देश का प्रतिष्ठित अख़बार नवभारत टाइम्स
        </title>
        <link rel="canonical" href={window.href} />
        <meta
          name="description"
          content={`Read online Navbharat Times ePaper. NBT is an online Hindi newspaper where you get to read your city daily breaking news updates. Download Navbharat Hindi ePaper. पढ़ें देश की आज की सभी खबरें ऑनलाइन epaper.navbharattimes.com के ePaper सेक्शन पर`}
        />
        <meta
          name="title"
          content={
            "Navbharat Times Newspaper online | Read and Download today's hindi epaper | पढ़ें देश का प्रतिष्ठित अख़बार नवभारत टाइम्स"
          }
        />
        <meta
          name="Keywords"
          content={`Navbharat Times Epaper, News in Hindi, Online Newspaper, नवभारत टाइम्स ई-पेपर, Download hindi epaper, Newspaper in Hindi, Hindi News paper, Hindi newspapers, Newspaper online, online Hindi Newspaper, daily epaper, Latest Online News, NBT Hindi ePaper, E-Paper, breaking hindi news, latest news in hindi`}
        />
        <meta
          property="og:title"
          content={`${initialPublication?.publicationname} epaper`}
        />
        <meta
          property="og:site_name"
          content={`${initialPublication?.publicationcode} epaper`}
        />
        <meta property="og:locale" content={`hi_IN`} />
        <meta
          name="breadcrumbs"
          content={`Home>Navbharat Times ${initialEdition?.editionname} E-Paper`}
        />

        <meta content="summary_large_image" name="twitter:card" />
        <meta name="twitter:title" content={`Navbharat times E-paper`} />
        <meta
          property="twitter:description"
          content={`Navbharat Times ${initialEdition?.editionname} ePaper: Read your Latest, Daily ${initialEdition?.editionname} ePaper News on Politics, Crime, Sports in Hindi, Entertainment updates and latest breaking news of ${initialEdition?.editionname} | Navbharat Times Newspaper Online`}
        />
        <meta
          name="twitter:url"
          content={`https://nbtepaper.harnscloud.com/`}
        />
        <meta
          name="twitter:domain"
          content={`https://nbtepaper.harnscloud.com/`}
        />
        <meta name="twitter:creator" content={`navbharattimes`} />
        <meta name="twitter:site" content={`navbharattimes`} />
      </Helmet>
      {localStorage.getItem("continentCode") === "EU" &&
        (!localStorage.getItem("webAnalytics") ||
          !localStorage.getItem("contentRec")) && <CookiesChoice {...props} />}
      <div className="landing-page-main-div">
        <div>
          <div
            style={{
              position: "sticky",
              top: "0px",
              zIndex: "999",
            }}
          >
            <Card
              className="header-card-landing border-0 bg-white "
              style={{
                height: "90px",
              }}
            >
              <Row className="bg-white pb-1">
                <Col
                  className="desktop-only"
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="3"
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    className="hp-logo-nbt"
                    src="/navbharattimes-assets/logo/nbt-header-logo.png"
                    alt="Home page"
                  />
                </Col>

                <Col
                  className="top-ad-landing desktop-only"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  {localStorage.getItem("contentRec") === "true" &&
                    !isPaidUser && (
                      <div>
                        <div id="div-gpt-ad-1647945910557-0" />
                      </div>
                    )}
                </Col>

                {localStorage.getItem("customer_details") &&
                  JSON.parse(localStorage.getItem("customer_details"))
                    .access_type !== "paid" && (
                    <Col
                      className="top-ad-landing-mob"
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                    >
                      <Card className="border-0" style={{ height: "60px" }}>
                        <div>
                          <div id="div-gpt-ad-1647946420710-0" />
                        </div>
                        {/* )} */}
                      </Card>
                    </Col>
                  )}
                <Col md="3" lg="3" xl="3"></Col>
              </Row>
            </Card>
            <div className="border-landing desktop-only"></div>
          </div>

          <Card>
            <Nav //for desktop view only
              className="homepage-nav-desktop desktop-only"
              style={{ color: themeColor, backgroundColor: "white" }}
            >
              <Row className="pt-1">
                <Col sm="5" md="5" className="center-nbt-icon px-0">
                  <Row className="w-100 pl-4">
                    <span className="pr-4 pt-2 pl-2">
                      <NbtMenu />
                    </span>
                    {!localStorage.getItem("customer_details") && (
                      <Row>
                        <button
                          onClick={() => {
                            handleLogin();
                          }}
                          className="btn-login-tbpar px-2 mx-3 mt-2"
                        >
                          <p className="m-0"> {"Start Free Trial"} </p>
                        </button>

                        <a
                          href={`${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/plans`}
                        >
                          <button
                            // onClick={() => {
                            //   loginEvents(
                            //     "login_initiated",
                            //     "login_initiated",
                            //     "login_initiated",
                            //     "topbar"
                            //   );
                            //   handleLogin();
                            // }}
                            className="btn-login-tbpar px-2 mt-2"
                          >
                            <p className="m-0"> {"Subscribe"} </p>
                          </button>
                        </a>
                      </Row>
                    )}
                  </Row>
                </Col>
                <Col sm="7" md="7" className="">
                  <Row style={{ justifyContent: "end" }}>
                    <Col className="text-right pl-1 cursor-pointer">
                      <a
                        // href={`${process.env.REACT_APP_GOLD_URL}`}
                        href="https://navbharatgold.timesgroup.com/"
                        target="_blank"
                      >
                        <img
                          width="165px"
                          src="/navbharattimes-assets/logo/nbtgoldlogo.png"
                          alt="navbharat gold"
                        />
                      </a>
                    </Col>
                    {/* <Col
                      md="2"
                      lg="2"
                      className="text-center pl-1 pt-2 cursor-pointer"
                    >
                      <Bell
                        width="27px"
                        height="27px"
                        onClick={() => {
                          // pushNotifications();
                          bpnSubscribe(
                            "bpn_subscribe",
                            "bellicon_press",
                            "bpn_subscribe",
                            "bellicon_press"
                          );
                          setIsOpenUpdate(!isOpenUpdate);
                        }}
                      />
                    </Col> */}
                    <span style={{ textDecoration: "none", color: themeColor }}>
                      {localStorage?.getItem("customer_details") ? (
                        <Col
                          id="account"
                          className=" pr-3 pl-1 cursor-pointer hp-btn-loggedin mt-0"
                          onClick={toggleMenu5}
                        >
                          {/* <button
                            className="btn-login-tbpar mt-2"
                            style={{
                              border: `1px solid ${props.themeColor}`,
                              width: "auto",
                              padding: "0 5px",
                            }}
                          >
                            <p className="m-0">नमस्कार: {customerFirstName} </p>
                          </button> */}
                          <ToggleMenu5
                            customerFirstName={customerFirstName}
                            themeColor={themeColor}
                            open={toggleMenu5Open}
                            handleClose={handleToggleMenu5Close}
                            homepage={true}
                          />
                        </Col>
                      ) : (
                        <Col className=" pr-3 pl-1 cursor-pointer hp-btn-loggedin">
                          <button
                            onClick={() => {
                              loginEvents(
                                "login_initiated",
                                "login_initiated",
                                "login_initiated",
                                "topbar"
                              );
                              handleLogin();
                            }}
                            className="btn-login-tbpar mt-2"
                            style={{
                              color: "#d82521",
                              backgroundColor: "white",
                            }}
                          >
                            <p className="m-0"> {"लॉग इन"} </p>
                          </button>
                        </Col>
                      )}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Nav>
            <Nav //for mobile view only
              className="homepage-nav-mob mob-only"
              style={{
                color: themeColor,
                backgroundColor: "white",
                top:
                  localStorage.getItem("customer_details") &&
                  JSON.parse(localStorage.getItem("customer_details"))
                    .access_type === "paid"
                    ? "0px"
                    : "60px",
              }}
            >
              <Row
                className="m-auto"
                style={{
                  height: "-webkit-fill-available",
                  alignItems: "center",
                }}
              >
                <Col xs="5" sm="5">
                  <Row style={{ alignItems: "center" }}>
                    <span className="pr-4 pt-2">
                      <NbtMenu />
                    </span>
                  </Row>
                </Col>
                <Col
                  xs="3"
                  sm="3"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <img
                    width="70px"
                    src="/navbharattimes-assets/logo/nbt-header-logo.png"
                    alt="logo"
                  />
                </Col>
                {/* <span className="text-center pl-1 cursor-pointer">
                  <img
                    width="165px"
                    src="/navbharattimes/logo/nbtgoldlogo.png"
                    alt="navbharat gold"
                  />
                </span> */}
                <Col xs="4" sm="4">
                  <Row style={{ alignItems: "center", justifyContent: "end" }}>
                    {/* <Col className="text-center pl-1 cursor-pointer"> */}
                    {/* <span className="pr-3 pt-1">
                      <Bell
                        onClick={() => {
                          // pushNotifications();
                          bpnSubscribe(
                            "bpn_subscribe",
                            "bellicon_press",
                            "bpn_subscribe",
                            "bellicon_press"
                          );
                          setIsOpenUpdate(!isOpenUpdate);
                        }}
                      />
                    </span> */}
                    {/* </Col> */}
                    {/* <Col> */}
                    <span className="pr-2">
                      <a
                        // href={`${process.env.REACT_APP_GOLD_URL}/${process.env.REACT_APP_CHANNEL_NAME}`}
                        href="https://navbharatgold.timesgroup.com/"
                      >
                        <img width="23px" src={GoldFavIcon} alt="gold icon" />
                      </a>
                    </span>
                    {/* </Col> */}
                  </Row>
                </Col>
              </Row>
            </Nav>
          </Card>
          {window.innerWidth < 701 &&
            !localStorage.getItem("customer_details") && (
              <>
                <Row
                  style={{
                    width: "-webkit-fill-available",
                    justifyContent: "center",
                    marginTop: "3.6rem",
                  }}
                >
                  {!localStorage.getItem("customer_details") && (
                    <span className="py-2">
                      <button
                        onClick={() => {
                          handleLogin();
                        }}
                        className="btn-trial-tbpar px-0 mx-3 my-0"
                      >
                        <p className="m-0"> {"Start Free Trial"} </p>
                      </button>

                      <a
                        href={`${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/plans`}
                      >
                        <button
                          // onClick={() => {
                          //   loginEvents(
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "topbar"
                          //   );
                          //   handleLogin();
                          // }}
                          className="btn-trial-tbpar px-2 my-0 "
                        >
                          <p className="m-0"> {"Subscribe"} </p>
                        </button>
                      </a>
                    </span>
                  )}

                  {/* {localStorage.getItem("customer_details") &&
                  JSON.parse(localStorage.getItem("customer_details"))
                    ?.access_type === "expired" &&
                  JSON.parse(localStorage.getItem("customer_details"))
                    ?.access_type !== "paid" && (
                    <span className="py-2">
                      <a href="https://nbtgold.harnscloud.com/navbharatgold/plans">
                        <button
                          // onClick={() => {
                          //   loginEvents(
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "topbar"
                          //   );
                          //   handleLogin();
                          // }}
                          className="btn-trial-tbpar px-0 mx-3 mt-0"
                        >
                          <p className="m-0"> {"Exclusive Offer"} </p>
                        </button>
                      </a>

                      <a href="https://nbtgold.harnscloud.com/navbharatgold/plans">
                        <button
                          // onClick={() => {
                          //   loginEvents(
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "login_initiated",
                          //     "topbar"
                          //   );
                          //   handleLogin();
                          // }}
                          className="btn-trial-tbpar px-2 mt-0 "
                        >
                          <p className="m-0"> {"Subscribe"} </p>
                        </button>
                      </a>
                    </span>
                  )} */}
                </Row>
              </>
            )}
          <div
            className="landing-cotainer-scroll"
            style={{
              height:
                window.innerWidth > 700
                  ? window.innerHeight - 100
                  : window.innerHeight - 20,
              marginTop:
                localStorage.getItem("customer_details") &&
                JSON.parse(localStorage.getItem("customer_details"))
                  .access_type === "paid" &&
                "60px",
            }}
          >
            {/* ad only for screen less than 700px */}
            {/* {localStorage.getItem("contentRec") === "true" && (
              <Row className="mob-ad-only  justify-content-center">
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Card className="border-0">
                    <div>
                      <div id="div-gpt-ad-1647946420710-0" />
                    </div>
                  </Card>
                </Col>
              </Row>
            )} */}
            <Row
              className="  centre-ipad justify-content-center px-2"
              style={{ paddingTop: window.innerWidth > 700 ? "70px" : "10px" }}
            >
              <Col sm="12" md={window.innerWidth > 1280 ? "6" : "9"}>
                <Row className="px-2">
                  {publicationsData[0]?.editions?.map((item, i) => {
                    return (
                      <>
                        <Col
                          key={item.editionid + "key"}
                          xs="6"
                          sm="4"
                          md="3"
                          lg="3"
                          className="mb-3 px-1"
                          onClick={async () => {
                            await JSCookie.remove("fromArchive");
                            await getPublicationsData(true);
                            window.location.assign(
                              `${
                                window.location.origin
                              }/navbharattimes/${initialPublication?.publicationname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}/${item?.editionname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}`
                            );
                          }}
                        >
                          <img
                            onError={(e) => (e.target.src = defaultImg)}
                            onLoad={() => {
                              setImgLpLoaded(true);
                            }}
                            className="ext-image-nbt"
                            src={
                              displayTodaysImage &&
                              dateIndexData?.length > 0 &&
                              `${s3Source}/PublicationData/${
                                initialPublication?.publicationcode
                              }/${
                                item?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("nbtpubindex"))
                                ]?.editions[JSCookie.get("nbtedindex")]
                                  ?.editionid
                              }/${
                                JSCookie.get("nbteddate")
                                  ? JSCookie.get("nbteddate")
                                      ?.split("-")
                                      ?.reverse()
                                      .join("/")
                                  : todays_date
                              }/TodaysPage/todaysthumbimage_${
                                item?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("nbtpubindex"))
                                ]?.editions[JSCookie.get("nbtedindex")]
                                  ?.editionid
                              }.jpg?v=${dateIndexData[0]?.Timestamp}`
                            }
                            alt={
                              "image" + dateIndexData?.length > 0
                                ? dateIndexData[0].Date.split("-")
                                    .reverse()
                                    .join("/")
                                : todays_date
                            }
                            style={{ cursor: "pointer" }}
                          />{" "}
                          {imgLpLoaded && (
                            <div
                              className="page-font-bottombar text-white text-center"
                              style={{
                                borderRadius: " 0px 0px 5px 5px ",
                                backgroundColor: "red",
                                padding: " 0px 0px",
                                width: "100%",
                                maxWidth: "220px",
                              }}
                            >
                              {item?.editionnameHin}
                            </div>
                          )}
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>

              <Col
                className=" not-ipad mt-2"
                md="3"
                lg="3"
                xl="3"
                style={{ position: "inherit" }}
              >
                {localStorage.getItem("contentRec") === "true" &&
                  !isPaidUser && (
                    <div className=" landing-ad desktop-only">
                      <div id="div-gpt-ad-1647945968086-0" />
                    </div>
                  )}
                <a href={hpBanner?.url} target="_blank">
                  <img
                    onError={(e) => (e.target.style.display = "none")}
                    width="300px"
                    height="250px"
                    src={`${process.env.REACT_APP_IMAGE_URL}${hpBanner?.img}`}
                    alt="homepage banner"
                  />
                </a>
                {/* )} */}
                {localStorage.getItem("contentRec") === "true" &&
                  !isPaidUser && (
                    <div className="pt-1 landing-ad desktop-only ">
                      <div
                        // path={`/1064661/HP_2nd_Right`}
                        // width={300}
                        // height={250}
                        id="div-gpt-ad-1647945860655-0"
                      />
                    </div>
                  )}
              </Col>
              {/* </Row> */}
            </Row>

            <footer className="footer">
              <div className="toi-links">
                <a
                  href="https://www.navbharattimes.indiatimes.com/navbharatgold/?_ga=2.65447790.1142465637.1647246677-205168764.1645616766"
                  target="_blank"
                >
                  Navbharat Gold
                </a>
                <span className="toi-sep px-1"></span>
                <a href="mailto:help@navbharatgold.com" target="_blank">
                  Helpdesk
                </a>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/navbharattimes/terms"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Terms of Use
                </Link>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/navbharattimes/privacy"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Privacy and Cookie Policy
                </Link>
              </div>
              <div className="toi-copyright">
                Copyright © 2020 Bennett Coleman &amp; Co. Ltd. • All rights
                reserved.
              </div>
            </footer>
            {localStorage.getItem("contentRec") === "true" && !isPaidUser && (
              <Row className="justify-content-center landing-bottom-ad ">
                <div className=" desktop-only ">
                  <div
                    // path={`/1064661/HP_Bottom_Horizontal`}
                    // width={728}
                    // height={90}
                    id="div-gpt-ad-1647945808198-0"
                  />
                </div>
              </Row>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenUpdate}
        size="sm"
        // className="mymodal-mob "
        overlayClassName={"myoverlay-update"}
        centered
        // toggle={function noRefCheck(){}}
      >
        {/* <ModalHeader>Modal title</ModalHeader> */}
        <ModalBody className="update-modal-card">
          <Col className="pr-1" style={{ textAlign: "end" }}>
            <X
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              onClick={() => {
                setIsOpenUpdate(false);
                bpnSubscribe(
                  "bpn_subscribe",
                  "bottombanner_cross",
                  "bottombanner_cross",
                  "bottombanner_cross"
                );
              }}
            />
          </Col>
          <Row className="">
            <Col sm="3" className="mt-2" style={{ textAlign: "center" }}>
              <span>
                <img
                  src="/navbharattimes-assets/logo/nbt-header-logo.png"
                  width="auto"
                  height="36px"
                  alt="logo"
                />
              </span>
            </Col>

            <Col sm="9">
              <div className="content">
                <p>
                  Never miss an update, subscribe to our notifications now for
                  exclusive podcasts/stories.
                </p>
              </div>
              <Row
                style={{
                  justifyContent: window.innerWidth > 700 ? "start" : "center",
                }}
              >
                <Button
                  className=" btn-update-not mr-2"
                  onClick={() => {
                    bpnSubscribe(
                      "bpn_subscribe",
                      "bottombanner_notnow",
                      "bpn_subscribe",
                      "bottombanner_notnow"
                    );
                    setIsOpenUpdate(false);
                  }}
                >
                  Not Now
                </Button>
                <Button
                  className="btn-update-subs"
                  onClick={() => {
                    bpnSubscribe(
                      "bpn_subscribe",
                      "bottombanner_subscribe",
                      "bpn_subscribe",
                      "bottombanner_subscribe"
                    );
                    pushNotifications();
                    setIsOpenUpdate(false);
                  }}
                >
                  Subscribe
                </Button>
              </Row>
            </Col>
            {/* <i
            className="fa fa-times-circle d-flex flex-end"
            onClick={closeNotification}
          /> */}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
