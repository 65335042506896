import React, { useContext } from "react";
import clsx from "clsx";
import { Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import publication_img from "../assets/icons/pubiconmob.png";
import GoldFavIcon from "../assets/icons/goldfavicon.ico";
// import { PublicationEditionData } from "./PublicationEditionData";
import SubMenu from "./SubMenu";
import { AppContext } from "../contexts/AppContext";

const useStyles = makeStyles({
  list: {
    width: 230,
    backgroundColor: "white",
  },
  fullList: {
    width: "auto",
    backgroundColor: "white",
  },
});

export default function PublicationEdition(props) {
  // global state
  const { state, setState } = useContext(AppContext);
  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Row className="pt-3 pb-1 justify-content-center">
        <b
          style={{
            color: props.themeColor,
            fontSize: "13px",
            padding: "0px 30px",
          }}
        >
          पब्लिकेशन और एडिशन
        </b>
        <img width="23px" src={GoldFavIcon} alt="gold icon" />
      </Row>
      <List>
        {props?.publicationsData?.map((item, index) => {
          return (
            <SubMenu
              setLogoAndTheme={props.setLogoAndTheme}
              editionData={props.editionData}
              handlePublication={props.handlePublication}
              handleEdition={props.handleEdition}
              item={item}
              key={index}
              index={index}
              togglemodal={props.toggleModal}
              setIsOpenMobThumb={props.setIsOpenMobThumb}
              isOpenMobThumb={props.isOpenMobThumb}
              toggleDrawer={toggleDrawer}
              anchor={anchor}
              false={false}
            />
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <FileCopyOutlinedIcon  /> */}
          {
            <img
              src={publication_img}
              onClick={toggleDrawer(anchor, true)}
              height="24px"
            />
          }

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
