import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import "./content-modal.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import JSCookie from "js-cookie";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Collapse,
  ModalHeader,
  Container,
  Spinner,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";

import OutsideClickHandler from "react-outside-click-handler";
import { X, ZoomIn, ZoomOut } from "react-feather";
import axios from "axios";
import {
  Bookmark,
  Copy,
  FileText,
  Image,
  MinusCircle,
  PlusCircle,
  Printer,
  Share2,
  Volume2,
} from "react-feather";
import Modal from "react-modal";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ShareIcon from "@material-ui/icons/Share";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import ad1 from "./Image From ET__UI_1.png";
import ad2 from "./Image From ET__UI_2.png";
import PrintIcon from "@material-ui/icons/Print";
import article_ad from "../assets/images/article_ad.png";
import "../Fonts/LibreBaskerville-Regular.ttf";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { XCircle } from "react-feather";
//
import { AppContext } from "../contexts/AppContext";

Modal.setAppElement("#root");

export default function ArticleView(props) {
  const [currentPublication, setCurrentPublication] = useState({});
  const [loading, setLoading] = useState(true);

  // global state
  const {
    articles,
    toggleArticleViewModal,
    // articleViewData,
    articleViewName,
    toggle,
    isOpen2,
    print,
    setIsOpen2,
    fit2page,
    s3Source,
    currArticleIndex,
    publicationJson,
    views,
    isRegional,
    setIsRegional,
    imageUrl,
    setImageUrl,
    activeTabName,
    setArticleViewDataLinked,
    articleViewDataLinked,
    articleViewNameLinked,
    setArticleViewNameLinked,
    linkJsonURL,
    hasInfoZone,
    setHasInfoZone,
    filteredDate,
  } = useContext(AppContext);
  const [articleViewData, setarticleViewData] = useState();
  const [articleImageURL, setarticleImageURL] = useState();
  useEffect(() => {
    let currentPub = publicationJson[JSCookie.get("nbtpubindex")];
    if (currentPub && Object.keys(currentPub).length > 0) {
      // if (currentPublication.type === "text") {
      //   setArticleTextView(true);
      // } else {
      //   setArticleTextView(false);
      // }
      setCurrentPublication(currentPub);
    }
  }, []);
  const advertisement = useRef("");
  useEffect(() => {
    let ad = props?.advertisement?.ZoneId?.split("_");
    if (ad && ad.length > 0) {
      ad.pop();
      advertisement.current = ad.join("_");
    } else {
      advertisement.current = "";
    }
  }, [props.advertisement]);
  const articleAuthorEmail = useRef(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (articleViewData?.ArticleAuthor) {
      if (
        articleViewData?.ArticleAuthor?.split("<br>")
          ?.join(" ")
          ?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
      ) {
        articleAuthorEmail.current = true;
      } else {
        articleAuthorEmail.current = false;
      }
    }
  }, [articleViewData]);

  useEffect(() => {
    if (hasInfoZone) {
      setArticleTextView(false);
    }
  }, [hasInfoZone]);

  let desktopView = window.innerWidth > 700;
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [articleTextView, setArticleTextView] = useState(true);
  const toggleShare = () => setIsOpenMobArticle(!isOpenMobArticle);
  const [articleBlurbFontsize, setArticleBlurbFontSize] = useState(13);
  const [articleBodyFontsize, setArticleBodyFontSize] = useState(17);
  const [articleHeadingFontsize, setArticleHeadingFontSize] = useState(43);
  const [articleHeadingMobFontsize, setArticleheadingMobFontSize] =
    useState(25);
  const [imgWidth, setImgWidth] = useState(80);
  const [imageDimension, setImageDimension] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });
  const imageSizeRef = useRef(1);
  const imageViewRef = useRef(1);

  const [imageSizes, setImageSizes] = useState({
    width: 1,
    height: 1,
  });
  const [textData, setTextData] = useState([""]);
  const pageNumber = useMemo(() => {
    let currentPageNumber = fit2page
      ? props?.twoPageImage1Name?.slice(11, 14)
      : props?.currentPage?.PageName?.slice(11, 14);
    if (props?.isSecondPageFit2page && fit2page) {
      currentPageNumber = props?.twoPageImage2Name?.slice(11, 14);
    }
    return currentPageNumber;
  }, [
    props.isSecondPageFit2page,
    props.twoPageImage1Name,
    props.twoPageImage2Name,
    props.currentPage,
    fit2page,
  ]);
  const increaseFontSize = () => {
    setArticleBlurbFontSize(articleBlurbFontsize + 2);
    setArticleBodyFontSize(articleBodyFontsize + 2);
    setArticleHeadingFontSize(articleHeadingFontsize + 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize + 2);
  };

  const decreaseFontSize = () => {
    setArticleBlurbFontSize(articleBlurbFontsize - 2);
    setArticleBodyFontSize(articleBodyFontsize - 2);
    setArticleHeadingFontSize(articleHeadingFontsize - 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize - 2);
  };

  useEffect(() => {
    setImgWidth(80);
  }, []);
  const zoomImageSize = (zoomBy) => {
    setImgWidth((currState) => {
      const newWidth = currState + zoomBy;
      return newWidth;
    });
  };
  const toggleView = () => {
    setArticleTextView(!articleTextView);
  };

  const parsedBody = useRef(articleViewData?.ArticleBody);
  const parsedLinkedBody = useRef(articleViewDataLinked?.ArticleBody);
  useEffect(() => {
    if (
      articleViewData &&
      articleViewData.ArticleBody &&
      articleViewData.ArticleBody.length > 0
    ) {
      const htmlparser = (str) => {
        var input_str; //store input
        var text_input; //store input after beging trim()med
        var output_html = ""; //store output
        var counter;
        input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str
        //remove duplicate location
        if (articleViewData?.ArticleLocation) {
          let regex = new RegExp("^" + articleViewData?.ArticleLocation, "i");
          if (input_str.match(regex)) {
            let val = input_str.match(regex);
            let len = val.index + articleViewData?.ArticleLocation.length;
            input_str = input_str.slice(len);
          }
        }

        text_input = input_str.trim(); //trim() input
        if (text_input.length > 0) {
          output_html += `<p>`; //begin by creating paragraph
          let foundFullStop = false;
          let sentences = [];
          for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
              case "\n":
                if (text_input[counter + 1] === "\n") {
                  output_html += "</p>\n<p>";
                  counter++;
                } else {
                  output_html += "<br/>";
                }
                break;
              case ".":
                if (sentences.length === 0) {
                  foundFullStop = true;
                  output_html += ".<br/>";
                } else {
                  output_html += ". ";
                }
                break;
              case " ":
                if (
                  text_input[counter - 1] !== " " &&
                  text_input[counter - 1] !== "\t"
                )
                  output_html += " ";
                break;

              case "\t":
                if (text_input[counter - 1] !== "\t") output_html += " ";
                break;

              case "":
                output_html += "›";
                break;
              case "`":
                output_html += "₹";
                break;

              default:
                output_html += text_input[counter];
            }
            if (foundFullStop) {
              if (sentences.length === 0) {
                sentences.push(output_html.split("<br/>"));
                output_html += `<div style='float:left; margin-right:20px;'><div style='width:300px;height:250px;border:1px solid #333; margin'><img style="width:300px" src="https://tpc.googlesyndication.com/daca_images/simgad/5899703141009266033" /></div></div>`;
              }
            }
          }

          output_html += "</p>"; //finally close paragraph
          output_html = output_html.split("br_tag").join("<br/>");
        }
        if (props.isSearch?.search) {
          output_html = output_html
            .split(props.isSearch?.search.toLowerCase())
            .join(
              `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
            );

          let text = `<span style='line-height:${
            Number(articleBodyFontsize) + 10
          }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
            .charAt(0)
            .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
          output_html = output_html
            .split(
              props.isSearch?.search.charAt(0).toUpperCase() +
                props.isSearch?.search.slice(1)
            )
            .join(text);
        }

        return output_html;
      };

      parsedBody.current = htmlparser(articleViewData?.ArticleBody);
      findLink(articleViewData);
      // htmlparser2(articleViewData?.ArticleBody);
      // } else {
      //   parsedBody.current = articleViewData?.ArticleBody;
      // }
    } else {
      parsedBody.current = "<p></p>";
    }
    if (props.isSearch?.from === "photographs") setArticleTextView(false);
  }, [props.isSearch, articleViewData]);

  //   useEffect(() => {
  //     if (
  //       articleViewDataLinked &&
  //       articleViewDataLinked.ArticleBody &&
  //       articleViewDataLinked.ArticleBody.length > 0
  //     ) {
  //       const htmlparser = (str) => {
  //         var input_str; //store input
  //         var text_input; //store input after beging trim()med
  //         var output_html = ""; //store output
  //         var counter;
  //         // input_str = str ? str?.split("<br>").join(" ") : ""; //get input and store it in input_str
  //         input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str
  //         text_input = input_str.trim(); //trim() input
  //         if (text_input.length > 0) {
  //           output_html += `<p>`; //begin by creating paragraph

  //           for (counter = 0; counter < text_input.length; counter++) {
  //             switch (text_input[counter]) {
  //               case "\n":
  //                 if (text_input[counter + 1] === "\n") {
  //                   output_html += "</p>\n<p>";
  //                   counter++;
  //                 } else {
  //                   output_html += "<br/>";
  //                 }
  //                 break;

  //               case " ":
  //                 if (
  //                   text_input[counter - 1] !== " " &&
  //                   text_input[counter - 1] !== "\t"
  //                 )
  //                   output_html += " ";
  //                 break;

  //               case "\t":
  //                 if (text_input[counter - 1] !== "\t") output_html += " ";
  //                 break;

  //               case "`":
  //                 output_html += "₹";
  //                 break;

  //               case "":
  //                 output_html += "›";
  //                 break;
  //               default:
  //                 output_html += text_input[counter];
  //             }
  //           }

  //           output_html += "</p>"; //finally close paragraph
  //           output_html = output_html.split("br_tag").join("<br/>");
  //         }
  //         if (props.isSearch?.search) {
  //           output_html = output_html
  //             .split(props.isSearch?.search.toLowerCase())
  //             .join(
  //               `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
  //             );
  //           let text = `<span style='line-height:${
  //             Number(articleBodyFontsize) + 10
  //           }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
  //             .charAt(0)
  //             .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
  //           output_html = output_html
  //             .split(
  //               props.isSearch?.search.charAt(0).toUpperCase() +
  //                 props.isSearch?.search.slice(1)
  //             )
  //             .join(text);
  //         }
  //         return output_html;
  //       };
  //       // if (props.isSearch?.search) {

  //       parsedLinkedBody.current = htmlparser(articleViewDataLinked?.ArticleBody);
  //     }
  //   }, [articleViewDataLinked]);
  const getOpacity = (article, searchQuery) => {
    if (article.BlockType === "PAGE") return 0;
    const searchWords = searchQuery?.toLowerCase()?.split(" ");
    if (article.Text) {
      if (searchWords?.includes(article?.Text?.toLowerCase())) return 0.7;
      else return 0;
    }
  };
  //   useEffect(() => {
  //     if (imageUrl && activeTabName === "Photographs") {
  //       axios.get(imageUrl).then((response) => {
  //         setTextData(response.data);
  //         const fullImageDimension = response.data[0].Geometry.BoundingBox;
  //         setImageDimension({
  //           height: (fullImageDimension.Height * imageSizes.height) / 2,

  //           width: (fullImageDimension.Width * imageSizes.width) / 2,
  //           top: (fullImageDimension.Top * imageSizes.height) / 2,
  //           left: (fullImageDimension.Left * imageSizes.width) / 2,
  //         });
  //       });
  //     }
  //   }, [imageUrl, imageSizes, activeTabName]);

  useEffect(() => {
    axios
      .get(
        "https://andre-toi-uat.s3.ap-south-1.amazonaws.com/PublicationData/TOI/toiac/2021/12/06/ArticleJson/001/06_12_2021_001_016_toiac.json"
      )
      .then((res) => {
        console.log("article-view", res.data.ArticlePhotographs[0].Photograph);
        setarticleViewData(res.data);
      });
    setarticleImageURL(
      `https://andre-toi-uat.s3.ap-south-1.amazonaws.com/PublicationData/TOI/toiac/2021/12/06/Photographs/001/${
        articleViewData && articleViewData?.ArticlePhotographs[0].Photograph
      }_toiac.jpg?v=${filteredDate?.Timestamp}`
    );
  }, []);
  useEffect(() => {
    setarticleImageURL(
      `https://andre-toi-uat.s3.ap-south-1.amazonaws.com/PublicationData/TOI/toiac/2021/12/06/Photographs/001/${
        articleViewData && articleViewData?.ArticlePhotographs[0].Photograph
      }_toiac.jpg?v=${filteredDate?.Timestamp}`
    );
  }, [articleViewData]);

  //   useEffect(() => {
  //     if (props?.defaultInitialPubCode.type === "image") {
  //       return setIsRegional(true);
  //     } else {
  //       return setIsRegional(false);
  //     }
  //   }, [props?.defaultInitialPubCode]);

  // useEffect(() => {
  let toReplace = [];
  const searchLink = async (path, zone) => {
    let response = [];
    await axios.get(path).then((res) => {
      if (res?.data?.linkJson?.length > 0) {
        response = res?.data?.linkJson.filter(
          (v) => v.FromZoneID === zone.ZoneId
        );
      }
    });
    return response;
  };
  const findLink = async (data) => {
    let parsed = parsedBody.current;
    let adblock = `<div style='float:left; margin-right:20px;'><div style='width:300px;height:250px;border:1px solid #333; margin'><img style="width:300px" src="https://tpc.googlesyndication.com/daca_images/simgad/5899703141009266033" /></div></div>`;
    let split_by_ad = parsed.split(adblock);
    let updated = [];
    let linked_zones = [];
    if (data && Object.keys(data).length > 0) {
      const zones = articles?.PageContent?.filter(
        (v) => v.ArticleName === data.ArticleName
      );
      const linkToZones = zones?.[0]?.Zones?.filter(
        (v) => v.TagName === "LinkTo"
      );

      if (linkToZones?.length > 0) {
        for (let i = 0; i < linkToZones.length; i++) {
          const ele = linkToZones[i];
          let filtered_response = await searchLink(linkJsonURL, ele);

          if (filtered_response && filtered_response.length > 0) {
            linked_zones.push({
              zoneId: ele.ZoneId,
              pageLink: filtered_response[0].PageLink,
            });
          }
          filtered_response[0].ZoneText = filtered_response[0].ZoneText.split(
            ""
          )
            .join("›")
            .split(".")
            .join("");
          let cases = [
            filtered_response[0].ZoneText.trim(),
            `&gt; &gt; ${filtered_response[0].ZoneText.split("›")
              .join("")
              ?.trim()}`,
            `&gt;&gt; ${filtered_response[0].ZoneText.split("›")
              .join("")
              ?.trim()}`,
            `›› ${filtered_response[0].ZoneText?.trim()}`,
          ];
          for (let s = 0; s < split_by_ad.length; s++) {
            let e = split_by_ad[s];

            // let checkIfExists = cases.filter((v) => new RegExp(v).test(e));
            // if (checkIfExists?.length > 0)
            for (let i = 0; i < cases.length; i++) {
              const elem = new RegExp(cases[i]);
              let str = `<a id='${ele.ZoneId}' class='${filtered_response[0].PageLink}' style='cursor:pointer;color:#0000ee;text-decoration:underline;font-weight:bold;font-family:LibreBaskerville fff ff4 !important;'>${filtered_response[0].ZoneText}</a>`;
              let found = elem.test(e);
              if (found) {
                let word = String(elem).split("/").join("");
                split_by_ad[s] = e.split(word).join(str);
              }
            }
          }
        }
      }
      parsedBody.current = split_by_ad.join(adblock);

      setTimeout(() => {
        const myFunc = (e) => {
          let jumpTo = props.dayIndexData.findIndex(
            (v) => v.PageName === e.target.className
          );
          let link = `${s3Source}/PublicationData/${props?.defaultInitialPubCode?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/PageThumb/${e.target?.className}.jpg?v=${filteredDate?.Timestamp}`;
          if (jumpTo !== -1) props.pageClick(link, jumpTo);
          props.toggleArticleViewModal();
        };
        for (let i = 0; i < linked_zones.length; i++) {
          const element = linked_zones[i];
          let domElement = document.getElementById(`${element.zoneId}`);
          if (domElement) {
            domElement.addEventListener("click", myFunc);
          }
        }
      }, 800);
    }
    // }
  };
  return (
    <React.Fragment>
      <Container fluid="true" className="fff ff4">
        {desktopView ? (
          //   <OutsideClickHandler
          //     onOutsideClick={() => {
          //       props?.toggleArticleViewModal();
          //     }}
          //   >
          //   </OutsideClickHandler>
          <div className="fff ff4">
            <Row className="" id="article-scroll-container">
              <Col md="12">
                <Row className="px-3 justify-content-start">
                  <Col xs="12" sm="12" md="3" lg="3" xl="3"></Col>
                  <Col
                    // xs="12"
                    // sm="12"
                    // md="7"
                    // lg="7"
                    // xl="7"
                    className="article-view-container mr-3"
                  >
                    <Card className="border-0 ">
                      {loading ? (
                        <Spinner className="loaderArticleCard" />
                      ) : articleTextView && !isRegional ? (
                        <div
                          className="article-view-desktop"
                          id="article-view-card"
                        >
                          {articleViewData?.ArticleBlurb && (
                            <div
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                width: "fit-content",
                              }}
                            >
                              <h6
                                style={{
                                  fontSize: `${articleBlurbFontsize}px`,
                                }}
                                id="article-card-blurb"
                                className="p-1"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData.ArticleBlurb?.split(
                                      "`"
                                    ).join("₹"),
                                }}
                              ></h6>
                            </div>
                          )}
                          <div>
                            <h1
                              id="article-card-title"
                              style={{
                                fontWeight: "700",
                                fontSize: `${articleHeadingFontsize}px`,
                                fontFamily: "fff ff4 !important",
                                lineHeight: `${articleHeadingFontsize + 18}px`,
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData.ArticleTitle.split("`").join(
                                    "₹"
                                  ),
                              }}
                            ></h1>
                            {articleViewData?.ArticleSubTitle && (
                              <h6
                                style={{
                                  fontSize: `${
                                    Number(articleBlurbFontsize) + 10
                                  }px`,
                                  fontWeight: "normal",
                                  fontStyle: "italic",
                                  color: "#4a4949",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleSubTitle.split(
                                      "`"
                                    ).join("₹"),
                                }}
                              ></h6>
                            )}
                            {articleViewData && (
                              <hr style={{ borderTop: "2px solid black" }} />
                            )}
                            {articleViewData?.ArticleAuthor && (
                              <b
                                style={{
                                  fontSize: `${articleBlurbFontsize}px`,
                                  fontStyle: "italic",
                                  color: articleAuthorEmail.current
                                    ? "#0000ee"
                                    : "#000",
                                  textDecoration: articleAuthorEmail.current
                                    ? "underline"
                                    : "none",
                                  fontWeight: articleAuthorEmail.current
                                    ? "bold"
                                    : "normal",
                                  fontFamily:
                                    "LibreBaskerville fff ff4 !important;",
                                  cursor: articleAuthorEmail.current
                                    ? "pointer"
                                    : "auto",
                                }}
                                onClick={() => {
                                  if (articleAuthorEmail.current) {
                                    window.open(
                                      "mailto:" +
                                        articleViewData?.ArticleAuthor?.split(
                                          "<br>"
                                        )?.join(" "),
                                      "mail"
                                    );
                                  }
                                }}
                                id="article-card-author"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleAuthor ===
                                      " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                      ? " TIMES NEWS NETWORK"
                                      : articleViewData?.ArticleAuthor?.split(
                                          "<br>"
                                        )?.join(" "),
                                }}
                              ></b>
                            )}
                            {articleViewData?.ArticleEmail && (
                              <a
                                href={"mailto:" + articleViewData?.ArticleEmail}
                              >
                                <i
                                  style={{
                                    fontSize: `${
                                      Number(articleBodyFontsize) - 4
                                    }px`,
                                    color: "#0000ee",
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                    fontFamily:
                                      "LibreBaskerville fff ff4 !important;",
                                  }}
                                  id="article-card-mail"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData.ArticleEmail,
                                  }}
                                ></i>
                              </a>
                            )}
                            {articleViewData && articleViewData.ArticleSource && (
                              <b
                                style={{
                                  fontSize: `${articleBodyFontsize}px`,
                                  color: "#4a4949",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData.ArticleSource ===
                                      " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                      ? " TIMES NEWS NETWORK"
                                      : articleViewData?.ArticleAuthor?.split(
                                          "<br>"
                                        )?.join(" "),
                                }}
                              ></b>
                            )}
                            {articleViewData &&
                              articleViewData.ArticleLocation && (
                                <p>
                                  <br />
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                      fontWeight: "bold",
                                      color: "#4a4949",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData.ArticleLocation,
                                    }}
                                  ></b>
                                </p>
                              )}

                            {/* <div className="ad-wrapper">
                              <CardImg src={article_ad} />
                            </div> */}
                            <p
                              style={{
                                fontSize: `${articleBodyFontsize + 1}px `,
                                lineHeight: `${
                                  Number(articleBodyFontsize) + 15
                                }px`,
                                color: "#4a4949",
                              }}
                              id="articlecardpara"
                              dangerouslySetInnerHTML={{
                                __html: articleViewData && parsedBody.current,
                              }}
                              className="mt-2"
                            >
                              {/* November 7, 2020 */}
                              {/* <br /> */}
                            </p>

                            {/* linked articleViewData */}
                            {articleViewDataLinked &&
                              Object.keys(articleViewDataLinked).length > 0 && (
                                <div>
                                  <h1
                                    id="article-card-title"
                                    style={{
                                      fontWeight: "700",
                                      fontSize: `${
                                        articleHeadingFontsize - 15
                                      }px`,
                                      fontFamily: "fff ff4 !important",
                                      lineHeight: `${
                                        articleHeadingFontsize + 10
                                      }px`,
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked.ArticleTitle.split(
                                          "`"
                                        ).join("₹"),
                                    }}
                                  />
                                  {articleViewDataLinked?.ArticleSubTitle && (
                                    <h6
                                      style={{
                                        fontSize: `${
                                          Number(articleBlurbFontsize) + 5
                                        }px`,
                                        fontWeight: "normal",
                                        fontStyle: "italic",
                                        color: "#4a4949",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleSubTitle.split(
                                            "`"
                                          ).join("₹"),
                                      }}
                                    ></h6>
                                  )}
                                  {articleViewDataLinked && (
                                    <hr
                                      style={{ borderTop: "2px solid black" }}
                                    />
                                  )}

                                  {articleViewDataLinked && (
                                    <b
                                      style={{
                                        fontSize: `${
                                          articleBodyFontsize - 4
                                        }px`,
                                        fontWeight: "bold",
                                        color: "#4a4949",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked.ArticleLocation,
                                      }}
                                    ></b>
                                  )}

                                  <p
                                    style={{
                                      fontSize: `${articleBodyFontsize - 2}px `,
                                      lineHeight: `${
                                        Number(articleBodyFontsize) + 12
                                      }px`,
                                      color: "#4a4949",
                                    }}
                                    id="articlecardpara"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        parsedLinkedBody.current,
                                    }}
                                    className="mt-2"
                                  >
                                    {/* November 7, 2020 */}
                                    {/* <br /> */}
                                  </p>
                                </div>
                              )}

                            <Row
                              className="justify-content-center mb-3"
                              style={{
                                width: "fit-content",
                                overflow: "auto",
                              }}
                            >
                              {articleViewData &&
                              articleViewData.ArticlePhotographs &&
                              articleViewData.ArticlePhotographs.length
                                ? articleViewData?.ArticlePhotographs.map(
                                    (item, index) => {
                                      let photo = item.Photograph.split("_");
                                      return (
                                        <div
                                          className="text-center"
                                          key={item.Photograph + index + "ll"}
                                        >
                                          <img
                                            crossOrigin="*"
                                            className=""
                                            src={
                                              articleImageURL && articleImageURL
                                            }
                                            //   src={
                                            //     photo[photo.length - 1] ===
                                            //     props?.defaultInitialEditionName
                                            //       ?.editionid
                                            //       ? `${s3Source}/PublicationData/${
                                            //           props
                                            //             ?.defaultInitialPubCode
                                            //             ?.publicationcode
                                            //         }/${
                                            //           props
                                            //             ?.defaultInitialEditionName
                                            //             ?.editionid
                                            //         }/${props?.dateArray[0]}/${
                                            //           props?.dateArray[1]
                                            //         }/${
                                            //           props?.dateArray[2]
                                            //         }/Photographs/${
                                            //           item.Photograph.split(
                                            //             "_"
                                            //           )[3]
                                            //         }/${item.Photograph}.jpg?v=${filteredDate?.Timestamp}`
                                            //       : `${s3Source}/PublicationData/${
                                            //           props
                                            //             ?.defaultInitialPubCode
                                            //             ?.publicationcode
                                            //         }/${
                                            //           props
                                            //             ?.defaultInitialEditionName
                                            //             ?.editionid
                                            //         }/${props?.dateArray[0]}/${
                                            //           props?.dateArray[1]
                                            //         }/${
                                            //           props?.dateArray[2]
                                            //         }/Photographs/${
                                            //           item.Photograph.split(
                                            //             "_"
                                            //           )[3]
                                            //         }/${item.Photograph}_${
                                            //           props
                                            //             ?.defaultInitialEditionName
                                            //             ?.editionid
                                            //         }.jpg?v=${filteredDate?.Timestamp}`
                                            //   }
                                            alt={articleViewData.ArticleTitle}
                                          />
                                          {item?.ImageCaption && (
                                            <p className="small text-center">
                                              {item?.ImageCaption}
                                            </p>
                                          )}
                                        </div>
                                      );
                                    }
                                  )
                                : ""}
                              {props.advertisement && advertisement.current && (
                                <div className="text-center">
                                  <img
                                    width={`${imgWidth}%`}
                                    crossOrigin="*"
                                    className=""
                                    src={`${s3Source}/PublicationData/${
                                      props?.defaultInitialPubCode
                                        ?.publicationcode
                                    }/${
                                      props?.defaultInitialEditionName
                                        ?.editionid
                                    }/${props?.dateArray[0]}/${
                                      props?.dateArray[1]
                                    }/${props?.dateArray[2]}/Advertisement/${
                                      advertisement.current?.split("_")[3]
                                    }/${advertisement.current}_${
                                      props?.defaultInitialEditionName
                                        ?.editionid
                                    }.jpg?v=${filteredDate?.Timestamp}`}
                                    alt={articleViewData?.ArticleTitle}
                                  />
                                </div>
                              )}
                            </Row>
                          </div>
                          {articleViewDataLinked &&
                            Object.keys(articleViewDataLinked).length > 0 && (
                              <Row
                                className="justify-content-center mt-3 border"
                                style={{
                                  width: "fit-content",
                                  overflow: "auto",
                                }}
                              >
                                {articleViewDataLinked &&
                                articleViewDataLinked.ArticlePhotographs &&
                                articleViewDataLinked.ArticlePhotographs.length
                                  ? articleViewDataLinked?.ArticlePhotographs.map(
                                      (item, index) => {
                                        let photo = item.Photograph.split("_");
                                        return (
                                          <div
                                            className="text-center"
                                            key={item.Photograph + index + "ll"}
                                          >
                                            <img
                                              crossOrigin="*"
                                              className=""
                                              src={
                                                photo[photo.length - 1] ===
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                                  ? `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      item.Photograph.split(
                                                        "_"
                                                      )[3]
                                                    }/${
                                                      item.Photograph
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                                  : `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      item.Photograph.split(
                                                        "_"
                                                      )[3]
                                                    }/${item.Photograph}_${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                              }
                                              alt={
                                                articleViewDataLinked.ArticleTitle
                                              }
                                            />
                                            {item?.ImageCaption && (
                                              <p className="small text-center">
                                                {item?.ImageCaption}
                                              </p>
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </Row>
                            )}
                        </div>
                      ) : (
                        <>
                          <Row
                            style={{
                              position: "relative",
                              overflow: "auto",
                            }}
                          >
                            {textData.length > 0 &&
                              textData?.map((article, key) => {
                                return (
                                  <>
                                    <div
                                      key={"articleName_" + article?.Text}
                                      className=""
                                      style={{
                                        backgroundColor: "#ffff0069",
                                        opacity: getOpacity(
                                          article,
                                          props?.isSearch?.search
                                        ),
                                        position: "absolute",
                                        top: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Top *
                                              imageSizes.height) /
                                            2
                                          : " ",
                                        left: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Left *
                                              imageSizes.width) /
                                            2
                                          : " ",
                                        height: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Height *
                                              imageSizes.height) /
                                            2
                                          : " ",
                                        width: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Width *
                                              imageSizes.width) /
                                            2
                                          : " ",
                                      }}
                                    ></div>
                                  </>
                                );
                              })}
                            <img // need to add highlight
                              // style={{ width: `${imgWidth}%` }}
                              onLoad={() => {
                                setImageSizes({
                                  width:
                                    !isRegional &&
                                    imageSizeRef.current.naturalWidth,
                                  height:
                                    !isRegional &&
                                    imageSizeRef.current.naturalHeight,
                                });
                                // setImgWidth(
                                //   imageViewRef.current.naturalWidth
                                // );
                              }}
                              style={{
                                width:
                                  props?.isSearch?.from === "photographs"
                                    ? imageDimension.width
                                    : `${imgWidth}%`,
                                height:
                                  props?.isSearch?.from === "photographs"
                                    ? imageDimension.height
                                    : "auto",
                              }}
                              ref={imageSizeRef}
                              crossOrigin="*"
                              src={
                                articleViewName
                                  ? `${s3Source}/PublicationData/${
                                      props?.defaultInitialPubCode
                                        ?.publicationcode
                                    }/${
                                      props?.defaultInitialEditionName
                                        ?.editionid
                                    }/${props?.dateArray[0]}/${
                                      props?.dateArray[1]
                                    }/${props?.dateArray[2]}/Article/${
                                      articleViewName.split("_")[3]
                                    }/${articleViewData?.ArticleName}.jpg?v=${
                                      filteredDate?.Timestamp
                                    }`
                                  : `${s3Source}/PublicationData/${
                                      props?.defaultInitialPubCode
                                        ?.publicationcode
                                    }/${
                                      props?.defaultInitialEditionName
                                        ?.editionid
                                    }/${props?.dateArray[0]}/${
                                      props?.dateArray[1]
                                    }/${props?.dateArray[2]}/Advertisement/${
                                      advertisement.current?.split("_")[3]
                                    }/${advertisement.current}_${
                                      props?.defaultInitialEditionName
                                        ?.editionid
                                    }.jpg?v=${filteredDate?.Timestamp}`
                              }
                              alt={articleViewData?.ArticleTitle}
                            />
                            {articleViewDataLinked &&
                              Object.keys(articleViewDataLinked).length > 0 && (
                                <p className="mt-2">
                                  <img // need to add highlight
                                    // style={{ width: `${imgWidth}%` }}
                                    onLoad={() => {
                                      setImageSizes({
                                        width:
                                          !isRegional &&
                                          imageSizeRef.current.naturalWidth,
                                        height:
                                          !isRegional &&
                                          imageSizeRef.current.naturalHeight,
                                      });
                                      // setImgWidth(
                                      //   imageViewRef.current.naturalWidth
                                      // );
                                    }}
                                    style={{
                                      width:
                                        props?.isSearch?.from === "photographs"
                                          ? imageDimension.width
                                          : `${imgWidth}%`,
                                      height:
                                        props?.isSearch?.from === "photographs"
                                          ? imageDimension.height
                                          : "auto",
                                    }}
                                    ref={imageSizeRef}
                                    crossOrigin="*"
                                    src={
                                      articleViewNameLinked &&
                                      `${s3Source}/PublicationData/${
                                        props?.defaultInitialPubCode
                                          ?.publicationcode
                                      }/${
                                        props?.defaultInitialEditionName
                                          ?.editionid
                                      }/${props?.dateArray[0]}/${
                                        props?.dateArray[1]
                                      }/${props?.dateArray[2]}/Article/${
                                        articleViewNameLinked.split("_")[3]
                                      }/${
                                        articleViewDataLinked?.ArticleName
                                      }.jpg?v=${filteredDate?.Timestamp}`
                                    }
                                    alt={articleViewDataLinked?.ArticleTitle}
                                  />
                                </p>
                              )}
                          </Row>
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : (
          //   <OutsideClickHandler
          //     onOutsideClick={() => {
          //       props?.toggleArticleViewModal();
          //     }}
          //   >
          //   </OutsideClickHandler>
          <Row>
            <Col sm="12">
              <Card className="px-2 border-0">
                <CardBody className="pr-2">
                  <Row
                    className="justify-content-center pb-2 "
                    style={{ color: "#2b78a2" }}
                  >
                    <div className="share-mob">
                      <Row className=" flex-wrap-inherit">
                        <Col
                          style={{
                            color: "black",
                          }}
                          className=" pr-4 text-align-end"
                          onClick={() =>
                            props.handlePrevArticle(
                              props.isSecondPageFit2page
                                ? props.twoPageImage2Name
                                : props.twoPageImage1Name ??
                                    props.currentPage?.PageName
                            )
                          }
                        >
                          {props.currArticleIndex > 0 &&
                            articleViewData &&
                            !isRegional && (
                              <ArrowBackOutlinedIcon className="cursor-pointer " />
                            )}
                          {currArticleIndex > 0 &&
                            articleViewData &&
                            !isRegional && (
                              <Tooltip title="Previous Article">
                                <ArrowBackOutlinedIcon className="cursor-pointer " />
                              </Tooltip>
                            )}
                        </Col>
                        {currentPublication?.type !== "image" &&
                        !isRegional &&
                        !props.advertisement &&
                        !hasInfoZone ? (
                          <Col
                            className="pt-0 px-4 cursor-pointer2"
                            style={{
                              color: props.themeColor,
                            }}
                            onClick={() => {
                              toggleView();
                            }}
                          >
                            {articleTextView ? (
                              <div className="pt-1">
                                <Tooltip title="Image">
                                  <Image />
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="pt-1">
                                <Tooltip title="Text">
                                  <FileText />
                                </Tooltip>
                              </div>
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col
                          style={{
                            color: props.themeColor,
                          }}
                          className="pt-1 px-1"
                          onClick={() => {
                            articleTextView && increaseFontSize();
                            zoomImageSize(10);
                          }}
                        >
                          <Tooltip title="Zoom In">
                            <ZoomIn />
                          </Tooltip>
                        </Col>
                        <Col
                          style={{
                            color: props.themeColor,
                          }}
                          className="pt-1 px-1"
                          onClick={() => {
                            articleTextView && decreaseFontSize();
                            zoomImageSize(-10);
                          }}
                        >
                          <Tooltip title="Zoom Out">
                            <ZoomOut />
                          </Tooltip>
                        </Col>
                        {/* <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                          >
                          <Tooltip title="Bookmark">

                            <BookmarkBorderSharpIcon className="font-overview-icon" />
                            </Tooltip>
                        
                          </Col> */}

                        {/* <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                          >
                            <VolumeUpIcon className="font-overview-icon" />
                          </Col> */}
                        <Col
                          style={{
                            color: props.themeColor,
                          }}
                          className="pt-1 px-1"
                          onClick={() => print("articleViewMob")}
                        >
                          <Tooltip title="Print">
                            <Printer color={props.themeColor} />
                          </Tooltip>
                        </Col>
                        <Col
                          style={{
                            color: props.themeColor,
                          }}
                          className="pt-1 px-1"
                        >
                          <Tooltip title="Share">
                            <ShareIcon
                              onClick={toggleShare}
                              className="font-overview-icon"
                            />
                          </Tooltip>
                        </Col>

                        <Col
                          style={{
                            color: props.themeColor,
                          }}
                          className="pt-1 px-1"
                        >
                          <Tooltip title="Close">
                            <XCircle
                              className=""
                              onClick={() => {
                                props.toggleArticleViewModal();
                                props.setAd();
                              }}
                              fontSize="large"
                            />
                          </Tooltip>
                        </Col>
                        <Col
                          style={{
                            color: "black",
                          }}
                          className="text-align-start pl-2"
                        >
                          {currArticleIndex !==
                            props?.propsArticles?.length - 1 &&
                            articleViewData &&
                            !isRegional && (
                              <Tooltip title="Next Article">
                                <ArrowForwardOutlinedIcon
                                  color={props?.themeColor}
                                  onClick={() =>
                                    props.handleNextArticle(
                                      props.isSecondPageFit2page
                                        ? props.twoPageImage2Name
                                        : props.twoPageImage1Name ??
                                            props.currentPage?.PageName
                                    )
                                  }
                                  className="cursor-pointer "
                                />
                              </Tooltip>
                            )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <Row className="justify-content-center mt-2">
                    <Collapse
                      isOpen={isOpenMobArticle}
                      className="collapse-fixed"
                    >
                      <Row className="pl-0 justify-content-center">
                        <Card className="social-share">
                          <CardBody>
                            <Row
                              className=""
                              style={{ justifyContent: "space-evenly" }}
                            >
                              <WhatsappShareButton
                                quote={
                                  articleViewData &&
                                  articleViewData.ArticleTitle.split("`").join(
                                    "₹"
                                  )
                                }
                                url="https://ezones.harnstech.com"
                              >
                                <WhatsAppIcon
                                  style={{
                                    color: "#25D366",
                                    fontSize: "30px",
                                  }}
                                />
                              </WhatsappShareButton>

                              <FacebookShareButton
                                quote={
                                  articleViewData &&
                                  articleViewData.ArticleTitle.split("`").join(
                                    "₹"
                                  )
                                }
                                url="https://ezones.harnstech.com"
                              >
                                <FacebookIcon
                                  fontSize="large"
                                  style={{
                                    color: "#4267B2",
                                    fontSize: "30px",
                                  }}
                                />
                              </FacebookShareButton>
                              <TwitterShareButton
                                title={
                                  articleViewData &&
                                  articleViewData.ArticleTitle.split("`").join(
                                    "₹"
                                  )
                                }
                                url="https://ezones.harnstech.com"
                              >
                                <TwitterIcon
                                  style={{
                                    color: "#1DA1F2",
                                    fontSize: "30px",
                                  }}
                                />
                              </TwitterShareButton>
                              <EmailShareButton
                                title={
                                  articleViewData &&
                                  articleViewData.ArticleTitle
                                }
                                url="https://ezones.harnstech.com"
                              >
                                <img
                                  style={{ width: "25px" }}
                                  src="https://img.icons8.com/color/24/000000/gmail-new.png"
                                  alt="gmail"
                                />
                              </EmailShareButton>

                              <div className="pt-2">
                                <Copy
                                  fontSize="small"
                                  style={{ color: "black" }}
                                />
                              </div>
                              <div className="pt-2">
                                <X color="red" onClick={toggleShare} />
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Row>
                    </Collapse>
                  </Row>
                  <Row className="pt-4 " id="articleViewMob">
                    {loading ? (
                      <Spinner className="loaderArticleCard" />
                    ) : articleTextView && !isRegional ? (
                      <Col className="text-center ">
                        {!props.advertisement && (
                          <div
                            className="badge p-1 mb-3 text-white"
                            style={{
                              backgroundColor: "var(--primary)",
                            }}
                          >
                            Views: <span>{views}</span>
                          </div>
                        )}
                        {articleViewData?.ArticleBlurb && (
                          <Row
                            className="text-center"
                            style={{
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <h6
                              style={{
                                backgroundColor: "red",

                                fontSize: `${articleBlurbFontsize}px`,
                              }}
                              id="article-card-blurb-mob"
                              className="p-1"
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData.ArticleBlurb?.split("`").join(
                                    "₹"
                                  ),
                              }}
                            ></h6>
                          </Row>
                        )}
                        <h4
                          style={{
                            fontSize: `${articleHeadingMobFontsize}px`,
                          }}
                          id="article-card-title-mob"
                          className="font-weight-bold article-view-mob"
                          dangerouslySetInnerHTML={{
                            __html:
                              articleViewData &&
                              articleViewData.ArticleTitle.split("`").join("₹"),
                          }}
                        ></h4>
                        {articleViewData?.ArticleSubTitle && (
                          <h6
                            style={{
                              fontSize: `${articleBlurbFontsize}px`,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                articleViewData.ArticleSubTitle.split("`").join(
                                  "₹"
                                ),
                            }}
                          ></h6>
                        )}
                        <hr style={{ borderTop: "2px solid black" }} />
                        {articleViewData?.ArticleAuthor && (
                          <b
                            // style={{
                            //   fontSize: `${articleBodyFontsize}px`,
                            // }}
                            style={{
                              fontSize: `${articleBlurbFontsize}px`,
                              fontStyle: "italic",
                              color: articleAuthorEmail.current
                                ? "#0000ee"
                                : "#000",
                              textDecoration: articleAuthorEmail.current
                                ? "underline"
                                : "none",
                              fontWeight: articleAuthorEmail.current
                                ? "bold"
                                : "normal",
                              fontFamily:
                                "LibreBaskerville fff ff4 !important;",
                              cursor: articleAuthorEmail.current
                                ? "pointer"
                                : "auto",
                            }}
                            onClick={() => {
                              if (articleAuthorEmail.current) {
                                window.open(
                                  "mailto:" +
                                    articleViewData?.ArticleAuthor?.split(
                                      "<br>"
                                    )?.join(" "),
                                  "mail"
                                );
                              }
                            }}
                            id="article-card-author-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                articleViewData?.ArticleAuthor ===
                                  " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                  ? " TIMES NEWS NETWORK"
                                  : articleViewData?.ArticleAuthor?.split(
                                      "<br>"
                                    )?.join(" "),
                            }}
                          ></b>
                        )}
                        {articleViewData?.ArticleEmail && (
                          <a href={"mailto:" + articleViewData?.ArticleEmail}>
                            <i
                              style={{
                                fontSize: `${articleBodyFontsize}px`,
                              }}
                              id="article-card-mail-mob"
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData.ArticleEmail,
                              }}
                            ></i>
                          </a>
                        )}
                        {articleViewData && articleViewData.ArticleSource && (
                          <b
                            style={{
                              fontSize: `${articleBodyFontsize}px`,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                articleViewData.ArticleSource ===
                                  " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                  ? " TIMES NEWS NETWORK"
                                  : articleViewData?.ArticleAuthor?.split(
                                      "<br>"
                                    )?.join(" "),
                            }}
                          ></b>
                        )}
                        {articleViewData && articleViewData.ArticleLocation && (
                          <p>
                            <br />
                            <b
                              style={{
                                fontSize: `${articleBodyFontsize}px`,
                                fontWeight: "bold",
                                color: "#4a4949",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData.ArticleLocation,
                              }}
                            ></b>
                          </p>
                        )}
                        <p
                          style={{
                            fontSize: `${articleBodyFontsize}px`,
                          }}
                          id="articlecardpara-mob"
                          dangerouslySetInnerHTML={{
                            __html: articleViewData && parsedBody.current,
                          }}
                          className="article-view-mob"
                        ></p>

                        {/* second linked article*/}
                        {articleViewDataLinked &&
                          Object.keys(articleViewDataLinked).length > 0 && (
                            <>
                              <h4
                                style={{
                                  fontSize: `${
                                    articleHeadingMobFontsize - 4
                                  }px`,
                                }}
                                id="article-card-title-mob"
                                className="font-weight-bold article-view-mob mt-3"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewDataLinked &&
                                    articleViewDataLinked.ArticleTitle.split(
                                      "`"
                                    ).join("₹"),
                                }}
                              ></h4>
                              {articleViewDataLinked?.ArticleSubTitle && (
                                <h6
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      articleViewDataLinked.ArticleSubTitle.split(
                                        "`"
                                      ).join("₹"),
                                  }}
                                ></h6>
                              )}
                              <hr style={{ borderTop: "2px solid black" }} />
                              {articleViewDataLinked?.ArticleAuthor && (
                                <b
                                  style={{
                                    fontSize: `${articleBodyFontsize}px`,
                                  }}
                                  id="article-card-author-mob"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      articleViewDataLinked?.ArticleAuthor ===
                                        " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                        ? " TIMES NEWS NETWORK"
                                        : articleViewDataLinked?.ArticleAuthor?.split(
                                            "<br>"
                                          )?.join(" "),
                                  }}
                                ></b>
                              )}
                              {articleViewDataLinked?.ArticleEmail && (
                                <a
                                  href={
                                    "mailto:" +
                                    articleViewDataLinked?.ArticleEmail
                                  }
                                >
                                  <i
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                    }}
                                    id="article-card-mail-mob"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked.ArticleEmail,
                                    }}
                                  ></i>
                                </a>
                              )}
                              {articleViewDataLinked &&
                                articleViewDataLinked.ArticleSource && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked.ArticleSource ===
                                          " TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? " TIMES NEWS NETWORK"
                                          : articleViewDataLinked?.ArticleAuthor?.split(
                                              "<br>"
                                            )?.join(" "),
                                    }}
                                  ></b>
                                )}
                              {articleViewDataLinked &&
                                articleViewDataLinked.ArticleLocation && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize - 4}px`,
                                      fontWeight: "bold",
                                      color: "#4a4949",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked.ArticleLocation,
                                    }}
                                  ></b>
                                )}
                              <p
                                style={{
                                  fontSize: `${articleBodyFontsize - 2}px`,
                                }}
                                id="articlecardpara-mob"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewDataLinked &&
                                    parsedLinkedBody.current,
                                }}
                                className="article-view-mob"
                              />
                            </>
                          )}
                        <div>
                          {articleViewData &&
                          articleViewData.ArticlePhotographs &&
                          articleViewData.ArticlePhotographs.length
                            ? articleViewData?.ArticlePhotographs.map(
                                (item, index) => {
                                  let photo = item.Photograph.split("_");
                                  return (
                                    <React.Fragment className="text-center">
                                      <img
                                        key={
                                          articleViewData?.ArticleName +
                                          index +
                                          "name"
                                        }
                                        crossOrigin="*"
                                        className=""
                                        src={
                                          photo[photo.length - 1] ===
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                            ? `${s3Source}/PublicationData/${
                                                props?.defaultInitialPubCode
                                                  ?.publicationcode
                                              }/${
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                              }/${props?.dateArray[0]}/${
                                                props?.dateArray[1]
                                              }/${
                                                props?.dateArray[2]
                                              }/Photographs/${
                                                articleViewData?.ArticleName?.split(
                                                  "_"
                                                )[3]
                                              }/${item.Photograph}.jpg?v=${
                                                filteredDate?.Timestamp
                                              }`
                                            : `${s3Source}/PublicationData/${
                                                props?.defaultInitialPubCode
                                                  ?.publicationcode
                                              }/${
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                              }/${props?.dateArray[0]}/${
                                                props?.dateArray[1]
                                              }/${
                                                props?.dateArray[2]
                                              }/Photographs/${
                                                articleViewData?.ArticleName?.split(
                                                  "_"
                                                )[3]
                                              }/${item.Photograph}_${
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                              }.jpg?v=${
                                                filteredDate?.Timestamp
                                              }`
                                        }
                                        alt={articleViewData.ArticleTitle}
                                      />
                                      {item?.ImageCaption && (
                                        <p className="small">
                                          {item?.ImageCaption}
                                        </p>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )
                            : ""}
                        </div>

                        {/* second linked article data */}
                        {articleViewDataLinked &&
                          Object.keys(articleViewDataLinked).length > 0 && (
                            <>
                              <div>
                                {articleViewDataLinked &&
                                articleViewDataLinked.ArticlePhotographs &&
                                articleViewDataLinked.ArticlePhotographs.length
                                  ? articleViewDataLinked?.ArticlePhotographs.map(
                                      (item, index) => {
                                        let photo = item.Photograph.split("_");
                                        return (
                                          <React.Fragment className="text-center">
                                            <img
                                              key={
                                                articleViewDataLinked?.ArticleName +
                                                index +
                                                "name"
                                              }
                                              crossOrigin="*"
                                              className=""
                                              src={
                                                photo[photo.length - 1] ===
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                                  ? `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      articleViewDataLinked?.ArticleName?.split(
                                                        "_"
                                                      )[3]
                                                    }/${
                                                      item.Photograph
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                                  : `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      articleViewDataLinked?.ArticleName?.split(
                                                        "_"
                                                      )[3]
                                                    }/${item.Photograph}_${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                              }
                                              alt={
                                                articleViewDataLinked.ArticleTitle
                                              }
                                            />
                                            {item?.ImageCaption && (
                                              <p className="small">
                                                {item?.ImageCaption}
                                              </p>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </>
                          )}
                      </Col>
                    ) : (
                      <>
                        <Row
                          // className="article-view-desktop"
                          style={{
                            //   width: "1000px",
                            position: "relative",
                            //   transform:
                            //     isPopularDrawerOpen && isFitToHeight > 0 ? "translateX(-5vw)" : "",
                          }}
                          noGutters
                          // style={{ width: "fit-content" }}
                          // id="article-view-card"
                        >
                          <Col>
                            {textData.length > 0 &&
                              textData?.map((article, key) => {
                                return (
                                  <>
                                    <div
                                      key={"llf" + key}
                                      className=""
                                      style={{
                                        backgroundColor: "#ffff0069",
                                        opacity: getOpacity(
                                          article,
                                          props?.isSearch?.search
                                        ),
                                        position: "absolute",
                                        top: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Top *
                                              imageSizes.height) /
                                            2
                                          : " ",
                                        left: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Left *
                                              imageSizes.width) /
                                            2
                                          : " ",
                                        height: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Height *
                                              imageSizes.height) /
                                            2
                                          : " ",
                                        width: props?.isSearch?.search
                                          ? (article?.Geometry?.BoundingBox
                                              .Width *
                                              imageSizes.width) /
                                            2
                                          : " ",
                                      }}
                                    ></div>
                                  </>
                                );
                              })}
                            <img // need to add highlight
                              // style={{ width: `${imgWidth}%` }}
                              onLoad={() => {
                                setImageSizes({
                                  width:
                                    !isRegional &&
                                    imageSizeRef.current.naturalWidth,
                                  height:
                                    !isRegional &&
                                    imageSizeRef.current.naturalHeight,
                                });
                              }}
                              style={{
                                width: props?.isSearch?.search
                                  ? imageDimension.width
                                  : `${imgWidth}%`,
                                height: props?.isSearch?.search
                                  ? imageDimension.height
                                  : "auto",
                              }}
                              ref={imageSizeRef}
                              crossOrigin="*"
                              src={`${s3Source}/PublicationData/${
                                props?.defaultInitialPubCode?.publicationcode
                              }/${
                                props?.defaultInitialEditionName?.editionid
                              }/${props?.dateArray[0]}/${props?.dateArray[1]}/${
                                props?.dateArray[2]
                              }/Article/${articleViewName.split("_")[3]}/${
                                articleViewData?.ArticleName
                              }.jpg?v=${filteredDate?.Timestamp}`}
                              alt={articleViewData?.ArticleTitle}
                            />

                            {/* second linked article */}
                            <img // need to add highlight
                              // style={{ width: `${imgWidth}%` }}
                              onLoad={() => {
                                setImageSizes({
                                  width:
                                    !isRegional &&
                                    imageSizeRef.current.naturalWidth,
                                  height:
                                    !isRegional &&
                                    imageSizeRef.current.naturalHeight,
                                });
                              }}
                              style={{
                                width: props?.isSearch?.search
                                  ? imageDimension.width
                                  : `${imgWidth}%`,
                                height: props?.isSearch?.search
                                  ? imageDimension.height
                                  : "auto",
                              }}
                              className="mt-3"
                              ref={imageSizeRef}
                              crossOrigin="*"
                              src={`${s3Source}/PublicationData/${
                                props?.defaultInitialPubCode?.publicationcode
                              }/${
                                props?.defaultInitialEditionName?.editionid
                              }/${props?.dateArray[0]}/${props?.dateArray[1]}/${
                                props?.dateArray[2]
                              }/Article/${
                                articleViewNameLinked.split("_")[3]
                              }/${articleViewDataLinked?.ArticleName}.jpg?v=${
                                filteredDate?.Timestamp
                              }`}
                              alt={articleViewDataLinked?.ArticleTitle}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {/* </div> */}
      </Container>
    </React.Fragment>
  );
}
