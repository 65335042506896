import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
} from "reactstrap";
import defaultImg from "../assets/images/default_image.png";

import axios from "axios";
import { useHistory } from "react-router-dom";
import "./gallery-view.css";
import ArticleCard from "./ArticleCard";
import "./content-modal.css";
import Modal from "react-modal";
import JSCookie from "js-cookie";
// context
import { AppContext } from "../contexts/AppContext";

export default function GalleryView(props) {
  // global state
  const {
    isPopularDrawerOpen,
    toggleArticleViewModal,
    setArticleViewData,
    setArticleViewName,
    isOpenArticleModal,
    toggle,
    toggleShare,
    isOpen2,
    s3Source,
    currArticleIndex,
    setCurrArticleIndex,
    updateInsight,
    setArticleViewDataLinked,
    setArticleViewNameLinked,
    setLinkJsonURL,
    setHasInfoZone,
    setRelatedArticleData,
    setArticles,
    initialPublication,
    initialEdition,
    dateArray,
    dayIndexData,
    publicationsData,
    currentPage,
    propsArticles,
    pageClick,
    themeColor,
    items,
    filteredDate,
    isEnableAds,
  } = useContext(AppContext);
  let desktopView = window.innerWidth > 700;
  // const [propsArticles, setPropsArticles] = useState([]);
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [articlePhoto, setArticlePhoto] = useState([]);
  const [advertisement, setAdvertisement] = React.useState("");
  let history = useHistory();
  useEffect(() => {
    if (!JSCookie.get("andre")) {
      history.push("/login");
    }
  });
  const tomainPage = () => {
    history.push(
      `/${publicationsData[JSCookie.get("nbtpubindex")]?.publicationname
        ?.split(" ")
        .join("-")
        .toLowerCase()}/${publicationsData[
        JSCookie.get("nbtpubindex")
      ]?.editions[JSCookie.get("nbtedindex")]?.editionname
        ?.split(" ")
        .join("-")
        .toLowerCase()}`
    );
  };
  useEffect(() => {
    JSCookie.set("view", "tiles");
    JSCookie.set("isLanding", false);
  }, []);

  useEffect(() => {
    setArticlePhoto(items?.ArticlePhotograph);
  }, [items]);

  useEffect(() => {
    const fetchPublications = () => {
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition?.editionid}/${dateArray?.[0]}/${dateArray?.[1]}/${dateArray?.[2]}/PageJson/`;

      path = path_prefix + currentPage?.PageName + ".json";

      if (!path.includes("undefined")) {
        axios
          .get(path)
          .then((result) => {
            setArticles(result?.data);
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    };
    fetchPublications();
  }, [dayIndexData]);

  const getContinuedArticles = async (path, articleName, articleJsonPath) => {
    let result = false;
    let isLinkTo = false;
    if (path) {
      await axios.get(path).then(async (res) => {
        if (res?.data?.linkJson && res?.data?.linkJson?.length > 0) {
          let filter = res.data.linkJson.filter(
            (v) => v.FromLinkArticle === articleName
          );
          if (filter.length === 0) {
            filter = res.data.linkJson.filter(
              (v) => v.ToLinkArticle === articleName
            );
            if (filter.length > 0) {
              isLinkTo = true;
            }
          }
          if (filter && filter.length > 0) {
            let findArticleLink = filter.filter(
              (v) => v.LinkType === "articleLink"
            );
            let selectedArticleLink = [];
            if (findArticleLink.length > 0) {
              selectedArticleLink = [
                findArticleLink[findArticleLink.length - 1],
              ];
            }
            // if (filter[0].LinkType === "articleLink") {
            if (selectedArticleLink?.length > 0) {
              let pageNumber =
                selectedArticleLink[0]?.ArticleLink.split("_")?.[3];

              setArticleViewNameLinked(selectedArticleLink[0]?.ArticleLink);
              await axios
                .get(
                  `${articleJsonPath}${pageNumber}/${selectedArticleLink[0]?.ArticleLink}.json?v=${filteredDate?.Timestamp}`
                )
                .then(async (response) => {
                  let array = [];
                  for (let i = 0; i < response.data.length; i++) {
                    const ele = response.data[i];
                    let obj = { ...ele, SortOrder: i + 1 };
                    array.push(obj);
                  }

                  response.data = array;
                  const finalArticle = await getFinalArticleArray(
                    response?.data,
                    selectedArticleLink[0]?.ArticleLink
                  );
                  result = {
                    articleName: selectedArticleLink[0]?.ArticleLink,
                    pageNumber,
                    articleData: finalArticle,
                    isLinkTo,
                  };
                  setArticleViewDataLinked({
                    ...finalArticle.articleData[0],
                    ArticlePhotographs: finalArticle.photographs ?? [],
                  });
                  setTimeout(() => {
                    updateInsight("view", finalArticle.articleData[0]);
                  }, 1200);
                })
                .catch((err) => {
                  setArticleViewDataLinked(null);
                });
            }
          }
        }
      });
    }
    return result;
  };
  const openArticleView = async (
    articleName,
    isSecondPage = false,
    linkJsonPath,
    zone
  ) => {
    let pageNumber = articleName.split("_")?.[3];
    if (zone?.TagName === "Information") setHasInfoZone(true);
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`
    );
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;
    let searched = await getContinuedArticles(
      linkJsonPath ??
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`,
      articleName,
      path_prefix
    );
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      interchange = searched?.isLinkTo;
      // if (
      //   searched?.pageNumber &&
      //   parseInt(searched.pageNumber) < parseInt(pageNumber)
      // ) {
      //   interchange = true;
      // }
    }

    const articleIndex = propsArticles?.findIndex(
      (article) => article.ArticleName === articleName
    );
    if (articleIndex !== -1) setCurrArticleIndex(articleIndex);

    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${filteredDate?.Timestamp}`;
    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;

        const finalArticle = await getFinalArticleArray(
          response.data,
          articleName,
          searched,
          interchange,
          true
        );
      })

      .catch((err) => {
        setArticleViewData(null);
      });
  };

  const getFinalArticleArray = async (
    data,
    articleName,
    searched,
    interchange,
    isNotFromContinued
  ) => {
    const articles = [];
    let photographs = data.filter((v) => v.TagName === "Photographs");

    if (photographs.length > 0) {
      photographs = photographs.map((ele, ind) => {
        return {
          ...ele,
          Photograph: ele?.ZoneID,
          ImageCaption: data?.filter(
            (v) =>
              v.TagName === "ImageCaption" && v.SortOrder == ele?.SortOrder + 1
          )?.[0]?.ZoneText,
        };
      });
    }
    let hasInformationTag = false;
    const titles = data.filter((v) => v.TagName === "ArticleTitle");
    for (let j = 0; j < titles.length; j++) {
      const ele = titles[j];
      const sliceNum = titles.filter((v, i, a) => {
        if (i !== 0 && i === j + 1) {
          return a[i];
        }
      });
      let sliced_list = [];
      if (sliceNum.length > 0) {
        sliced_list = data.slice(ele.SortOrder - 1, sliceNum[0].SortOrder - 1);
      } else {
        sliced_list = data.slice(ele.SortOrder - 1);
      }
      articles.push(sliced_list);
    }

    if (articles.length > 0) {
      let finalArticleArray = [];
      let allowedTags = ["ArticleTitle", "ArticleBody"];
      for (let i = 0; i < articles.length; i++) {
        const sub_article = articles[i];
        let tts = "";
        let obj = {};

        for (let j = 0; j < sub_article.length; j++) {
          const ele = sub_article[j];
          if (allowedTags.includes(ele.TagName)) {
            tts += ele?.ZoneText?.split("")
              .join(" ")
              .split("<br>")
              .join(" ")
              .trim();
          }
          if (ele.TagName === "Information") {
            setHasInfoZone(true);
            hasInformationTag = true;
          }
          if (ele.TagName === "LinkTo") {
            let zoneid = ele.ZoneID.split("_");
            zoneid.pop();
            let linkedZone = await searchLink(
              `${s3Source}/PublicationData/${
                initialPublication?.publicationcode
              }/${initialEdition?.editionid}/${dateArray?.join("/")}/LinkJson/${
                dateArray[2]
              }_${dateArray[1]}_${dateArray[0]}_${
                initialEdition?.editionid
              }.json?v=${filteredDate?.Timestamp}`,
              { ZoneId: zoneid.join("_") }
            );
            const span_link = `<p id='${zoneid.join("_")}' class='${
              linkedZone?.[0]?.PageLink
            }' style='cursor:pointer;color:#0000ee;text-decoration:underline;font-weight:bold;font-family:LibreBaskerville'>${
              (!ele.ZoneText || ele.ZoneText === " ") &&
              linkedZone[0].LinkType === "pageLink"
                ? `P${Number(linkedZone[0].PageLink.split("_")[3])}`
                : ele.ZoneText?.split("").join("›")
            }</p>`;
            if (obj.ArticleBody) {
              obj["ArticleBody"] = obj?.["ArticleBody"] + `${span_link}`;
            } else {
              obj["ArticleBody"] = span_link;
            }
          } else {
            obj[ele.TagName] = obj[ele.TagName]
              ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›").trim()
              : ele?.ZoneText?.split("").join("›").trim();
          }
        }
        finalArticleArray.push({ ...obj, ArticleName: articleName, tts });
      }
      let finalArticle = {
        photographs,
        articleData: finalArticleArray,
      };

      if (isNotFromContinued) {
        if (interchange) {
          if (
            searched &&
            Object.keys(searched).length > 0 &&
            searched?.articleData?.articleData?.length > 1
          ) {
            let related = [
              ...searched?.articleData?.articleData.slice(1),
              ...finalArticle.articleData.slice(1),
            ];
            setRelatedArticleData(related);
          }
          setArticleViewName(searched?.articleName);
          if (
            !hasInformationTag &&
            !searched?.articleData?.articleData[0]?.ArticleBody.slice(0, 7) ===
              "<p id='"
          ) {
            let location = await findLocation(
              searched?.articleData?.articleData[0]?.ArticleBody
            );
            if (location && location.length > 0) {
              searched.articleData.articleData[0].ArticleLocation =
                location.join(" ");
              searched.articleData.articleData[0].ArticleBody =
                searched?.articleData?.articleData[0]?.ArticleBody.slice(
                  location.join(" ")?.length
                );
            }
          }
          setArticleViewData(searched?.articleData?.articleData[0]);
          setArticleViewDataLinked({
            ...finalArticle?.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
          setArticleViewNameLinked(articleName);
        } else {
          setRelatedArticleData(finalArticle.articleData.slice(1));
          setArticleViewName(articleName);
          setArticleViewNameLinked(searched?.articleName);
          if (
            !hasInformationTag &&
            !finalArticle.articleData[0]?.ArticleBody.slice(0, 7) === "<p id='"
          ) {
            let location = await findLocation(
              finalArticle.articleData[0]?.ArticleBody
            );

            if (location && location.length > 0) {
              finalArticle.articleData[0].ArticleLocation = location.join(" ");

              finalArticle.articleData[0].ArticleBody =
                finalArticle.articleData[0]?.ArticleBody.slice(
                  location.join(" ")?.length
                );
            }
          }
          setArticleViewData({
            ...finalArticle.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
        }
        setTimeout(() => {
          updateInsight("view", {
            ...finalArticle.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
        }, 1200);
      }
      return finalArticle;
    }
    return { photographs, articleData: [] };
  };
  const findLocation = (data) => {
    const iterated = [];
    if (data && data.length > 0) {
      let split = data.split(" ").slice(0, 4);

      for (let i = 0; i < split.length; i++) {
        const ele = split[i];

        if (ele.search(":") !== -1) {
          split[i] = ele.split(":")[0] + ":";
          for (let s = 0; s < i + 1; s++) {
            const element = split[s];
            iterated.push(element);
          }
        } else {
        }
      }
    }
    return iterated;
  };
  //updated
  const handleNextArticle = async (articleName) => {
    setRelatedArticleData([]);
    setArticleViewDataLinked({});
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    let length = propsArticles?.length - 1;

    if (currArticleIndex < length) {
      let array = propsArticles;

      let searched = await getContinuedArticles(
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`,
        array[currArticleIndex + 1].ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }

      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex + 1].ArticleName
      }.json?v=${filteredDate?.Timestamp}`;
      let newArticleName = array[currArticleIndex + 1].ArticleName;
      setArticleViewName(array[currArticleIndex + 1].ArticleName);

      setCurrArticleIndex(currArticleIndex + 1);

      axios
        .get(path)
        .then(async (response) => {
          let array = [];
          for (let i = 0; i < response.data.length; i++) {
            const ele = response.data[i];
            let obj = { ...ele, SortOrder: i + 1 };
            array.push(obj);
          }

          response.data = array;

          const finalArticle = await getFinalArticleArray(
            response.data,
            newArticleName,
            searched,
            interchange,
            true
          );
        })
        .catch((err) => {
          console.log("handleNextArticle err: ", err);
        });
    }
  };

  const handlePrevArticle = async (articleName) => {
    setArticleViewDataLinked({});
    setRelatedArticleData([]);
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    // let pageNumber = currentPage.PageName.slice(11, 14);
    let array = propsArticles;

    if (currArticleIndex > 0) {
      let searched = await getContinuedArticles(
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`,
        array?.[currArticleIndex - 1]?.ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }
      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex - 1].ArticleName
      }.json?v=${filteredDate?.Timestamp}`;
      setCurrArticleIndex(currArticleIndex - 1);
      setArticleViewName(array[currArticleIndex - 1].ArticleName);
      let newArticleName = array[currArticleIndex - 1].ArticleName;
      axios
        .get(path)
        .then(async (response) => {
          let array = [];
          for (let i = 0; i < response.data.length; i++) {
            const ele = response.data[i];
            let obj = { ...ele, SortOrder: i + 1 };
            array.push(obj);
          }

          response.data = array;
          const finalArticle = await getFinalArticleArray(
            response.data,
            newArticleName,
            searched,
            interchange,
            true
          );
        })
        .catch((err) => {
          console.log("handlePrevArticle err: ", err);
        });
    }
  };

  const searchLink = async (path, zone) => {
    let response = [];
    await axios.get(path).then((res) => {
      if (res?.data?.linkJson?.length > 0) {
        response = res?.data?.linkJson.filter(
          (v) => v.FromZoneID === zone.ZoneId
        );
      }
    });
    return response;
  };

  return (
    <React.Fragment>
      {desktopView ? (
        <Modal
          isOpen={isOpenArticleModal}
          className="mymodal"
          overlayClassName="myoverlay"
          onRequestClose={toggleArticleViewModal}
          id={`react-modal-desktop`}
        >
          <ArticleCard
            pageClick={(link, jumpTo) => {
              tomainPage();
              pageClick(link, jumpTo);
            }}
            shouldReload={true}
            dayIndexData={dayIndexData}
            toggleArticleViewModal={toggleArticleViewModal}
            themeColor={themeColor}
            defaultInitialPubCode={initialPublication}
            defaultInitialEditionName={initialEdition}
            dateArray={dateArray}
            currentPage={currentPage}
            toggle={toggle}
            isOpenMobArticle={isOpenMobArticle}
            toggleShare={toggleShare}
            handleNextArticle={handleNextArticle}
            handlePrevArticle={handlePrevArticle}
            isOpen2={isOpen2}
            openArticleView={openArticleView}
            propsArticles={propsArticles}
            setIsOpenMobArticle={setIsOpenMobArticle}
            advertisement={advertisement}
            setAd={() => {
              setAdvertisement("");
              let modal = document.getElementById("react-modal-desktop");
              modal.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
        </Modal>
      ) : (
        <Modal
          isOpen={isOpenArticleModal}
          className="mymodal-mob "
          overlayClassName={
            localStorage.getItem("contentRec") === "true"
              ? "myoverlay-mob"
              : "myoverlay-mob-noad"
          }
          id={`react-modal-mobile`}
        >
          <ArticleCard
            pageClick={(link, jumpTo) => {
              tomainPage();
              pageClick(link, jumpTo);
            }}
            shouldReload={true}
            toggleArticleViewModal={toggleArticleViewModal}
            themeColor={themeColor}
            defaultInitialPubCode={initialPublication}
            defaultInitialEditionName={initialEdition}
            dateArray={dateArray}
            currentPage={currentPage}
            toggle={toggle}
            isOpenMobArticle={isOpenMobArticle}
            toggleShare={toggleShare}
            handleNextArticle={handleNextArticle}
            handlePrevArticle={handlePrevArticle}
            isOpen2={isOpen2}
            openArticleView={openArticleView}
            propsArticles={propsArticles}
            setIsOpenMobArticle={setIsOpenMobArticle}
            setAd={() => {
              setAdvertisement("");
              let modal = document.getElementById("react-modal-mobile");
              modal.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
        </Modal>
      )}

      <div
        className="desktop-only gall-ie "
        style={{
          paddingLeft:
            !isPopularDrawerOpen && window.innerWidth > 1365 ? "10rem" : "",
          paddingRight:
            isPopularDrawerOpen && window.innerWidth > 1365 ? "10rem" : "",
        }}
      >
        {/* <Row
          className="pl-5 gallery-view-pad"
          style={{ justifyContent: window.innerWidth < 1366 && "center" }}
        >
          <Col md="10">
            <Row className="px-5 mx-4 pt-5 cursor-pointer">
              <CardImg src={ad_gallery} alt="toi" height="70%" />
            </Row>
          </Col>
        </Row> */}
        {currentPage?.PageTitle !== "Advertisement" ? (
          <Row
            className="pt-5 mt-4"
            style={{ justifyContent: window.innerWidth < 1366 && "center" }}
          >
            <Col sm="12" md="12" lg="10" xl="10">
              <Row className="gall-pad">
                {items &&
                  items?.Articles?.map((item, index) => {
                    return (
                      <Col
                        md="3"
                        sm="3"
                        className="pb-4"
                        key={item?.ArticleName + index}
                      >
                        <Card
                          style={{ overflow: "hidden", height: "200px" }}
                          onClick={() => {
                            openArticleView(item.ArticleName);
                            toggleArticleViewModal();
                          }}
                          className="card-gallery cursor-pointer "
                        >
                          <CardImg
                            onError={(e) => (e.target.src = defaultImg)}
                            className="tile-card-img"
                            style={{ objectFit: "cover" }}
                            src={`${s3Source}/PublicationData/${
                              initialPublication?.publicationcode
                            }/${initialEdition?.editionid}/${dateArray[0]}/${
                              dateArray[1]
                            }/${
                              dateArray[2]
                            }/Photographs/${currentPage?.PageName.slice(
                              11,
                              14
                            )}/${item.ArticlePhotograph}.jpg?v=${
                              filteredDate?.Timestamp
                            }`}
                            alt={item.ArticlePhotograph}
                          />
                          <div className="mb-1 overlay-img-initial">
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: item && item.ArticleTitle,
                              }}
                              className="m-0 px-3 font-weight-bold gallery-heading-desktop"
                            ></h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item && item.ArticleBody,
                              }}
                              className="m-0 gallery-article-desktop px-3"
                            ></p>
                          </div>
                          <div className="mb-1 overlay-img">
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: item && item.ArticleTitle,
                              }}
                              className="m-0 px-3 font-weight-bold gallery-heading-desktop"
                            ></h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item && item.ArticleBody,
                              }}
                              className="m-0 gallery-article-desktop px-3"
                            ></p>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        ) : (
          <Row
            className="advertismnt-tile"
            style={{
              left: isPopularDrawerOpen ? "30%" : "40%",
            }}
          >
            <h1>{currentPage?.PageTitle}</h1>
          </Row>
        )}
      </div>

      {/* //mob gallery view  */}
      {currentPage?.PageTitle !== "Advertisement" ? (
        <div className="mob-only ">
          <Row
            className="mx-3 mob-gallery-view"
            style={{
              overflow: "none",
              marginTop:
                localStorage.getItem("contentRec") === "true"
                  ? "120px"
                  : "50px",
            }}
          >
            <Row className="py-2" noGutters>
              {items &&
                items?.Articles?.map((item, index) => {
                  return (
                    <Col sm="4" xs="4" key={index} className="p-1">
                      <Card
                        style={{
                          height: "150px",
                          minWidth: "-webkit-fill-available",
                        }}
                        className="card-gallery-mob"
                        onClick={() => {
                          openArticleView(item.ArticleName);
                          toggleArticleViewModal();
                        }}
                      >
                        <CardImg
                          onError={(e) => (e.target.src = defaultImg)}
                          className="tile-card-img"
                          style={{ objectFit: "cover" }}
                          src={`${s3Source}/PublicationData/${
                            initialPublication?.publicationcode
                          }/${initialEdition?.editionid}/${dateArray[0]}/${
                            dateArray[1]
                          }/${
                            dateArray[2]
                          }/Photographs/${currentPage?.PageName.slice(
                            11,
                            14
                          )}/${item.ArticlePhotograph}.jpg?v=${
                            filteredDate?.Timestamp
                          }`}
                          alt={item.ArticlePhotograph}
                        />
                        <div className="mb-1 overlay-img-mob">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item && item.ArticleTitle,
                            }}
                            className="m-0 px-3 font-weight-bold gallery-heading-mob"
                          ></p>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Row>
        </div>
      ) : (
        <Row
          className="advertismnt-tile mob-only"
          style={{
            left: "32%",
          }}
        >
          <h3>{currentPage?.PageTitle}</h3>
        </Row>
      )}
    </React.Fragment>
  );
}
