//present
import React, { useState, useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import JSCookie from "js-cookie";
import publicationJson from "../config/Publications.json";
import { AppContext } from "../contexts/AppContext";
import Loader from "../components/LoaderScreen";

const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  const {
    getPublicationsData,
    initialPublication,
    dateArray,
    dateIndexData,
    dayIndexData,
    initialEdition,
    updateCookies,
  } = useContext(AppContext);
  let editionIndex = 0;

  if (
    JSCookie.get("nbtpubindex") &&
    publicationJson?.Publications?.length > 0
  ) {
    editionIndex = publicationJson?.Publications?.[
      JSCookie.get("nbtpubindex")
    ].editions.findIndex((v) => v.editionid == initialEdition?.editionid);
  }
  useEffect(async () => {
    await updateCookies();
    if (
      dateIndexData?.length == 0 ||
      dayIndexData?.length == 0 ||
      editionIndex !== Number(JSCookie.get("nbtedindex"))
    ) {
      // if (!JSCookie.get("nbteddate")) {
      getPublicationsData(true);
      // } else {
      //   getPublicationsData();
      // }
    }
  }, []);
  const loaderScreen = () => {
    return (
      <div>
        <Loader />
      </div>
    );
  };
  // if (dateIndexData?.length > 0 && dayIndexData?.length > 0) {
  const redirectToLoginURL = `http://${
    window.location.hostname
  }/navbharattimes/${publicationJson.Publications[
    JSCookie.get("nbtpubindex")
  ]?.publicationname
    ?.split(" ")
    .join("-")
    .toLowerCase()}/${publicationJson.Publications[
    JSCookie.get("nbtpubindex")
  ]?.editions[JSCookie.get("nbtedindex")]?.editionname
    ?.split(" ")
    .join("-")
    .toLowerCase()}`;

  const homeURL = `http://${window.location.hostname}/navbharattimes/home`;

  const encodedURI =
    `https://epapers.timesgroup.com/home.html?From=` +
    encodeURIComponent(
      JSCookie.get("nbtpubindex") && JSCookie.get("nbtedindex")
        ? redirectToLoginURL
        : homeURL
    );
  return (
    <Route
      {...rest}
      render={(props) => {
        // if (isAuthProtected && !JSCookie.get("pgsygina")) {
        //   window.location.assign(encodedURI);
        // }

        return <Component {...props} />;
      }}
    />
  );
  // }
  // else {
  //   if (window.location.pathname !== "/home") {
  //     return loaderScreen();
  //   } else {
  //     <Route
  //       {...rest}
  //       render={(props) => {
  //         return <HomePage {...props} />;
  //       }}
  //     />;
  //   }
  // }
};

export default AppRoute;
