import React, { useContext, useRef, useEffect, useMemo } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import HeightSharpIcon from "@material-ui/icons/HeightSharp";
import MenuList from "@material-ui/core/MenuList";
import "../components/sidebar.css";
import Tooltip from "@material-ui/core/Tooltip";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import fit_to_width_icon from "../assets/icons/fit_to_width.png";
import { makeStyles } from "@material-ui/core/styles";
import { Columns, Maximize, Monitor } from "react-feather";
import OutsideClickHandler from "react-outside-click-handler";

//context
import { AppContext } from "../contexts/AppContext";
import { displayEvents } from "../functions/cleverTap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "22px",
    justifyContent: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  MenuList: {
    marginTop: "19px",
  },
  MenuItem: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));

export default function ToggleMenu1(props) {
  const classes = useStyles();
  // global state
  const {
    setIsFitToHeight,
    isPopularDrawerOpen,
    setWidth,
    refactorHighlight,
    vw,
    setFit2page,
    fit2page,
    setIsPopularDrawerOpen,
    isPanoramaPage,
    setIsPanoramaPage,
    ratioMainPage,
    fit2pagesArray,
    s3Source,
    initialPublication,
    initialEdition,
    currentPage,
    dateArray,
    dayIndexData,
    logo,
    themeColor,
    pageClick,
  } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onFitToHeight = async () => {
    setIsFitToHeight((currState) => currState + 1);

    setWidth((currState) => {
      const targetHeight = window.innerHeight - 200;

      const newWidth = ratioMainPage * targetHeight;
      refactorHighlight(newWidth, currState);
      return newWidth;
    });

    if (fit2page) {
      await updatePage();
    }
    setFit2page(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const fullScreen = (param) => {
    if (param === "fscr") {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };
  const twoPageImage1Name = useMemo(() => {
    if (fit2page && currentPage.pageNumber === 1) {
      return currentPage.PageName;
    } else if (fit2page) {
      return dayIndexData[currentPage.pageNumber + (currentPage.pageNumber - 3)]
        ?.PageName;
    }
  }, [dayIndexData, currentPage, fit2page]);
  const updatePage = () => {
    const page = dayIndexData.findIndex(
      (v) =>
        v.PageName ===
        fit2pagesArray[currentPage?.pageNumber - 1].first.PageName
    );
    pageClick(
      `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${dayIndexData[page].PageName}.jpg`,
      page
    );
  };
  return (
    <div className={classes.root}>
      <div>
        {/* <Tooltip title="View"> */}
        <Monitor
          className=" feather-29"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
        {/* </Tooltip> */}
        <Popper
          open={props.open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    props.handleClose();
                  }}
                >
                  <MenuList
                    className={classes.MenuList}
                    autoFocusItem={props.open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <Tooltip title="Fit To Width">
                      <MenuItem
                        className={classes.MenuItem}
                        id="ftw"
                        onClick={async () => {
                          displayEvents(
                            "display_button",
                            "display_button",
                            "display_button",
                            "fit_to_width"
                          );
                          setIsFitToHeight(0);
                          // if (fit2page) {
                          //   pageClick(
                          //     twoPageImage1Name,
                          //     currentPage.pageNumber +
                          //       (currentPage.pageNumber - 3)
                          //   );
                          // }
                          setWidth((currState) => {
                            let newWidth = null;
                            let sidebarWidth = null;

                            if (window.innerWidth > 2000) {
                              sidebarWidth = isPopularDrawerOpen
                                ? vw(18.4)
                                : vw(0.7);
                              newWidth = window.innerWidth - sidebarWidth;
                            } else if (window.innerWidth > 1800) {
                              sidebarWidth = isPopularDrawerOpen
                                ? vw(18.2)
                                : vw(0.7);
                              newWidth = window.innerWidth - sidebarWidth;
                            } else if (window.innerWidth < 1366) {
                              newWidth = window.innerWidth;
                            } else if (window.innerWidth > 1365) {
                              const sidebarWidth = isPopularDrawerOpen
                                ? 340
                                : vw(0.7);
                              newWidth = window.innerWidth - sidebarWidth;
                            } else {
                              const sidebarWidth = isPopularDrawerOpen
                                ? 340
                                : vw(0.7);
                              newWidth = window.innerWidth - sidebarWidth;
                            }

                            refactorHighlight(newWidth, currState);

                            return newWidth;
                          });
                          if (fit2page) {
                            await updatePage();
                          }
                          setFit2page(false);
                        }}
                      >
                        <ZoomOutMapIcon style={{ color: themeColor }} />
                        <p className="menu-name">चौड़ाई में फिट</p>
                      </MenuItem>
                    </Tooltip>
                    <Tooltip title="Fit To Height">
                      <MenuItem
                        onClick={() => {
                          displayEvents(
                            "display_button",
                            "display_button",
                            "display_button",
                            "fit_to_height"
                          );
                          onFitToHeight();
                        }}
                        className={classes.MenuItem}
                        id="fth"
                      >
                        <HeightSharpIcon style={{ color: themeColor }} />

                        <p className="menu-name">ऊंचाई में फिट</p>
                      </MenuItem>
                    </Tooltip>
                    <Tooltip title="Two Pages">
                      <MenuItem
                        className={classes.MenuItem}
                        onClick={() => {
                          displayEvents(
                            "display_button",
                            "display_button",
                            "display_button",
                            "two_pages"
                          );
                          // if (fit2page) {
                          //   pageClick(currentPage.PageName, 2);
                          // }
                          setWidth((currState) => {
                            const newWidth = !isPanoramaPage
                              ? window.innerWidth / 2
                              : window.innerWidth - vw(0.7);

                            refactorHighlight(newWidth, currState);
                            return newWidth;
                          });
                          setIsFitToHeight(0);

                          setIsPopularDrawerOpen(false);
                          let page = fit2pagesArray.findIndex(
                            (v) => v.first.PageName === currentPage?.PageName
                          );
                          if (page === -1) {
                            page = fit2pagesArray.findIndex((v) => {
                              return (
                                v?.second?.PageName === currentPage?.PageName
                              );
                            });
                          }
                          if (page !== -1) {
                            pageClick(
                              `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${fit2pagesArray[page].PageName}.jpg`,
                              page
                            );
                          }
                          setFit2page(true);
                        }}
                      >
                        <Columns style={{ color: themeColor }} />

                        <p className="menu-name">दो पेज</p>
                      </MenuItem>
                    </Tooltip>
                    <Tooltip title="Full Screen">
                      <MenuItem
                        className={classes.MenuItem}
                        onClick={() => {
                          fullScreen("fscr");
                          displayEvents(
                            "display_button",
                            "display_button",
                            "display_button",
                            "full_screen"
                          );
                        }}
                      >
                        <Maximize style={{ color: themeColor }} />
                        <p className="menu-name">पूर्ण स्क्रीन</p>
                      </MenuItem>
                    </Tooltip>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
